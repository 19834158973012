import React from "react";
import { Helmet } from "react-helmet";

const PageTitle = ({ title }) => <Helmet title={`Locad | ${title}`} />;

PageTitle.defaultProps = {
  title: "The Logistics Engine",
};

export default PageTitle;
