import * as actionTypes from "./actionTypes";
import { getActionTypesObject } from "../store/apiMiddleware/utils";

const initialState = {
  pickupLocations: {
    isLoading: true,
    items: [],
    error: null,
  },
  pickupLocationDetail: {
    data: null,
    isLoading: false,
    error: null,
  },
  carrierConnectionsChanging: false,
  authorizedChanging: false,
};

const pickupLocationsActionTypes = getActionTypesObject(
  actionTypes.GET_PICKUP_LOCATIONS
);
const pickupLocationsDetailActionTypes = getActionTypesObject(
  actionTypes.GET_PICKUP_LOCATION_DETAIL
);
const updateCarrierConnectionsActionTypes = getActionTypesObject(
  actionTypes.UPDATE_PICKUP_LOCATION_CARRIER_CONNECTIONS
);

export default function shipment(state = initialState, action) {
  switch (action.type) {
    case pickupLocationsActionTypes.request:
      return updatePickupLocationState(state, { isLoading: true, error: null });

    case pickupLocationsActionTypes.success:
      return updatePickupLocationState(state, {
        isLoading: false,
        items: action.response,
      });

    case pickupLocationsActionTypes.fail:
      return updatePickupLocationState(state, {
        isLoading: false,
        error: action.error,
        items: [],
      });

    case pickupLocationsDetailActionTypes.request:
      return updatePickupLocationDetailState(state, {
        isLoading: true,
        error: null,
      });

    case pickupLocationsDetailActionTypes.success:
      return updatePickupLocationDetailState(state, {
        isLoading: false,
        data: action.response,
      });

    case pickupLocationsDetailActionTypes.fail:
      return updatePickupLocationDetailState(state, {
        isLoading: false,
        error: action.error,
        data: null,
      });

    case updateCarrierConnectionsActionTypes.request:
      return { ...state, carrierConnectionsChanging: true };

    case updateCarrierConnectionsActionTypes.success:
      const updatedDetailState = updatePickupLocationDetailState(state, {
        data: action.response,
      });
      return {
        ...updatedDetailState,
        carrierConnectionsChanging: false,
      };

    case updateCarrierConnectionsActionTypes.fail:
      return { ...state, carrierConnectionsChanging: false };

    default:
      return state;
  }
}

const updatePickupLocationState = (state, newState) => ({
  ...state,
  pickupLocations: {
    ...state.pickupLocations,
    ...newState,
  },
});

const updatePickupLocationDetailState = (state, newState) => ({
  ...state,
  pickupLocationDetail: {
    ...state.pickupLocationDetail,
    ...newState,
  },
});
