/**
 * Use when you need to show loader inside the button
 */
import React from 'react';
import { Button as MuiButton, CircularProgress as MuiCircularProgress, useTheme } from '@mui/material';
import styled from 'styled-components';
import { spacing } from '@mui/system';

const WithOpacity = styled.span`
  opacity: ${(props) => (props.transparent ? 0 : 1)};
`;
const Button = styled(MuiButton)(spacing);
const CircularProgress = styled(MuiCircularProgress)(
  spacing,
  () => `
  position: absolute;
`
);

export default function LoadingButton({ isLoading, children, color = 'primary', disabled, ...rest }) {
  const theme = useTheme();
  const buttonColors = {
    error: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.contrastText,
      },
    },
  };

  return (
    <Button
      size="small"
      variant="contained"
      color={color}
      disabled={isLoading || disabled}
      sx={{
        ...(!['primary', 'secondary', 'inherit'].includes(color) &&
          buttonColors.hasOwnProperty(color) &&
          buttonColors[color]),
      }}
      {...rest}
    >
      {isLoading ? <CircularProgress size={22} p={1} color="inherit" /> : null}
      <WithOpacity transparent={isLoading ? 'transparent' : null}>{children}</WithOpacity>
    </Button>
  );
}
