import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { rgba } from 'polished';
import { NavLink as RouterNavLink, withRouter } from 'react-router-dom';
import '../vendor/perfect-scrollbar.css';
import { PropTypes } from 'prop-types';
import { spacing } from '@mui/system';
import { getId, onboardingIsRouteOpen } from './Joyride/components/Onboarding/helpers';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box as MuiBox,
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  Grid,
  IconButton as MuiIconButton,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ExpandLess, ExpandMore, FiberManualRecord as BulletIcon, KeyboardArrowLeft } from '@mui/icons-material';
import { getSidebarRoutes } from '../routes/index';
import { logout } from '../redux/auth/actions';
import { Power } from 'react-feather';
import { getListUrlFromPath, paths } from '../routes/constants';
import useScreenSize from '../hooks/useScreenSize';

const NavLink = React.forwardRef((props, ref) => <RouterNavLink innerRef={ref} {...props} />);

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const IconButton = styled(MuiIconButton)`
  color: ${(props) => props.theme.sidebar.color};
  :hover {
    background-color: var(--color-hover-charcol) !important;
    color: var(--colors-secondary-hover);
  }
  svg {
    width: 22px;
    height: 22px;
  }
`;
const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
  margin: 4.625rem 0 0 0 !important;
  overflow-y: hidden;
  height: ${(props) => props.innerHeight - 145}px;

  @media (max-width: 599px) {
    margin: 0 !important;
  }
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(6)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(4)};
  padding-right: ${(props) => props.theme.spacing(4)};
  cursor: default;
  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
    position: fixed;
    left: 0px;
    right: 0px;
    width: 16.25rem;
    z-index: 1;
  }
  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;
const Category = styled(ListItem)`
  justify-content: center;
  opacity: 0.8;
  height: 3rem;
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-bottom: ${(props) => props.theme.spacing(3)};
  transition: border-radius 0.1s ease;
  svg {
    color: ${(props) => props.theme.sidebar.color};
  }
  &:hover {
    opacity: 0.9;
    background: rgba(61, 61, 61, 1);
    border-radius: 1.5rem;
  }
  &.${(props) => props.activeclassname} {
    opacity: 1;
    background-color: ${(props) => props.theme.palette.secondary.dark};
    border-radius: 1.5rem;
    span {
      color: ${(props) => props.theme.palette.secondary.contrastText};
    }
    svg {
      color: ${(props) => props.theme.palette.secondary.contrastText};
    }
  }
`;
export const ImgBox = styled(Box)`
  margin-left: -0.7rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const SimpleLink = styled(ListItem)`
  padding: 0;
  color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  &:hover {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.8)};
  }
  text-align: center;
`;

const Link = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(2)};
  padding-bottom: ${(props) => props.theme.spacing(2)};
  width: 85%;
  margin: auto;
  border-radius: 1.3rem;
  white-space: nowrap;
  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }
  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }
  &.${(props) => props.activeclassname} {
    > * {
      color: ${(props) => props.theme.palette.secondary.contrastText} !important;
    }
    background-color: ${(props) => props.theme.palette.secondary.dark};
    span {
      color: ${(props) => props.theme.palette.secondary.contrastText};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize - 2}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  // top: 8px;
  font-size: 10px;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  height: 20px;
  position: absolute;
  right: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)} ${(props) => props.theme.spacing(6)}px
    ${(props) => props.theme.spacing(1)};
  opacity: 0.9;
  display: block;
  margin: auto;
  // margin-left: 20px;
`;

const SidebarFooter = styled.div`
  background-color: ${(props) => props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)} ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  position: fixed;
  z-index: 1;
  width: 16.25rem;
  bottom: 0;
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.725rem;
  display: block;
  padding: 1px;
`;

const BulletStyle = {
  width: '.7rem',
  marginRight: '.4rem',
  color: 'var(--colors-contrast-text-primary)',
};

const footerTextGrid = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '11rem',
};
const arrowIconLeft = {
  display: 'flex',
  position: 'fixed',
  margin: '3.3rem 0 0 15.4rem',
  borderRadius: '50%',
  boxShadow: '0px 2px 2px 0px rgba(0,0,0,.122),0px 1px 10px 0px rgba(0,0,0,.078)',
  backgroundColor: 'white',
  zIndex: '2',
  cursor: 'pointer',
};

function SidebarCategory({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  id,
  currentTargetIdForTour,
  isTourInProgress,
  to,
  exact,
  activeclassname,
  component,
  ...rest
}) {
  const targetOverlayStyle = currentTargetIdForTour
    ? currentTargetIdForTour === id
      ? {
          border: '1px solid white',
          borderRadius: '0px',
        }
      : {}
    : {};
  return (
    <Box
      style={{
        margin: '0 .6rem',
        ...targetOverlayStyle,
      }}
      id={id}
    >
      <Category
        {...(isTourInProgress ? { activeclassname, ...rest } : { component, activeclassname, exact, to, ...rest })}
      >
        <ImgBox>{icon}</ImgBox>
        <CategoryText>{name}</CategoryText>
        {isCollapsable ? isOpen ? <CategoryIconMore /> : <CategoryIconLess /> : null}
        {badge ? <CategoryBadge label={badge} /> : ''}
      </Category>
    </Box>
  );
}

function SidebarLink({ name, to, badge, id, currentTargetIdForTour, isTourInProgress }) {
  return (
    <Link
      button
      dense
      {...(isTourInProgress ? {} : { component: NavLink })}
      {...(isTourInProgress ? {} : { exact: true })}
      {...(isTourInProgress ? {} : { to: to })}
      activeclassname="active"
      id={id}
      style={
        currentTargetIdForTour === id
          ? {
              border: '1px solid white',
              borderRadius: '0px',
            }
          : {}
      }
    >
      <BulletIcon style={BulletStyle} />
      <LinkText activeclassname="active">{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ''}
    </Link>
  );
}

function Sidebar({
  classes,
  staticContext,
  location,
  theme,
  isSideNavOpen,
  setIsSideNavOpen,
  tourNotSupported = false,
  ...rest
}) {
  const { height } = useScreenSize();
  const scrollbarRef = useRef(null);
  const user = useSelector((state) => state.auth.user);
  const onboarding = useSelector((state) => state.onboarding);
  const productTourPrompt = useSelector((state) => state.startProductTourPrompt);

  useEffect(() => {
    if ((!isSideNavOpen && onboarding.useJoyrideIndexRoutes) || onboarding.showWelcomeMessage) {
      setIsSideNavOpen(true);
    }
  }, [isSideNavOpen, onboarding.showWelcomeMessage, setIsSideNavOpen, onboarding.useJoyrideIndexRoutes]);

  const activeTab = useSelector((state) => state.sidebar.activeTab);
  let routes = getSidebarRoutes(user.dashboard_version, user, activeTab);
  const initOpenRoutes = (routes = []) => {
    /* Open collapse element that matches current url */
    const pathName = location?.pathname;

    let _routes = {};

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === paths.INDEX ? true : false;

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(routes));
  const dispatch = useDispatch();
  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) => openRoutes[index] || setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [item]: false }))
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }));
  };

  const conditionToCloseOpenRoutes = onboarding.stepIndex === onboarding.steps.length - 1;

  function openManageListRoute(targetRoute) {
    let routeIndex = -1;
    if (targetRoute) {
      routes.forEach((item, index) => {
        if (item.path === targetRoute) {
          routeIndex = index;
        }
      });
    }
    if (routeIndex > -1) {
      Object.keys(openRoutes).forEach(
        (item) =>
          openRoutes[routeIndex] || setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [item]: false }))
      );

      // Toggle selected element
      setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [routeIndex]: openRoutes[routeIndex] || true }));
    }
  }
  useEffect(() => {
    const currentTarget = scrollbarRef.current?._container;
    if ((onboarding.useJoyrideIndexRoutes && onboarding.stepIndex > 0) || productTourPrompt.run) {
      currentTarget.scrollTop = currentTarget.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboarding.stepIndex, onboarding.useJoyrideIndexRoutes]);

  useEffect(() => {
    if (productTourPrompt.parentRoute && !onboarding.steps.length) {
      openManageListRoute(productTourPrompt.parentRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productTourPrompt.parentRoute, !onboarding.steps.length]);

  //
  useEffect(() => {
    if (conditionToCloseOpenRoutes) toggle(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionToCloseOpenRoutes]);

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <>
      <Drawer variant="permanent" {...rest}>
        {isMdUp && (
          <Box
            style={arrowIconLeft}
            className="on-hover-border"
            onClick={() => {
              if (onboarding.useJoyrideIndexRoutes) return;
              setIsSideNavOpen(false);
            }}
          >
            <KeyboardArrowLeft />
          </Box>
        )}
        <Brand>
          <SimpleLink component={NavLink} to={paths.INDEX} exact>
            <Box pt={2} component="span">
              <img alt="logo" width="70%" src={theme.body.logos.dark} />
            </Box>
          </SimpleLink>
        </Brand>
        <List innerHeight={height} disablePadding>
          <PerfectScrollbar ref={scrollbarRef}>
            <Items>
              {routes?.map((category, index) => (
                <React.Fragment key={category.id}>
                  {category?.header ? <SidebarSection>{category.header}</SidebarSection> : null}
                  {category?.children ? (
                    <>
                      <SidebarCategory
                        isOpen={
                          onboarding.useJoyrideIndexRoutes
                            ? !onboardingIsRouteOpen(index, onboarding)
                            : !openRoutes[index]
                        }
                        isCollapsable={true}
                        name={category.id}
                        icon={category.icon}
                        button={true}
                        onClick={() => toggle(index)}
                        isTourInProgress={onboarding.useJoyrideIndexRoutes}
                      />
                      <Collapse
                        in={
                          !onboarding.useJoyrideIndexRoutes
                            ? openRoutes[index]
                            : onboardingIsRouteOpen(index, onboarding)
                        }
                        timeout="auto"
                        unmountOnExit
                      >
                        {category.children.map((route) => (
                          <SidebarLink
                            key={route.name}
                            name={route.name}
                            to={getListUrlFromPath(route?.path)}
                            icon={route.icon}
                            badge={route.badge}
                            id={getId(route.name)}
                            currentTargetIdForTour={onboarding.currentTargetId}
                            isTourInProgress={onboarding.useJoyrideIndexRoutes}
                          />
                        ))}
                      </Collapse>
                    </>
                  ) : (
                    <SidebarCategory
                      isCollapsable={false}
                      name={category.id}
                      to={getListUrlFromPath(category?.path)}
                      activeclassname="active"
                      component={NavLink}
                      icon={category.icon}
                      exact
                      badge={category.badge}
                      id={getId(category.id)}
                      currentTargetIdForTour={onboarding.currentTargetId}
                      isTourInProgress={onboarding.useJoyrideIndexRoutes}
                    />
                  )}
                </React.Fragment>
              ))}
            </Items>
          </PerfectScrollbar>
          {/* {!user.fulfilment_enabled &&
          user.shipping_enabled &&
          localStorage.getItem('onboardingFlow_1') &&
          !tourNotSupported && <ProductTourLinkButton {...{ routes }} />} */}
        </List>
        <SidebarFooter>
          <Grid container spacing={5}>
            <Grid item className="display-flex">
              <IconButton id="logout" onClick={() => dispatch(logout())} style={{ margin: 'auto' }} size="large">
                <Power />
              </IconButton>
            </Grid>
            <Grid item style={footerTextGrid}>
              <SidebarFooterText variant="body2">
                <SimpleLink id="loggedinUser" component={NavLink} to={paths.ACCOUNT_SETTINGS} exact>
                  {user.first_name} {user.last_name}
                </SimpleLink>
              </SidebarFooterText>
              <SidebarFooterSubText variant="caption">
                <SimpleLink id="loggedinUserBrand" component={NavLink} to={paths.ACCOUNT_SETTINGS} exact>
                  {user.current_brand.name}
                </SimpleLink>
              </SidebarFooterSubText>
            </Grid>
          </Grid>
        </SidebarFooter>
      </Drawer>
    </>
  );
}
Sidebar.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  theme: PropTypes.shape({
    palette: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  to: PropTypes.string,
  badge: PropTypes.string,
  isCollapsable: PropTypes.bool,
};

export default withRouter(withTheme(Sidebar));
