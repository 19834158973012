export const INBOUND = {
  title: 'Inbound',
  INBOUND_REQUESTS: {
    title: 'Uploaded Files',
  },
  INBOUND_TRACKING: {
    title: 'Manage Inbound',
  },
  ASN: {
    title: 'ASN Number',
  },
  ASN_DETAIL: {
    title: 'Advance Shipping Notes Detail',
  },
};
