import * as actionTypes from './actionTypes';
import { getJoyrideSteps } from '../../../Components/Joyride/components/Onboarding/helpers';
import { productTourPromptStepDefinition } from '../../../Components/Joyride/components/Onboarding/constants';
export const startProductTourPrompt = () => {
  return {
    type: actionTypes.START_PRODUCT_TOUR_PROMPT,
    payload: {
      run: true,
      continuous: true,
      loading: false,
      stepIndex: 0,
      steps: getJoyrideSteps(productTourPromptStepDefinition),
      initStep: 0,
      targetId: 'product_tour',
    },
  };
};
export const stopProductTourPrompt = ({ ...rest }) => {
  return {
    type: actionTypes.STOP_PRODUCT_TOUR_PROMPT,
    payload: {
      run: false,
      continuous: false,
      loading: false,
      stepIndex: 0,
      initStep: 0,
      targetId: '',
      parentRoute: '/shipments',
      ...rest,
    },
  };
};

export function terminatePrompt({ ...rest }) {
  return {
    type: actionTypes.TERMINATE_PROMPT,
    payload: { parentRoute: '', ...rest },
  };
}
