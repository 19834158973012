import {
  CLEAR_PREV_NOTIFICATION,
  HIDE_NOTIFICATION,
  OPEN_NOTIFICATION,
  SHOW_NOTIFICATION,
} from "./actionTypes";

/**
 *
 * @param message any test
 * @param type error| info| success| warning (one of alert colors) if not provided, will show default snackbar
 * @param verticalAnchor top | bottom
 * @param horizontalAnchor right | left | center
 * @returns {{payload: {verticalAnchor, message, type, horizontalAnchor}, type: object}}
 */
export function showNotification(
  message,
  type,
  verticalAnchor = "top",
  horizontalAnchor = "center"
) {
  return {
    type: SHOW_NOTIFICATION,
    payload: {
      message,
      type,
      verticalAnchor,
      horizontalAnchor,
    },
  };
}

export function hideNotification() {
  return {
    type: HIDE_NOTIFICATION,
  };
}

export function openNotification() {
  return {
    type: OPEN_NOTIFICATION,
  };
}

export function clearPreviousNotification() {
  return {
    type: CLEAR_PREV_NOTIFICATION,
  };
}
