//accounts urls
export const USER_EXISTANCE_CHECK = '/api/accounts/cognito/migrate/check/:userName/';
export const USER_URL = '/api/accounts/user/';
export const PASSWORD_CHANGE_URL = '/api/accounts/password/';
export const LOGIN_URL = '/api/accounts/token/obtain/';
export const TOKEN_REFRESH_URL = '/api/accounts/token/refresh/';
export const SIGNUP_USER_INVITE = '/api/accounts/signupuserinvite/';
export const LINK_BRAND_INVITE = '/api/accounts/linkbranduser/';
export const AUTH_TOKEN_URL = '/api/accounts/cognito/signinup/';
export const FRESHDESK_SSO_URL = '/api/freshdesk/login/';
export const FRESHDESK_AUTH_URL = '/api/freshdesk/auth_url/';
export const FRESHDESK_COMPANIES_FETCH = '/api/freshdesk/companies/'; //GET
export const FRESHDESK_COMAPNY_DETAILS = '/api/freshdesk/companies/:company_id/'; //GET
export const FRESHDESK_COMPANY_CREATE = '/api/freshdesk/companies/create/'; //POST body={"company_name": "<company_name>"}
export const FRESHDESH_COMPANY_MAP = '/api/freshdesk/companies/mapbrand/'; //POST body={"freshdesk_company_id": <company_id>}
export const METABASE_EMBEDDED_URL = '/api/metabase/embedded/dashboard/:dashboard';

//Shopify Signup
export const SHOPIFY_SIGNUP_INSTALL = '/api/zeus/marketplace/shopify/auth/public/installV2/';
export const SHOPIFY_SIGNUP_SETUP = '/api/zeus/marketplace/shopify/auth/public/setupV2/';
export const SHOPIFY_CREATE_USER = '/api/zeus/marketplace/shopify/auth/public/create/user/';
export const SHOPIFY_LOAD_DATA = '/api/zeus/marketplace/shopify/auth/public/load/user_id/data/';
export const SHOPIFY_USER_DATA = '/api/zeus/marketplace/shopify/auth/public/load/:user_id/data/';
export const EXISTING_USER_BRANDS_CONNECTION = '/api/zeus/marketplace/load/brand_connections/';
export const SHOPIFY_USER_MAP_LOCATION = '/api/zeus/marketplace/shopify/auth/public/account/submit/';
export const SHOPIFY_USER_CREATE_BRAND = '/api/zeus/marketplace/shopify/auth/public/create/brand/';
export const SHOPIFY_GET_TNC = '/api/accounts/loadactivetnc/'; // GET ?type=SHIPPING_PH";
export const SHOPIFY_ACCEPT_TNC = '/api/accounts/agreedtnc/';

// Amazon Signup
export const AMAZON_SIGNUP_AUTH_URL = '/api/zeus/marketplace/amazon/login_url/';
export const AMAZON_SIGNUP_VALIDATE_URL = '/api/zeus/marketplace/amazon/save_auth/';

//ssorders urls
export const SALES_METRICS_URL = '/api/ssorders/salesmetrics/';
export const ORDER_LIST_URL = '/api/ssorders/orders/';
export const ORDER_FILTERS_URL = '/api/ssorders/orderfilters/';
export const ORDER_ITEMS_URL = '/api/ssorders/orderitems/';
export const INVENTORY_URL = '/api/ssorders/inventory/';
export const FULFILLMENT_METRICS = '/api/ssorders/fulfillmentmetrics/';
export const FULFILLMENT_FORECAST_BULK_UPLOAD = '/api/zeus/order/forecast/bulk_uploads/view/?warehouse_id=:id';
export const FULFILLMENT_FORECAST_BULK_UPLOADED_LIST = '/api/zeus/order/forecast/bulk_uploads/view/';
export const FULFILLMENT_FORECAST_VIEW = '/api/zeus/order/forecast/month/:year/:month/';
export const FULFILLMENT_FORECAST_VIEW_UPSERT = '/api/zeus/order/forecast/upsert/';
export const FULFILLMENT_FORECAST_DOWNLOAD_SUCCESS_FILE = '/api/zeus/order/forecast/bulk_uploads/document/:id/';
export const FULFILLMENT_FORECAST_DOWNLOAD_ERROR_FILE = '/api/zeus/order/forecast/bulk_uploads/error/:id/';
export const FULFILLMENT_FORECAST_CSV_DOWNLOAD = '/api/zeus/order/forecast/csv_download/';
export const FULFILMENT_FORECAST_WAREHOUSE_DATA = '/api/zeus/order/forecast/date/:year/:month/:day/';
export const ORDER_PAUSE = '/api/zeus/order/orders/:id/edit_initiate/';
export const ORDER_RELEASE = '/api/zeus/order/orders/:id/release_order_from_paused/';
//Wallet Summary
export const WALLET_BALANCE = '/api/wallet/balance/';
export const WALLET_TRANSACTION = '/api/wallet/transactions/';
export const WALLET_ELIGIBILITY = '/api/shipping/shipping_eligibility/?multi_currency_enabled=True';
export const VOUCHER_VALIDATION_URL = '/api/wallet/voucher_validation/<voucher_code>/<currency>';

//zeus carrier urls
export const CARRIER_ALL_ORDERS_URL = '/api/zeus/carrier/all_orders/';
export const CARRIER_LIST_URL = '/api/zeus/carrier/';
export const CARRIER_CONNECTIONS_LIST_URL = '/api/zeus/carrier/order_id/carrier_connections/';
export const CARRIER_ORDER_LIST_URL = '/api/zeus/order/carrier_orders/';
export const CARRIER_ORDER_FILTER_LIST_URL = '/api/zeus/order/carrier_order/filters/';
export const CARRIER_NINJAVAN_VALIDATE_URL = '/api/zeus/carrier/ninjavan/validate/';
export const CARRIER_ENTREGO_VALIDATE_URL = '/api/zeus/carrier/entrego/validate/';
export const CARRIER_XDE_VALIDATE_URL = '/api/zeus/carrier/xde/validate/';
export const CARRIER_LALAMOVE_VALIDATE_URL = '/api/zeus/carrier/lalamove/validate/';
export const CARRIER_JNTPH_VALIDATE_URL = '/api/zeus/carrier/jntph/validate/';
export const CARRIER_JNTSG_VALIDATE_URL = '/api/zeus/carrier/jntsg/validate/';
export const CARRIER_LBC_VALIDATE_URL = '/api/zeus/carrier/lbc/validate/';
export const CARRIER_DHL_VALIDATE_URL = '/api/zeus/carrier/dhl/validate/';
export const CARRIER_AUSPOST_VALIDATE_URL = '/api/zeus/carrier/auspost/validate/';
export const CARRIER_ASENDIA_VALIDATE_URL = '/api/zeus/carrier/asendia/validate/';
export const CARRIER_SENDLE_VALIDATE_URL = '/api/zeus/carrier/sendle/validate/';
export const CARRIER_KERRY_VALIDATE_URL = '/api/zeus/carrier/kerry/validate/';
export const CARRIER_TELEPORT_VALIDATE_URL = '/api/zeus/carrier/teleport/validate/';
export const CARRIER_SINGPOST_VALIDATE_URL = '/api/zeus/carrier/singpost/validate/';
export const CARRIER_PICKUPP_VALIDATE_URL = '/api/zeus/carrier/pickupp/validate/';

//zeus marketplace urls
export const MARKETPLACE_GET_CONNECTION_UPDATES =
  '/api/zeus/marketplace/get_connection_update_history/:marketplace_id/';
export const MARKETPLACE_UPDATE_ORDER_SYNC = '/api/zeus/marketplace/:marketplace_id/set_order_sync/';
export const MARKETPLACE_UPDATE_INVENTORY_SYNC = '/api/zeus/marketplace/:marketplace_id/set_prod_inv_sync/';
export const MARKETPLACE_LIST_URL = '/api/zeus/marketplace/';
export const MARKETPLACE_PRODUCT_SYNC_URL = '/api/zeus/marketplace/:marketplace_id/products/sync';
export const MARKETPLACE_ORDER_SYNC_URL = '/api/zeus/marketplace/:marketplace_id/orders/sync';
export const MARKETPLACE_LOCATION_SYNC_URL = '/api/zeus/marketplace/:marketplace_id/locations/sync';
export const MARKETPLACE_SET_WRITE_ENABLED_URL = '/api/zeus/marketplace/:marketplace_id/set_write_enabled';
export const MARKETPLACE_SET_ALLOW_OVERSELLING_URL = '/api/zeus/marketplace/:marketplace_id/set_allow_overselling';
export const MARKETPLACE_LOCATIONS_URL = '/api/zeus/marketplace/locations/:marketplace_id';
export const MARKETPLACE_LOCATION_MAPPING = '/api/zeus/marketplace/fulfilment_location_mapping';
export const MARKETLACE_CREATE_STORE_URL = '/api/zeus/marketplace/address/:location_id/add_as_store';
export const MARKETPLACE_GOLIVE_DETAILS = '/api/zeus/marketplace/:marketplace_id/update_golive_details';
export const MARKETPLACE_PERFORMANCE_CONFIG = '/api/zeus/marketplace/marketplace_performance/config/';
export const MARKETPLACE_PERFORMANCE_DATA =
  '/api/zeus/marketplace/marketplace_performance/data/?metric_code=:metric_code';
export const MARKETPLACE_UPDATE_NAME = '/api/zeus/marketplace/:marketplace_id/set_name';

//zeus warehouse urls
export const WAREHOUSE_LIST_URL = '/api/zeus/warehouse/';
export const WAREHOUSE_WAREO_VALIDATE_URL = '/api/zeus/warehouse/wareo/validate/';
export const WAREHOUSE_WAREO_3_VALIDATE_URL = '/api/zeus/warehouse/wareo3/validate/';
export const WAREHOUSE_UNIWARE_VALIDATE_URL = '/api/zeus/warehouse/uniware/validate/';
export const WAREHOUSE_LOGIWA_VALIDATE_URL = '/api/zeus/warehouse/logiwa/validate/';
export const WAREHOUSE_SET_WRITE_ENABLED_URL = '/api/zeus/warehouse/:warehouse_id/set_write_enabled';
export const WAREHOUSE_VENDOR_CODE = '/api/zeus/warehouse/uniware/get_vendor_code/';
export const WAREHOUSE_PRODUCT_SYNC = '/api/zeus/warehouse/:warehouse_id/products/sync/';

//carrier management urls
export const SHIPPING_PROFILE_LIST = '/api/zeus/carrier/shipping_profiles/';
export const DELETE_SHIPPING_PROFILE = '/api/zeus/carrier/shipping_profiles/:profileId/';
export const UPDATE_SHIPPING_PROFILE = '/api/zeus/carrier/shipping_profiles/:id/';
export const UPDATE_LOCATIONS_PUBLIC_PROFILE = '/api/zeus/carrier/set_location_to_public_shipping_profile/:id/';
export const ADD_SHIPPING_ZONES = '/api/zeus/carrier/shipping_profiles/:id/add_shipping_zones/';
export const DEFAULT_CARRIER_PRIORITY =
  'api/zeus/carrier/shipping_profiles/default_carrier_priority/?from_country=:fromCountry&to_country=:toCountry&shipment_type=:shipmentType';
export const WAREHOUSE_ADD_SHIPPING_PROFILE = '/api/zeus/warehouse/:id/set_shipping_profile/';
export const EXPRESS_SHIPPING_RATE_NAMES = '/api/zeus/marketplace/:marketplace_id/checkout_options/';
export const CLONE_SHIPPING_PROFILE = '/api/zeus/carrier/shipping_profiles/:id/clone/';
export const SHIPPING_PROFILE_WAREHOUSE_LIST_URL = 'api/zeus/carrier/shipping_profiles/:id/get_warehouse_list';
export const SHIPPING_PROFILE_PICKUP_LOCATION_LIST_URL =
  'api/zeus/carrier/shipping_profiles/:id/get_pickup_location_list';
export const UPDATE_SHIPPING_PROFILE_PICKUP_LOCATION_URL = '/api/shipping/pickup/location/:id/set_shipping_profile/';

//zeus product urls
export const INVENTORY_PRODUCT_DASHBOARD_STOCK_URL = '/api/zeus/product/inventory_product_dashboard_stock/';
export const INVENTORY_PRODUCTS_URL = '/api/zeus/product/inventory/';
export const INVENTORY_FILTERS_URL = '/api/zeus/product/filters/inventory/';
export const INVENTORY_PRODUCTS_BULK_UPLOADS_URL = '/api/zeus/product/inventory_bulk_uploads/';
export const INVENTORY_PRODUCTS_BULK_DOWNLOAD_URL = '/api/zeus/product/inventory_bulk_document/id/';
export const INVENTORY_PRODUCTS_BULK_DOWNLOAD_ERROR_URL = '/api/zeus/product/inventory_bulk_error/id/';
export const INVENTORY_HISTORY_URL = '/api/zeus/product/inventory/:productId/stock/history/';
export const INVENTORY_PRODUCT_STATEMENT_URL = '/api/zeus/product/inventory/:productId/statement/history/';
export const INVENTORY_STOCK_DETAILS_URL = '/api/zeus/product/inventory/:productId/stock/detail/';
export const INVENTORY_SERIAL_NUMBERS_URL = '/api/zeus/product/inventory/:productId/serial_details/';
export const INVENTORY_SERIAL_NUMBER_HISTORY_URL = '/api/zeus/product/inventory/serial_details/:serial_number/history';
export const INVENTORY_METRICS_URL = '/api/zeus/product/dashboard/inventory/metrics/';
export const INVENTORY_PRODUCT_SYNC_URL = '/api/zeus/product/inventory/sync/:inventory_product_id';
export const INVENTORY_PRODUCT_RESERVED_STOCK_DETAILS_URL =
  '/api/zeus/product/reserved_stock_info/:warehouse_id/:inventory_id/';
export const INVENTORY_PRODUCT_PACK_TYPES_LIST =
  '/api/zeus/product/pack-hierarchy/:inventory_product_id/hierarchy-list/';
export const INVENTORY_PRODUCT_ADD_PACK_TYPE = '/api/zeus/product/pack-hierarchy/';
export const INVENTORY_PRODUCT_DELETE_PACK_TYPE = '/api/zeus/product/pack-hierarchy/:pack_type_id/';
export const CATALOGUE_PRODUCT_UPDATE_HISTORY =
  '/api/zeus/product/get_catalogue_product_update_history/:catalogue_product_id/';
export const CATALOGUE_UPDATE_INVENTORY_SYNC =
  '/api/zeus/product/:catalogue_product_id/set_catalogue_product_inv_sync/';
export const CATALOGUE_INVENTORY_MAPPING_URL = '/api/zeus/product/catalogue/inventory_mapping/';
export const CATALOGUE_INVENTORY_MAPPING_BULK_UPLOADS_URL =
  '/api/zeus/product/catalogue/inventory_mapping/bulk_upload/';
export const CATALOGUE_INVENTORY_MAPPING_BULK_DOWNLOAD_URL =
  '/api/zeus/product/catalogue/inventory_mapping/bulk_document/:id/';
export const CATALOGUE_LISTING_URL = '/api/zeus/product/catalogue/';
export const CATALOGUE_LISTING_FILTERS_URL = '/api/zeus/product/filters/catalogue/';
export const CATALOGUE_PRODUCT_SYNC_URL = '/api/zeus/product/catalogue/sync/:productId';

export const MARKETPLACE_SHOPEE_AUTH_URL = '/api/zeus/marketplace/shopee/:marketplace_id/authorize/';
export const MARKETPLACE_SHOPEE_VALIDATE_URL = '/api/zeus/marketplace/shopee/:marketplace_id/validate/';

export const MARKETPLACE_AMAZON_APP_URL = '/api/zeus/marketplace/amazon/:marketplace_id/app_url/';

export const MARKETPLACE_LAZADA_AUTH_URL = '/api/zeus/marketplace/lazada/:marketplace_id/authorize/';
export const MARKETPLACE_LAZADA_VALIDATE_URL = '/api/zeus/marketplace/lazada/validate/';

export const MARKETPLACE_TIKTOK_AUTH_URL = '/api/zeus/marketplace/tiktok/:marketplace_id/authorize/';
export const MARKETPLACE_TIKTOK_VALIDATE_URL = '/api/zeus/marketplace/tiktok/validate/';

export const MARKETPLACE_WIX_VALIDATE_URL = '/api/zeus/marketplace/wix/validate/';
export const MARKETPLACE_TOKOPEDIA_VALIDATE_URL = '/api/zeus/marketplace/tokopedia/validate/';

export const MARKETPLACE_EBAY_AUTH_URL = '/api/zeus/marketplace/ebay/:marketplace_id/authorize/';
export const MARKETPLACE_EBAY_VALIDATE_URL = '/api/zeus/marketplace/ebay/validate/';

export const MARKETPLACE_SHOPIFY_PRIVATE_VALIDATE_URL = '/api/zeus/marketplace/shopify/auth/private/validate/';
export const MARKETPLACE_SHOPIFY_CUSTOM_AUTHORIZE_URL = '/api/zeus/marketplace/shopify/auth/custom/authorize/';
export const MARKETPLACE_SHOPIFY_CUSTOM_VALIDATE_URL = '/api/zeus/marketplace/shopify/auth/custom/validate/';
export const MARKETPLACE_SHOPIFY_LOCATIONS_URL = '/api/zeus/marketplace/shopify/locations/:marketplace_connection_id/';
export const MARKETPLACE_SHOPIFY_LOCATION_SET_URL =
  '/api/zeus/marketplace/shopify/location/:marketplace_connection_id/set/';

export const MARKETPLACE_WOOCOMMERCE_VALIDATE_URL = '/api/zeus/marketplace/woocommerce/validate/';
export const MARKETPLACE_ZALORA_VALIDATE_URL = '/api/zeus/marketplace/zalora/validate/';
export const MARKETPLACE_CAPILLARY_VALIDATE_URL = '/api/zeus/marketplace/capillary/validate/';
export const CUSTOM_WEBSTORE_VALIDATE_URL = '/api/zeus/marketplace/custom/validate/';
export const MARKETPLACE_KUMU_VALIDATE_URL = '/api/zeus/marketplace/kumu/validate/';
export const MARKETPLACE_BBO_VALIDATE_URL = '/api/zeus/marketplace/bbo/validate/';
export const MARKETPLACE_GINEE_VALIDATE_URL = '/api/zeus/marketplace/ginee/validate/';
export const ZEUS_SALES_OVERVIEW_URL = '/api/zeus/order/sales-overview/';
export const ZEUS_STATUS_METRICS = '/api/zeus/order/fulfilment-status-metrics/';
export const ZEUS_STATUS_COUNT = '/api/zeus/order/fulfilment-status-count/';
export const ZEUS_AGE_METRICS = '/api/zeus/order/fulfilment-age-metrics/';
export const ZEUS_FULFILLMENT_SLA_METRICS = '/api/zeus/order/fulfilment-sla-metrics/';

// Order Dashboard SLA Visibility APIs
export const ZEUS_WEEKLY_DISPATCH_METRICS = '/api/zeus/order/weekly-dispatch-metrics/';
export const ZEUS_MONTHLY_DISPATCH_METRICS = '/api/zeus/order/monthly-dispatch-metrics/';
export const ZEUS_YEARLY_DISPATCH_METRICS = '/api/zeus/order/yearly-dispatch-metrics/';
export const ZEUS_WEEKLY_FULFILLMENT_METRICS = '/api/zeus/order/weekly-fulfilment-metrics/';
export const ZEUS_MONTHLY_FULFILLMENT_METRICS = '/api/zeus/order/monthly-fulfilment-metrics/';
export const ZEUS_YEARLY_FULFILLMENT_METRICS = '/api/zeus/order/yearly-fulfilment-metrics/';

// Carrier SLA UI Urls
export const CARRIER_SLA = '/api/zeus/carrier/draft_orders/';

//zeus order urls
export const ALL_FULFILLMENT_ORDERS = '/api/zeus/order/all-fulfilment-orders/';
export const ORDER_LISTING_URL = '/api/zeus/order/orders/';
export const ORDER_LISTING_FILTERS_URL = '/api/zeus/order/filters/';
export const RESHIP_CARRIER_ORDER_URL = '/api/zeus/order/reship/';
export const GET_RESHIP_CARRIER_ORDER_REASONS_URL = '/api/zeus/order/reship/';
export const ORDER_SYNC_URL = '/api/zeus/order/sync/:orderId/';
export const ORDER_SHIPPING_ADDRESS_UPDATE = '/api/zeus/order/orders/:orderId/address/update/shipping/';
export const ORDER_DOCUMENT_DOWNLOAD_URL = '/api/zeus/order/documents/:documentId';
export const FULFILLMENT_ORDER_REGENERATE_DOCUMENT_URL =
  '/api/zeus/order/documents/regenerate/:documentType/:forderId/';
export const RELEASE_FULFILLMENT_ORDER = '/api/zeus/order/orders/release_fulfilment_order_manually/:OrderID/';
export const RECREATE_FULFILLMENT_ORDER = '/api/zeus/order/recreate_fulfilment_order/';
//create bulk orders urls
export const GENERIC_BULK_UPLOADS_ARGUMENTS = '/api/shared/bulk_upload/:type/:templateType/arguments/';
export const GENERIC_BULK_UPLOADS_UPLOAD_FILE = '/api/shared/bulk_upload/:type/:templateType/upload_file/';
export const GENERIC_BULK_UPLOADS_UPLOADED_ENTRIES = '/api/shared/bulk_upload/:type/uploaded_entries/';
export const GENERIC_BULK_UPLOADS_DOWNLOAD_UPLOADED_FILE = '/api/shared/bulk_upload/file/:bulk_upload_id';
export const GENERIC_BULK_UPLOADS_DOWNLOAD_ERROR_FILE = '/api/shared/bulk_upload/errors/:bulk_upload_id';
export const GENERIC_BULK_UPLOADS_UPLOADS_META_DATA = '/api/shared/bulk_upload/:type/metadata/';
export const GENERIC_BULK_UPLOADS_DOWNLOAD_TEMPLATE =
  '/api/shared/bulk_upload/:upload_type/:template_type/template_file/';

//zeus inbound urls
export const INBOUND_CONSIGNMENT_URL = '/api/zeus/consignment/inbound/list/';
export const RETURN_ORDERS_LISTING_URL = '/api/zeus/order/return_orders/list/';

export const RETURN_ORDERS_FILTERS_URL = '/api/zeus/order/return_orders_filters/';
export const INBOUND_CONSIGNMENT_FILTERS_URL = '/api/zeus/consignment/inbound/filters/';
export const INBOUND_CONSIGNMENT_PRODUCTS_URL = '/api/zeus/consignment/inbound/products/consignment_id/';
export const INBOUND_CONSIGNMENT_BULK_UPLOADS_URL = '/api/zeus/consignment/inbound/bulk_uploads/';
export const INBOUND_CONSIGNMENT_BULK_DOWNLOAD_URL = '/api/zeus/consignment/inbound/bulk_uploads/document/id/';
export const INBOUND_CONSIGNMENT_BULK_DOWNLOAD_ERROR_URL = '/api/zeus/consignment/inbound/bulk_uploads/error/id/';
export const TRACKING_AND_CARRIER_PATCH_URL = '/api/zeus/consignment/inbound/list/:id/';

export const INBOUND_CONSIGNMENT_UPDATE = '/api/zeus/consignment/inbound/bulk_uploads/:id/';
export const INBOUND_CONSIGNMENT_EXPIRY_UPDATE = '/api/zeus/consignment/inbound/expiry_data/:id/';
export const INBOUND_CONSIGNMENT_DECISION_SUBMIT = '/api/zeus/consignment/inbound/decision/:id/?type=';
export const INBOUND_CONSIGNMENT_DOCUMENT_TYPES = '/api/zeus/consignment/inbound/supported_document_types';
export const INBOUND_CONSIGNMENT_UPLOAD_RELATED_FILES =
  '/api/zeus/consignment/inbound/related_files/?document_type=:document_type&consignment_id=';
export const INBOUND_CONSIGNMENT_RELATED_FILES = '/api/zeus/consignment/inbound/related_files/?consignment_id=';
export const INBOUND_CONSIGNMENT_DOWNLOAD_RELATED_FILE = '/api/zeus/consignment/inbound/related_files/:id';
export const INBOUND_CONSIGNMENT_DELETE_RELATED_FILE =
  '/api/zeus/consignment/inbound/related_files/?document_id=:id&update_type=deactivate';

// zeus inbounds VAS urls
export const CREATE_VAS_DRAFT_URL = '/api/zeus/consignment/vas/item/save/';
export const FETCH_VAS_DETAILS_URL = '/api/zeus/consignment/vas/item/{{vas_id}}/details/';
export const FETCH_VAS_AVAILABLE_ACTIVITIES_LIST_URL =
  '/api/zeus/consignment/vas/item/available_activities/?vas_id={{vas_id}}';
export const ADD_VAS_ACTIVITY_URL = '/api/zeus/consignment/vas/activity/add/';
export const UPDATE_VAS_ACTIVITY_URL = '/api/zeus/consignment/vas/activity/{{activity_id}}/';
export const CANCEL_VAS_URL = '/api/zeus/consignment/vas/item/action-execute/';
export const FETCH_VAS_ACTIVITY_DETAIL = '/api/zeus/consignment/vas/activity/{{activity_id}}/';
export const FETCH_PRODUCT_LEVEL_SKU_LIST_URL = '/api/zeus/consignment/inbound/products/cumulative/{{consignment_id}}/';
export const FILE_UPLOAD_URL = '/api/zeus/consignment/vas/activity/{{activity_id}}/file_upload/';
export const FILE_DELETE_URL = '/api/zeus/consignment/vas/activity/{{file_id}}/file_purge/';
export const FETCH_VAS_ACTIVITY_TEMPLATE =
  '/api/zeus/consignment/vas/activity/{{vas_id}}/download_quantities/?activity={{activity_name}}';
export const VAS_BULK_QUANTITY_UPDATE_URL = '/api/zeus/consignment/vas/activity/{{vas_id}}/upload_quantities/';

//zeus inbound urls
export const OUTBOUND_CONSIGNMENT_URL = '/api/zeus/consignment/outbound/list/';
export const OUTBOUND_CONSIGNMENT_FILTERS_URL = '/api/zeus/consignment/outbound/list/filters/';
export const OUTBOUND_CONSIGNMENT_PRODUCTS_URL = '/api/zeus/consignment/outbound/products/consignment_id/';
export const OUTBOUND_CONSIGNMENT_BULK_UPLOADS_URL = '/api/zeus/consignment/outbound/bulk_uploads/';
export const OUTBOUND_CONSIGNMENT_BULK_DOWNLOAD_URL = '/api/zeus/consignment/outbound/bulk_uploads/document/id/';
export const OUTBOUND_CONSIGNMENT_BULK_DOWNLOAD_ERROR_URL = '/api/zeus/consignment/outbound/bulk_uploads/error/id/';

//zeus urls
export const TASK_URL = '/api/zeus/task/status/:task_id';
export const UNITS_URL = '/api/zeus/product/supported_currencies_and_units/';
export const COUNTRIES_URL = '/api/geo/countries/';
export const STATES_URL = '/api/geo/countries/country_id/states/';
export const AREAS_1_URL = '/api/geo/states/state_id/administrative_areas1/';
export const AREAS_2_URL = '/api/geo/administrative_areas1/area1_id/administrative_areas2/';
export const ADDRESS_URL = '/api/geo/address/address_id/';

// shipment urls
export const SHIPMENT_SYNC_URL = '/api/zeus/carrier/order/:orderId/sync/';
export const SHIPMENT_ORDER_URL = '/api/shipping/order/';
export const SHIPMENT_ORDERS_LIST_URL = '/api/shipping/orders/all/';
export const SHIPMENT_ORDER_FILTERS_URL = '/api/shipping/order_filters/';
export const PICKUP_LOCATIONS_URL = '/api/shipping/pickup/location/';
export const BOOKMARKED_DELIVERY_ADDRESSES_URL = '/api/zeus/carrier/saved_delivery_address/';
export const ORDER_SELF_SHIP = '/api/zeus/carrier/order/:id/self_ship/';
export const BULK_ORDER_SELF_SHIP = '/api/zeus/carrier/order/self_ship_orders/';
export const BULK_ORDER_SELF_SHIP_DELETE = '/api/zeus/carrier/order/self_ship_and_delete_orders/';
export const BULK_CANCEL_ORDER = '/api/zeus/carrier/order/bulk_cancel_and_reopen/';
export const CARRIER_ORDER_SHIPPING_ADDRESS_UPDATE = '/api/zeus/carrier/order/:id/address/update/shipping/';
export const PICKUP_LOCATIONS_CARRIER_CONNECTIONS_MAPPING_URL =
  '/api/shipping/pickup/location/pickup_location_id/carrier_connections/';
export const SHIPMENT_TYPES_URL = '/api/shipping/shipment_types/';
export const SHIPMENT_ORDER_SYNC_URL = '/api/shipping/orders/all/order_id/sync/';
export const SHIPMENT_ORDER_CANCEL_URL = '/api/shipping/order/order_id/cancel/';
export const SHIPMENT_ORDER_RESHIP_URL =
  '/api/shipping/orders/all/order_id/reship/carrier_connection_id/shipment_type/';
export const SHIPMENT_CARRIER_CONNECTIONS_URL = '/api/shipping/order/order_id/carrier_connections/';
export const SHIPMENT_ORDER_WAYBILL_URL = '/api/shipping/waybill/order_id/';
export const BULK_SHIPMENT_ORDER_UPLOAD_URL = '/api/shipping/bulk_uploads/';
export const BULK_SHIPMENT_ORDER_DOWNLOAD_URL = '/api/shipping/shipment_bulk_document/id/';
export const BULK_SHIPMENT_ORDER_DOWNLOAD_ERROR_URL = '/api/shipping/shipment_bulk_error/id/';
export const RESHIP_SHIPMENT_URL = '/api/zeus/carrier/order/:order_id/reship/';
export const SET_DEFAULT_PICKUP_LOCATION_URL = '/api/shipping/pickup/location/:pickup_location_id/mark_as_default/';

//Reports URL
export const GET_REPORTS_TYPES = '/api/reports/types/list/';
export const GET_REPORT_ARGUMENTS = '/api/reports/types/:id/arguments/';
export const VALIDATE_REPORT = '/api/reports/types/:id/arguments/validate/';
export const SUBMIT_REPORT = '/api/reports/types/:id/arguments/submit/';
export const SCHEDULED_REPORT = '/api/reports/scheduled_reports/:scope/:id/';
export const SCHEDULED_REPORT_DURATIONS = '/api/reports/durations/';
export const GET_GENERATED_REPORTS = '/api/reports/list/';
export const GET_REPORT_STATUS = '/api/reports/requests/:id/status';
export const DOWNLOAD_GENERATED_REPORT = '/api/reports/download/';

// carrier urls
export const CARRIER_ORDERS_DETAIL_URL = '/api/zeus/carrier/order/';
export const CARRIER_ORDER_SYNC_URL = '/api/zeus/carrier/order/:carrier_order_id/sync/'; // SHIPMENT_ORDER_SYNC_URL
export const CARRIER_DRAFT_ORDER_LIST_URL = '/api/zeus/carrier/draft_orders/'; // SHIPMENT_ORDERS_LIST_URL
export const CARRIER_PENDING_ORDER_LIST_URL = '/api/zeus/carrier/pending_orders/';
export const CARRIER_TRACKING_ORDER_LIST_URL = '/api/zeus/carrier/tracking_orders/';
export const CARRIER_ORDER_SLOT_URL = '/api/zeus/carrier/connections/:carrier_connection_id/pickup_slots/';
export const CARRIER_DIM_WEIGHT_UPDATE_URL = '/api/zeus/carrier/order/:shipment_order_id/dim_update/';
export const CARRIER_PRICE_BY_DIM = '/api/zeus/carrier/order/:shipment_order_id/prices/?serviceability=:serviceability';
export const CARRIER_ORDER_BULK_BOOK_URL = '/api/zeus/carrier/bulk_book/';
export const BOOK_SHIPMENT_REQUESTS_URL = 'api/zeus/carrier/book_shipment_requests/:id/';
export const BULK_AWB_DOWNLOAD = '/api/zeus/carrier/bulk_waybill/ids/';
export const BULK_POD_DOWNLOAD = '/api/zeus/carrier/bulk_pod/:ids/';
export const CARRIER_DRAFT_ORDER_FILTERS_URL = '/api/zeus/carrier/draft_order_filters/';
export const CARRIER_PENDING_ORDER_FILTERS_URL = '/api/zeus/carrier/pending_order_filters/';
export const CARRIER_TRACK_ORDER_FILTERS_URL = '/api/zeus/carrier/track_order_filters/';
export const SHIPMENT_CARRIER_ORDER_UPDATE_URL = '/api/shipping/order/:carrier_order_id/update_carrier_order/';
export const CARRIER_ORDER_CANCEL = '/api/zeus/carrier/order/:shipment_order_id/cancel/';
export const CARRIER_ORDER_CANCEL_REOPEN = '/api/zeus/carrier/order/:shipment_order_id/cancel_and_reopen/';
export const CARRIER_SHIPMENT_STATUS_METRICS_URL = '/api/zeus/carrier/shipment_status_metrics/';
export const CARRIER_SHIPMENT_STATUS_FORWARD_AGE_URL = '/api/zeus/carrier/shipment_status_forward_age/';
export const CARRIER_SHIPMENT_STATUS_REVERSE_AGE_URL = '/api/zeus/carrier/shipment_status_reverse_age/';
export const CARRIER_SHIPMENT_STATUS_PICKUP_AGE_URL = '/api/zeus/carrier/shipment_status_pickup_age/';
export const CARRIER_SHIPMENT_STATUS_PICKUP_PIE_URL = '/api/zeus/carrier/shipment_status_pickup_pie/';
export const CARRIER_SHIPMENT_STATUS_DESTINATION_PIE_URL = '/api/zeus/carrier/shipment_status_destination_pie/';
export const CARRIER_SHIPMENT_STATUS_BAR_METRICS_URL = '/api/zeus/carrier/shipment_status_bar_metrics/';
export const CARRIER_SHIPMENT_AGEING_COUNT = '/api/zeus/carrier/shipment_carrier_ageing_count/';

//billing urls
export const BILLING_SHIPMENT_TRANSACTIONS = '/api/billing/shipping/transactions/:month/:year/';
export const BILLING_TRANSACTION_OVERVIEW = 'api/billing/shipping/overview/transactions/:month/:year/';
export const BILLING_SUBSCRIPTION_PLANS = '/api/billing/subscription/plans/:country_code/';
export const BILLING_DOWNLOAD_REPORT = '/api/billing/download/:year/:month/';
export const BILLING_SUBSCRIPTION = 'api/billing/subscription/';
export const BILLING_SUBSCRIPTION_CLASSIFIERS = 'api/billing/subscription/rate/search/:level_name/';
export const BILLING_VERSIONS = '/api/billing/profile/versions/';
export const BILLING_VERSIONS_ACTIVATE = '/api/billing/profile/versions/:id/activate/';
export const BILLING_VERSIONS_UPDATE = '/api/billing/profile/versions/:id/update/';
// export const BILLING_FULFILLMENT_RATES_LIST = "/api/billing/fulfilment/rate_cards/list/";
export const BILLING_FULFILLMENT_RATES_LIST = '/api/billing/fulfilment/rate_cards/:rate_type/list/';
export const BILLING_STORAGE_RATES_LIST = '/api/billing/storage/rate_cards/list/';
// export const BILLING_CREATE_FULFILLMENT_RATES = "/api/billing/fulfilment/rate/create/";
// export const BILLING_FULFILLMENT_RATE_DETAIL = "/api/billing/fulfilment/rate/detail/:id/";
// export const BILLING_CREATE_PACKAGING_MATERIAL_RATES = '/api/billing/fulfilment/packaging_materials/';
// export const BILLING_PACKAGING_MATERIAL_RATE_DETAIL = '/api/billing/fulfilment/packaging_materials/:code/';
export const BILLING_CREATE_FULFILLMENT_RATES = '/api/billing/fulfilment/rate/:rate_type/create/';
export const BILLING_FULFILLMENT_RATE_DETAIL = '/api/billing/fulfilment/rate/:rate_type/detail/:id/';
export const BILLING_CREATE_STORAGE_RATES = '/api/billing/storage/rate/create/';
export const BILLING_STORAGE_RATE_DETAIL = '/api/billing/storage/rate/detail/:id/';
export const BILLING_STORAGE_RATES_CLASSIFIERS = '/api/billing/storage/rate/search/:code/';
// export const BILLING_FULFILLMENT_RATES_CLASSIFIERS = "/api/billing/fulfilment/rate/search/:code/";
// export const BILLING_UPDATE_FULFILLMENT_RATES = "/api/billing/fulfilment/rate/create_or_edit/";
// export const BILLING_UPDATE_STORAGE_RATES = "/api/billing/storage/rate/create_or_edit/";
export const BILLING_FULFILLMENT_RATES_CLASSIFIERS = '/api/billing/fulfilment/rate/:rate_type/search/:code/';
export const BILLING_PROFILE_SKU_CLASSIFIER = '/api/billing/profile/sku_classifier/fetch/:id/';
export const BILLING_ASSIGN_SKU_CLASSIFIER = '/api/billing/profile/sku_classifier/assign/';
export const BILLING_SIZE_CLASSES = '/api/billing/size_classes/';
export const BILLING_SKU_CLASSIFIERS = '/api/billing/profile/sku_classifier/search/:level_name/';
export const BILLING_UPDATE_SKU_CLASSIFIER_PROFILE = '/api/billing/profile/sku_classifier/create_or_edit/';

export const PACKAGING_MATERIALS_URL = '/api/billing/fulfilment/packaging_materials/';
export const PACKAGING_SKU_CLASSIFIERS = '/api/billing/fulfilment/packaging_materials/:level/:code/';
export const PACKAGING_RATES = '/api/billing/fulfilment/packaging_materials/';
export const PACKAGING_SERVICEWISE_BILL_URL = '/api/billing/total/bill/:month/:year/PACKAGING,FRAGILE/';
export const PACKAGING_DEFAULT_RATES_URL =
  '/api/billing/default_rate/?service_type=:type&billing_level=:billing_level&name=:name';

export const SHIPMENT_TYPES_SKU_CLASSIFIERS = '/api/billing/fulfilment/shipment_types/options/';
export const SHIPMENT_TYPES_PROFILE_URL = '/api/billing/fulfilment/shipment_types/';

export const FRAGILE_MATERIALS_URL = '/api/billing/fulfilment/fragile/';
export const FRAGILE_SKU_CLASSIFIERS = '/api/billing/fulfilment/fragile/:level/:code/';
export const FRAGILE_RATES = '/api/billing/fulfilment/fragile/';
// export const PACKAGING_SKU_CLASSIFIERS = '/api/billing/profile/sku_classifier/search/:level_name/';
// export const PACKAGING_UPDATE_SKU_CLASSIFIER_PROFILE = '/api/billing/profile/sku_classifier/create_or_edit/';

export const BILLING_PROFILE = '/api/billing/profile/';
// export const BILLING_FULFILLMENT_RATE = "/api/billing/fulfilment/rate/";
export const BILLING_FULFILLMENT_REPORT_TRANSACTIONS_DETAIL_URL =
  '/api/billing/fulfilment/report/transactions/:billingFor/:month/:year/';
export const BILLING_FULFILLMENT_REPORT_QUANTITIES_DETAIL_URL =
  '/api/billing/fulfilment/report/quantities/:billingFor/:month/:year/';
export const BILLING_FULFILLMENT_TRANSACTIONS_URL =
  '/api/billing/fulfilment/transactions/:type/:billingFor/:month/:year/';
export const BILLING_FULFILLMENT_TRANSACTIONS_FAILED_URL =
  '/api/billing/fulfilment/transaction/failed/:billingFor/:month/:year/';

export const BILLING_FULFILLMENT_REPORT_ORDER_URL = '/api/billing/fulfilment/report/order/:id';

//B2B Billing URLs
export const BILLING_B2B_REPORT_TRANSACTIONS_DETAIL_URL =
  '/api/billing/fulfilment/report/bulk_outbound_transactions/:billingFor/:month/:year/';
export const BILLING_B2B_REPORT_QUANTITIES_DETAIL_URL =
  '/api/billing/fulfilment/report/bulk_outbound_transactions/quantities/:billingFor/:month/:year/';
export const BILLING_B2B_TRANSACTIONS_URL =
  '/api/billing/fulfilment/outbound_bulk_transactions/:type/:billingFor/:month/:year/';
export const BILLING_B2B_REPORT_ORDER_URL = '/api/billing/fulfilment/report/bulk_outbound_order/:id/';
export const BILLING_B2B_TRANSACTIONS_FAILED_URL =
  '/api/billing/fulfilment/bulk_outbound_transactions/failed/:billingFor/:month/:year/';

export const BILLING_STORAGE_REPORT_V1_TRANSACTIONS_DETAIL_URL =
  '/api/billing/storage/report/v1/transactions/monthly/:month/:year/';
export const BILLING_STORAGE_REPORT_V1_QUANTITIES_DETAIL_URL =
  '/api/billing/storage/report/v1/quantities/monthly/:month/:year/';
export const BILLING_STORAGE_REPORT_V1_LINEITEMS_URL =
  '/api/billing/storage/transactions/v1/lineitems/REVENUE/:month/:year/';

export const BILLING_TRANSACTIONS_V2_SUMMARY_URL = '/api/billing/storage/transactions/v2/monthly/:month/:year/summary/';
export const BILLING_TRANSACTIONS_V2_LINEITEMS_URL = '/api/billing/storage/transactions/v2/monthly/:month/:year/';
export const BILLING_TRANSACTIONS_V2_LINEITEM_DETAIL_URL =
  '/api/billing/storage/transactions/v2/daily/records/:day/:month/:year/';
export const BILLING_TRANSACTIONS_V2_LINEITEM_SUMMARY_URL =
  '/api/billing/storage/transactions/v2/daily/summary/:day/:month/:year/';
export const BILLING_TRANSACTIONS_V2_TOTAL_CBM_URL =
  '/api/billing/storage/transactions/v2/monthly/:month/:year/total_cbms/';

export const BILLING_COMPUTATION_AND_RATES_RETURN_ORDER_TRANSACTIONS =
  '/api/billing/fulfilment/report/return_order_transactions/RETURN/:month/:year/';
export const BILLING_COMPUTATOIN_AND_RATES_ALL_TRANSACTIONS =
  '/api/billing/fulfilment/returns/ALL/RETURN/:month/:year/';
export const BILLING_COMPUTATION_AND_RATES_FAILED_TRANSACTIONS =
  '/api/billing/fulfilment/return_order_transactions/failed/RETURN/:month/:year/';
export const BILLING_COMPUTATIONS_AND_RATES_ORDER_DETAIL = '/api/billing/fulfilment/report/return_order/:id/';
export const BILLING_COMPUTATION_AND_RATES_TRANSACTION_QUANTITIES_AND_TOTAL_COST =
  '/api/billing/fulfilment/report/return_order_transactions/quantities/RETURN/:month/:year/';

export const BILLING_STORAGE_REPORT_V1_TRANSACTIONS_ITEM_URL = '/api/billing/storage/report/v1/transactions/item/:id';
export const BILLING_DASHBOARD_STORAGE_UPCOMING_URL = '/api/billing/storage/upcoming_bills/:number_of_bills';
export const BILLING_DASHBOARD_SERVICEWISE_BILL_URL =
  '/api/billing/total/bill/:month/:year/FULFILMENT,STORAGE,SHIPPING/';
export const BILLING_STORAGE_REPORT_V1_QUANTITIES_ITEM_URL = '/api/billing/storage/report/v1/quantities/item/:id';
export const BILLING_STORAGE_REPORT_V1_ITEM_URL = '/api/billing/storage/transactions/v1/:id';

export const SHIPMENT_BILLING_TRANSACTIONS_SUMMARY_URL = '/api/billing/shipping/dashboard/transactions/:month/:year/';

export const DOWNLOAD_SHIPPING_RATES = '/api/zeus/carrier/download_rate_card/?country_code=:country';
export const SHIPPING_RATE_HISTORY =
  'api/zeus/carrier/get_rate_card_history/?country_code=:country_code&effective_date=:effective_date';
export const DOWNLOAD_SHIPPING_RATES_HISTORY =
  '/api/zeus/carrier/download_rate_card/?country_code=:country&effective_date=:effective_date&effective_till=:effective_till';
// billing dashboard payment statuses widget urls
export const BILLING_GET_ACCOUNT_URL = '/api/billing/billing_account/get_accessible_billing_accounts/';
export const BILLING_GET_ENTITY_LIST_URL = '/api/billing/entity/';
export const BILLING_GET_ACCOUNTS_LIST_URL = '/api/billing/billing_account/';
export const BILLING_GET_XERO_ACCOUNTS_LIST_URL = '/api/billing/xero/accounts/pull/?country_cd=:country_code';
export const BILLING_PULL_INVOICES_URL = '/api/billing/invoices/pull/:id/';
export const BILLING_CREATE_XERO_ACCOUNT = 'api/billing/xero/accounts/create/';
export const BILLING_MAP_BRAND_ENTITY = '/api/billing/map_entity/';

export const BILLING_GET_WAREHOUSE_CONTRACTS = '/api/billing/cost/warehousecontracttype/';

//User management URLs
export const EDIT_OR_DELETE_USER_URL = '/api/accounts/editdeleteuser/';
export const FETCH_ALL_ROLES_URL = '/api/accounts/getroles/';
export const GET_USERS_FOR_BRAND_URL = '/api/accounts/getusersforbrand/';

//Invite User
export const SEND_EMAIL_INVITE = '/api/accounts/sendinvite/';
export const GET_INVITED_USERS = '/api/accounts/loaduserinvites/';
export const EXPIRE_INVITES = '/api/accounts/expireuserinvites/';
export const VALIDATE_INVITES = '/api/accounts/acceptinvitation/';

//Wallet Apis
// export const WalletBalance = '/api/wallet/balance/';
// export const WalletTransactionList = '/api/wallet/transactions/';

//Notification Apis
export const BRAND_NOTIFICATIONS_URL = '/api/notification/brand_notification_preference/';

//Brand Details Apis
export const UPDATE_BRAND_DETAILS = '/api/accounts/brand/update_public_display_name/';

//Payment Apis
export const CREATE_PAYMENT_REQUEST_ID = '/api/wallet/top_up_requests/top_up/';
export const GET_PAYMENT_PROVIDERS = '/api/payment/payment_methods/';
export const MAKE_PAYMENT_REQUEST = '/api/payment/create_payment/:payment_request_id/';
export const CHECK_PAYMENT_STATUS = '/api/payment/:id/confirm_payment/';

// Self sign up APIs
export const GET_ALL_ONBOARDING_DATA = '/api/onboarding/ui/progress/';
export const GET_ONBOARDING_DATA_FILTERED = '/api/onboarding/ui/progress/:data_key/';
export const UPDATE_ONBOARDING_DATA = '/api/onboarding/ui/progress/:data_key/';
export const CREATE_ONBOARDING_DATA = '/api/onboarding/ui/progress/';

// Public SignUP
export const ONBOARD_CREATE_BRAND = '/api/onboarding/create/brand/'; // POST
export const ONBOARD_CREATE_STORE = '/api/onboarding/create_store/'; // POST
export const ONBOARD_CREATE_MARKETPLACE_CONNECTION = '/api/onboarding/marketplace_connection/create/';
export const ONBOARDING_REQUEST_DETAILS = '/api/onboarding/onboarding_request/:onboarding_id/'; // GET
export const ONBOARDING_GET_BRAND_NAME = '/api/onboarding/get_brand_name/'; // GET ?onboarding_request=45645552-f141-4dbb-aed3-a67f2fc73683"
export const ONBOARDING_GET_LOCATIONS = '/api/onboarding/get_locations/'; // GET ?onboarding_request=45645552-f141-4dbb-aed3-a67f2fc73683"
export const ONBOARDING_MIGRATE_USER = '/api/accounts/cognito/migrate/  ';

// B2B Distriubution
export const DELETE_B2B_ADDRESS = '/api/zeus/consignment/bulk_outbound_address/:id/address_delete/';
export const UPDATE_B2B_ADDRESS = '/api/zeus/consignment/bulk_outbound_address/:id/address_update/';
export const SEARCH_SKU = '/api/zeus/product/inventory/short/'; // ?warehouse_id=8&search=INV_SKU_ITEM0001
export const GET_OUTBOUND_ADDRESS = '/api/zeus/consignment/bulk_outbound_address/';
export const GET_CHANNELS = '/api/zeus/consignment/bulk_outbound_address/channels/';
export const GET_DOCUMENT_TYPES = '/api/zeus/consignment/bulk_outbound/documents/?channel_id=:id';
export const SAVE_NEW_B2B_ADDRES = '/api/zeus/consignment/bulk_outbound_address/';
export const SAVE_BULK_OUTBOUND_ADDRESS = 'api/zeus/consignment/bulk_outbound_address/:id/save/';
export const SAVE_BULK_OUTBOUND_SHIPPING = '/api/zeus/consignment/bulk_outbound_shipment/:id/save/';
export const SAVE_BULK_OUTBOUND_SHIPPING_CARRIER = '/api/zeus/consignment/bulk_outbound/:id/carrier-info/';
export const SAVE_BULK_OUTBOUND_WAREHOUSE_TRANSFER = '/api/zeus/consignment/bulk_outbound/update_warehouse_transfer/';
export const CREATE_DRAFT_DISTRIUBTION_ORDER = '/api/zeus/consignment/bulk_outbound/save/';
export const UPLOAD_DRAFT_FILES_DISTRIUBTION_ORDER = '/api/zeus/consignment/bulk_outbound/:draft_id/label/'; //put
export const DOWNLOAD_DRAFT_FILES_DISTRIUBTION_ORDER = '/api/zeus/consignment/bulk_outbound_label/:order_item_id';
export const GET_DETAILS_DISTRIBUTON_ORDER = '/api/zeus/consignment/bulk_outbound/:id/details/';
export const DOWNLOAD_PACKAGE_LABEL = '/api/zeus/consignment/bulk_outbound_label/:itemId/';
export const SUBMIT_B2B_ORDER = '/api/zeus/consignment/bulk_outbound/:id/submit/';
export const BULK_UPLOAD_SKU_ORDER = '/api/zeus/consignment/bulk_outbound/bulk_upload/?condition=:condition'; // URL Will Update once BE Provides
export const DESTINATION_DOWNLOAD_UPLOAD_DOCUMENT = '/api/zeus/consignment/bulk_outbound_document/:documentId/';
export const DELETE_B2B_DOCUMENT = '/api/zeus/consignment/bulk_outbound_address/:orderID/document/:docID/';
export const DELETE_B2B_ORDER = '/api/zeus/consignment/bulk_outbound/:id/';
export const CANCEL_B2B_ORDER = '/api/zeus/consignment/bulk_outbound/:id/cancel/';
export const CLONE_B2B_ORDER = '/api/zeus/consignment/bulk_outbound/:id/clone/';
export const GET_VALID_SHIPMENT_DATES = '/api/zeus/warehouse/warehouse-availability/:warehouseID/';
// Sample Req: https://dev5.api-server.golocad.com/api/zeus/warehouse/warehouse-availability/8/?start_date=17/10/2023&end_date=16/12/2023&service_type=OutboundB2C&buffer_days=2

// VAS Billing
export const VAS_BILLING_CLASSIFIERS = '/api/billing/vaservice/rate/search/:level_name/';
export const VAS_RATES_URL = 'api/billing/vaservice/rate-cards/list/';
export const VAS_OPTIONS_URL = '/api/billing/vaservice/vas-types/';
export const VAS_BILLING_RATE_CREATE_URL = '/api/billing/vaservice/rate/vas/';

export const BILLING_VAS_TRANSACTIONS_URL = '/api/billing/vaservice/transactions/monthly/:month/:year/';
export const BILLING_VAS_TRANSACTIONS_REPORT_URL = '/api/billing/vaservice/report/transactions/:month/:year/';
export const VAS_TOTAL_BILL_URL = '/api/billing/total/bill/:month/:year/VAS/';

//ORDER EDIT
export const SEARCH_SKU_ORDER_EDIT = '/api/zeus/product/inventory/lookup/';

//RETURNS
export const GET_RETURN_ORDER_REASONS = '/api/zeus/order/return_orders/create/metadata/';
export const SEARCH_ORDER_NUMBER = '/api/zeus/order/return_orders/create/';
export const POST_RETURN_DATA = '/api/zeus/order/return_orders/create/submit/?type=:type';
export const GET_ORDER_ITEMS = '/api/zeus/order/return_orders/create/:orderId/order_items/';
export const SEARCH_SKU_RETURNS = '/api/zeus/product/inventory/lookup/';
