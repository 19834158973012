import * as actionTypes from './actionTypes';
import {
  updateStepStatus,
  updateStepDefinitionLocally,
} from '../../../Components/Joyride/components/Onboarding/helpers';
const INITIAL_STATE = {
  showWelcomeMessage: false, // for initiating with welcomeMEssage
  initiatePartialTour: false, // for later use
  inProgress: true, // for having the callback handler of the tour to be in progress
  isSidebarOpen: true, // lets the sidebar be open
  openRouteIndex: 0, // for sidebar collapsiblse to be set to open at the give route from step definition
  useJoyrideIndexRoutes: false,
  run: false, // joyride specific
  continuous: true, // joyride specific
  loading: false, // joyride specific
  stepIndex: 0, // joyride specific
  step: 0, // joyride specific
  steps: [], // joyride specific
  initStep: 0, // joyride specific
  currentTargetId: '',
};

export default function sidebarStateAndJoyrideReducer(state = INITIAL_STATE, actions) {
  let updatedState = {};
  switch (actions.type) {
    case actionTypes.UPDATE_ROUTE_INDEX:
      return {
        ...state,
        ...actions.payload,
      };
    case actionTypes.SHOW_WELCOME_MESSAGE:
      return {
        ...state,
        ...actions.payload,
      };

    case actionTypes.START:
      updatedState = {
        ...state,
        ...actions.payload,
        steps: updateStepStatus(actions.payload?.steps, actions.payload?.currentTargetId),
      };
      if (!localStorage.getItem('onboardingFlow_1')) {
        updateStepDefinitionLocally(updatedState);
      } else {
        localStorage.setItem('onboardingFlow_1', JSON.stringify({ ...updatedState, showWelcomeMessage: false }));
      }
      return updatedState;

    case actionTypes.START_PARTIAL_ONBOARDING:
      updatedState = {
        ...state,
        ...actions.payload,
        steps: updateStepStatus(actions.payload?.steps, actions.payload?.currentTargetId),
      };
      if (!localStorage.getItem('onboardingFlow_1')) {
        updateStepDefinitionLocally(updatedState);
      }
      return updatedState;

    case actionTypes.MARK_PARTIAL_TOUR_VISITED:
      return {
        ...state,
        ...actions.payload,
      };

    case actionTypes.STOP:
      return {
        ...state,
        ...actions.payload,
      };

    case actionTypes.NEXT_OR_PREV:
      updatedState = {
        ...state,
        ...actions.payload,
        steps: updateStepStatus(state?.steps, actions?.payload?.currentTargetId),
      };
      if (localStorage.getItem('onboardingFlow_1')) {
        updateStepDefinitionLocally(updatedState);
      }
      return updatedState;

    case actionTypes.TERMINATE:
      return {
        showWelcomeMessage: false,
        inProgress: false,
        isSidebarOpen: true,
        openRouteIndex: 0,
        useJoyrideIndexRoutes: false,
        run: false,
        continuous: false,
        loading: false,
        stepIndex: 0,
        step: 0,
        steps: [],
        initStep: 0,
        currentTargetId: '',
        ...actions.payload,
      };

    default:
      return state;
  }
}
