import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { isOperableArray } from '../helpers/other';

/**
 * @example
 * const { state } = useQuery()
 */

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => {
    const qryparams = new URLSearchParams(search);
    return Object.fromEntries(qryparams.entries());
  }, [search]);
};

export const useAddQuery = () => {
  const history = useHistory();
  const { search } = useLocation();

  const setQuery = (key, value) => {
    const params = new URLSearchParams(search);
    if (params.has(key)) params.delete(key);
    params.append(key, value);
    history.replace({ search: params.toString() });
  };
  const setMultipleQuery = (queries) => {
    const params = new URLSearchParams();

    if (isOperableArray(Object.entries(queries))) {
      Object.entries(queries).forEach((query) => {
        const key = query[0];
        const value = query[1];

        params.set(key, value);
      });

      history.replace({ search: params.toString() });
    }
  };

  const removeMultipleQuery = (removalArr = []) => {
    let params = new URLSearchParams(search);
    if (isOperableArray(removalArr)) removalArr.forEach((key) => params.delete(key));
    else params = new URLSearchParams();
    history.replace({ search: params.toString() });
  };

  return [setQuery, setMultipleQuery, removeMultipleQuery];
};
