export const PRODUCTS = {
  title: "Products",
  CREATE: {
    title: "Create Product",
    secTitle: "Product creating",
  },
  PRODUCT_DASHBOARD: {
    title: "Product Dashboard",
  },
  CATALOGUE_PRODUCTS: {
    title: "Catalogue Products",
    secTitle: "Product Catalogue Listing",
  },
  INVENTORY_PRODUCTS: {
    title: "Inventory Products",
    CREATE_INVENTORY_PROUDUCTS: {
      title: "Create Inventory Product",
    },
  },
  PRODUCT_UPLOADS: {
    title: "Product Uploads",
  },
};
