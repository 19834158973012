import {
  CLOSE_CONFIRMATION_DIALOG,
  OPEN_CONFIRMATION_DIALOG,
} from "./actionTypes";

const initialState = {
  open: false,
  title: "",
  text: "",
  callback: null,
};

export default function confirmationDialog(state = initialState, action) {
  switch (action.type) {
    case OPEN_CONFIRMATION_DIALOG:
      return {
        open: true,
        title: action.payload.title,
        text: action.payload.text,
        callback: action.payload.callback,
      };

    case CLOSE_CONFIRMATION_DIALOG:
      return {
        ...state,
        open: false,
        callback: null,
      };

    default:
      return state;
  }
}
