import React from 'react';
import ProductTourLinkButton from './Joyride/components/Onboarding/ProductTourLinkButton';
import { Grid, ListItemText, ListItem as MuiListItem, useMediaQuery } from '@mui/material';
// import { NavLink as RouterNavLink } from "react-router-dom";

import { openPrivacy_TnCPage } from '../helpers/Footer';
import { withTheme } from '@mui/styles';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.palette.background.light};
  position: relative;
  height: 35px;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: ${(props) => props.theme.palette.common.black};
  }
  cursor: pointer;
`;

function Footer({ isSideNavOpen, user, routes }) {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <Wrapper data-testid="footer" style={{ marginLeft: !isSideNavOpen && isMdUp && '4rem' }}>
      <Grid container spacing={0}>
        {isMdUp && (
          <Grid container item xs={12} md={6} lg={6}>
            <ListItem onClick={() => openPrivacy_TnCPage('Privacy')}>
              <ListItemText primary="Privacy" />
            </ListItem>
            <ListItem onClick={() => openPrivacy_TnCPage('Terms of Service')}>
              <ListItemText primary="Terms of Service" />
            </ListItem>
            <ListItem id="product_tour">
              {user?.shipping_enabled ? <ProductTourLinkButton {...{ routes }} /> : null}
            </ListItem>
            <ListItem style={{ marginLeft: 'auto' }}>
              <ListItemText primary={`© ${new Date().getFullYear()} - LOCAD`} />
            </ListItem>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
}

export default withTheme(Footer);
