export const GET_CONSIGNMENTS = "GET_CONSIGNMENTS";
export const GET_CONSIGNMENTS_SUCCESS = "GET_CONSIGNMENTS_SUCCESS";
export const GET_CONSIGNMENTS_FAIL = "GET_CONSIGNMENTS_FAIL";

export const GET_CONSIGNMENT_FILTERS = "GET_CONSIGNMENT_FILTERS";
export const GET_CONSIGNMENT_FILTERS_SUCCESS =
  "GET_CONSIGNMENT_FILTERS_SUCCESS";
export const GET_CONSIGNMENT_FILTERS_FAIL = "GET_CONSIGNMENT_FILTERS_FAIL";

export const SET_CONSIGNMENTS_ACTIVE_FILTERS =
  "SET_CONSIGNMENTS_ACTIVE_FILTERS";
export const SET_CONSIGNMENTS_TABLE_STATE = "SET_CONSIGNMENTS_TABLE_STATE";
