import React from 'react';
import { useRedux } from '../hooks/useRedux';
import { setFrontDeskWidgetVisibility } from '../redux/frontDeskWidget/actions';

/**
 * Helpdesk script should be hidden in auth pages.
 * It's visible by default, so we have to call this component with type 'hide' in Auth layout to hide it on auth page
 * and call with 'show' type in Dashboard layout.
 * it's handling visibility and also prefills some user data to it's inputs.
 * @param type 'show' | 'hide'
 */

function FrontDeskWidgetAction({ type }) {
  const [[user, visible], dispatch] = useRedux((state) => [state.auth.user, state.frontDeskWidget.visible]);

  React.useEffect(() => {
    if (window.FreshworksWidget) {
      if (type === 'show' && !visible) {
        dispatch(setFrontDeskWidgetVisibility(true));
        window.FreshworksWidget('show');
      } else if (type === 'hide' && visible) {
        dispatch(setFrontDeskWidgetVisibility(false));
        window.FreshworksWidget('hide');
      }
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    // prefill user info
    if (user && window.FreshworksWidget) {
      window.FreshworksWidget('identify', 'ticketForm', {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
      });
      window.FreshworksWidget('prefill', 'ticketForm', {
        customFields: {
          cf_external_brand: user.current_brand.name,
        },
      });
      window.FreshworksWidget('disable', 'ticketForm', ['custom_fields.cf_external_brand', 'email']);
    }
  }, [user]);

  return null;
}

export default FrontDeskWidgetAction;
