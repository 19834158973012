import React from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';

import { Grid, AppBar as MuiAppBar, IconButton as MuiIconButton, Toolbar, useMediaQuery } from '@mui/material';

import { Menu as MenuIcon } from '@mui/icons-material';
import { Power } from 'react-feather';

import { logout } from '../redux/auth/actions';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function Header(props) {
  const isMdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <>
      {isMdDown && (
        <AppBar position="sticky" elevation={0} data-testid="header">
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton color="inherit" aria-label="Open drawer" onClick={props.onDrawerToggle} size="large">
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item xs />
              <Grid item>
                <IconButton onClick={props.logout} color="inherit" data-testid="log-out" size="large">
                  <Power />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Header));
