import {
  updateRouteIndex,
  incrementNextStep,
  stop,
  markPartialTourVisited,
  terminate,
} from '../../../../redux/joyride/SelfServeOnboarding/actions';
import {
  startProductTourPrompt,
  stopProductTourPrompt,
} from '../../../../redux/joyride/StartProductTourPrompt/actions';
import { getRouteIndex } from './helpers';

export function onboardingCallbackHandler({ data, dispatch, ACTIONS, EVENTS, routes }) {
  const { action, index, type, step } = data;
  let nextOrPrevStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

  if ([EVENTS.TARGET_NOT_FOUND].includes(type)) {
    console.error(`For Step index:${index}, type: ${type}, action: ${action} step details so far: ${step}`);
    dispatch(stop());
    dispatch(terminate());
  }

  if (action === 'next') {
    if (type === 'step:after' && (index === 0 || index === 1)) {
      dispatch(
        updateRouteIndex({
          openRouteIndex: getRouteIndex(routes, step?.nextTarget?.substring(1, step.nextTarget.length)),
          loading: true,
          run: false,
          continuous: false,
        })
      );
      setTimeout(() => {
        dispatch(
          incrementNextStep({
            loading: false,
            run: true,
            continuous: true,
            currentTargetId: step.nextTarget.substring(1, step.nextTarget.length),
            stepIndex: nextOrPrevStepIndex,
            step: nextOrPrevStepIndex,
          })
        );
      }, 300);
    } else if (type === 'step:after' && index === 2) {
      dispatch(
        updateRouteIndex({
          openRouteIndex: getRouteIndex(routes, step?.nextTarget?.substring(1, step.nextTarget.length)),
          loading: true,
          run: false,
          continuous: false,
        })
      );
      setTimeout(() => {
        dispatch(
          incrementNextStep({
            loading: false,
            run: true,
            continuous: true,
            currentTargetId: step.nextTarget.substring(1, step.nextTarget.length),
            stepIndex: nextOrPrevStepIndex,
            step: nextOrPrevStepIndex,
          })
        );
      }, 100);
    } else if (type === 'step:after' && index === 3) {
      dispatch(stop());
      setTimeout(() => {
        dispatch(startProductTourPrompt({ run: true, continuous: true }));
      }, 500);
      setTimeout(() => {
        dispatch(stopProductTourPrompt({ parentRoute: '/shipments' }));
      }, 4000);
    } else if (type === 'step:after' && index > 3) {
      dispatch(stop());
      setTimeout(() => {
        dispatch(startProductTourPrompt());
      }, 500);

      setTimeout(() => {
        dispatch(stopProductTourPrompt({ parentRoute: '/shipments' }));
      }, 4000);
    }
  } else {
    if (action === 'skip') {
      dispatch(markPartialTourVisited());
      dispatch(stop());
      setTimeout(() => {
        dispatch(startProductTourPrompt());
      }, 500);

      setTimeout(() => {
        dispatch(stopProductTourPrompt({ parentRoute: '/shipments' }));
      }, 4000);
    }
  }
}
