import React from "react";
import styled from "styled-components";
import Done from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { normalizeDateRange, normalizeDateToTimezone } from "./datetime";
import { FormGroup, TextField as MuiTextField } from "@mui/material";
import { sizing } from "@mui/system";
import { findColumnIndexes } from "./workWithData";

const TextField = styled(MuiTextField)(sizing);

const DoneIcon = styled(Done)`
  color: ${(props) => props.theme.palette.success.main};
`;

export const renderBooleanValueIcon = (value) => (value ? <DoneIcon /> : <ClearIcon color="error" />);

export const getDatalabelsOptions = (theme) => ({
  font: {
    family: theme.typography.overline.fontFamily,
    size: 12,
  },
  color: "#000000",
  display: "auto",
});

// to create table date filters
export const getDateRangeCustomFilterColumnOptions = (defaultStartDate, defaultEndDate) => {
  return {
    customBodyRender: (time) => normalizeDateToTimezone(time),
    filterType: "custom",
    filterList: defaultStartDate && defaultEndDate ? [defaultStartDate, defaultEndDate] : null,
    customFilterListOptions: {
      render: (v) => {
        if (v[0] && v[1] && v[0] <= v[1]) {
          return `From: ${normalizeDateRange(v[0], "start", true)}, To: ${normalizeDateRange(v[1], "end", true)}`;
        } else if (!v[1]) {
          return `To Date was not chosen`;
        }
        return `From Date can not be later than To Date`;
      },
      update: (filterList, filterPos, index) => {
        if (filterPos === 0) {
          filterList[index].splice(filterPos, 1, "");
        } else if (filterPos === 1) {
          filterList[index].splice(filterPos, 1);
        } else if (filterPos === -1) {
          filterList[index] = [];
        }
        return filterList;
      },
    },
    filterOptions: {
      display: (filterList, onChange, index, column) => {
        const startDate = filterList[index][0];
        const endDate = filterList[index][1];

        return (
          <FormGroup row>
            <TextField
              width={1}
              label="From Date"
              id="from-date"
              name="from-date"
              value={startDate || ""}
              type="date"
              error={endDate < startDate}
              onChange={(event) => {
                filterList[index][0] = event.target.value;
                onChange(filterList[index], index, column);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              width={1}
              label="To Date"
              id="to-date"
              name="to-date"
              value={filterList[index][1] || ""}
              type="date"
              disabled={!startDate}
              error={(startDate && !endDate) || endDate < startDate}
              onChange={(event) => {
                filterList[index][1] = event.target.value;
                onChange(filterList[index], index, column);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormGroup>
        );
      },
    },
  };
};

export const convertCSVColumnsToTextOnDownload =
  (columnEntity, entityValues) => (buildHead, buildBody, columns, data) => {
    const indexes = findColumnIndexes(columns, columnEntity, entityValues);

    const newData = data.map((item) => {
      indexes.forEach((dataIndex) => {
        const value = String(item.data[dataIndex]);
        item.data[dataIndex] = value.startsWith("0") && !isNaN(Number(value)) ? "'" + value : value;
      });
      return item;
    });

    return buildHead(columns) + buildBody(newData);
  };
