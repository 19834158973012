export const UNMAPPED_PRODUCT_TOOLTIP_MESSAGE = [
  { type: 'unmatchedSKU', msg: 'Inventory SKU and Catalogue SKU have different value' },
  {
    type: 'notFulfillable',
    msg: 'No Inventory linked to the Catalogue Product. Item will not be fulfillable by Warehouse.',
  },
];

export const COLUMNS_WITH_HELPING_ICONS = ['Sellable Stock:', 'Marketplace Locked Stock:', 'Marketplace Stock:'];

export const STORAGE_TYPE_OPTIONS = [
  { label: 'Ambient', value: 'ambient' },
  { label: 'Temp Controlled', value: 'tempcontrolled' },
  { label: 'Cold Storage', value: 'coldstorage' },
  { label: 'High Value', value: 'highvalue' },
];
