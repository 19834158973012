export const BILLING = {
  title: 'Billing',
  BILLIG_D: {
    title: 'Billing Dashboard',
  },
  FULFILLMENT_BILLING: {
    title: 'Fulfillment Billing',
  },
  RETURN_BILLING: {
    title: 'Return Billing',
  },
  B2B_BILLING: {
    title: 'B2B Billing',
  },
  PACKAGING_BILLING: {
    title: 'Packaging Billing',
  },
  STORAGE_BILLING: {
    title: 'Storage Billing',
  },
  SHIPMENT_BILLING: {
    title: 'Shipment Billing',
  },
  VAS_BILLING: {
    title: 'VAS Billing',
  },
  BILLING_RATES: {
    title: 'Billing Rates',
    STORAGE_RATE_EDIT: {
      title: 'Edit Storage Rate',
    },
    FULFILLMENT_RATE_EDIT: {
      title: 'Edit Fulfillment Rate',
    },
    PACKAGING_MATERIAL_RATE_EDIT: {
      title: 'Edit Packaging Material Rate',
    },
  },
};
