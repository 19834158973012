import {
  GET_WAREHOUSES,
  GET_WAREHOUSES_FAIL,
  GET_WAREHOUSES_SUCCESS,
} from "./actionTypes";

const initialState = {
  items: [],
  brand: null,
  isLoading: false,
  error: null,
};

export default function warehouses(state = initialState, action) {
  switch (action.type) {
    case GET_WAREHOUSES: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case GET_WAREHOUSES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        items: action.response.warehouses,
        brand: action.response.brand,
      };
    }
    case GET_WAREHOUSES_FAIL: {
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
