import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function SimpleBackdrop({ show }) {
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={show}>
        <CircularProgress style={{ color: 'var(--colors-secondary)' }} />
      </Backdrop>
    </div>
  );
}
