import * as actionTypes from "./actionTypes";

const catalogueListingState = {
  listing: {
    data: {},
    isLoading: true,
    loaded: false,
    error: null,
  },
  tableState: {
    page: 0,
    rowsPerPage: 20,
    searchText: "",
    sortOrder: {},
  },
  detail: {
    data: {},
    isLoading: true,
    error: null,
  },
  filters: {
    data: {},
    isLoading: true,
    loaded: false,
    error: null,
  },
  activeFilters: {
    marketplace_country: null,
    product_type: null,
  },
  inventoryMapping: {
    isUpdating: false,
    error: null,
  },
};

export default function catalogue(state = catalogueListingState, action) {
  switch (action.type) {
    case actionTypes.GET_CATALOGUE_LISTING_ITEMS:
      return { ...state, listing: { ...state.listing, isLoading: true } };
    case actionTypes.GET_CATALOGUE_LISTING_ITEMS_SUCCESS:
      return {
        ...state,
        listing: {
          ...state.listing,
          isLoading: false,
          data: action.response,
          loaded: true,
        },
      };
    case actionTypes.GET_CATALOGUE_LISTING_ITEMS_FAIL:
      return {
        ...state,
        listing: { ...state.listing, isLoading: false, error: action.error },
      };

    case actionTypes.GET_CATALOGUE_LISTING_FILTERS:
      return { ...state, filters: { ...state.filters, isLoading: true } };
    case actionTypes.GET_CATALOGUE_LISTING_FILTERS_SUCCESS:
      return {
        ...state,
        filters: {
          ...state.filters,
          isLoading: false,
          data: action.response,
          loaded: true,
        },
      };
    case actionTypes.GET_CATALOGUE_LISTING_FILTERS_FAIL:
      return {
        ...state,
        filters: { ...state.filters, isLoading: false, error: action.error },
      };

    case actionTypes.GET_CATALOGUE_LISTING_DETAIL:
      return {
        ...state,
        detail: { ...state.detail, isLoading: true, data: [] },
      };
    case actionTypes.GET_CATALOGUE_LISTING_DETAIL_SUCCESS:
      return {
        ...state,
        detail: { ...state.detail, isLoading: false, data: action.response },
      };
    case actionTypes.GET_CATALOGUE_LISTING_DETAIL_FAIL:
      return {
        ...state,
        detail: { ...state.detail, isLoading: false, error: action.error },
      };

    case actionTypes.UPDATE_CATALOGUE_PRODUCT_MAPPING:
      return {
        ...state,
        inventoryMapping: { ...state.inventoryMapping, isUpdating: true },
      };
    case actionTypes.UPDATE_CATALOGUE_PRODUCT_MAPPING_SUCCESS:
      return updateDataAfterMappingChange(state, action.response);
    case actionTypes.UPDATE_CATALOGUE_PRODUCT_MAPPING_FAIL:
      return changeStateDueToMappingError(state, action.error?.data);

    case actionTypes.SET_CATALOGUE_LISTING_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: { ...state.activeFilters, ...action.payload.filters },
        tableState: { ...state.tableState, page: 0 },
      };

    case actionTypes.SET_CATALOGUE_LISTING_TABLE_STATE:
      return {
        ...state,
        tableState: { ...state.tableState, ...action.payload.tableState },
      };

    case actionTypes.CLEAR_INVENTORY_MAPPING_ERRORS:
      return {
        ...state,
        inventoryMapping: { ...state.inventoryMapping, error: null },
      };

    case actionTypes.RESTORE_CATALOGUE_LISTING_REDUCER:
      return catalogueListingState;

    default:
      return state;
  }
}

const changeStateDueToMappingError = (state, error) => ({
  ...state,
  inventoryMapping: { ...state.inventoryMapping, isUpdating: false, error },
});

const updateDataAfterMappingChange = (state, response) => ({
  ...state,
  inventoryMapping: { ...state.inventoryMapping, isUpdating: false },
  detail: {
    ...state.detail,
    data: response,
  },
  listing: updateListingStateAfterMappingChange(state, response),
});

const updateListingStateAfterMappingChange = (state, response) => ({
  ...state.listing,
  data: {
    ...state.data,
    results: state.listing.data.results.map((item) => {
      if (item.id === response.id) {
        return updateListingItemAfterMappingChange(item, response);
      } else {
        return item;
      }
    }),
  },
});

const updateListingItemAfterMappingChange = (item, updatedItem) => ({
  ...item,
  is_kit: updatedItem.is_kit,
  inventory_product: updatedItem.inventory_product,
  kit_inventory_products: updatedItem.kit_inventory_products,
});
