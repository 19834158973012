import React from 'react';
import { connect } from 'react-redux';
import { clearPreviousNotification, hideNotification, openNotification } from '../redux/notifications/actions';

import { Snackbar, Box } from '@mui/material';
import Slide from '@mui/material/Slide';
import { Alert } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { notifications } from '../constants';

const DEFAULT_MESSAGES_TO_TYPE_MAP = {
  error: notifications.ERROR.something_wrong,
  success: 'Success.',
  info: 'Info.',
  warning: 'Something is wrong.',
};
const TEST_ID_TO_TYPE_MAP = {
  error: 'error-message',
  success: 'success-message',
  info: 'info-message',
  warning: 'warning-message',
};

function AppSnackbar({ notifications, openNotification, hideNotification, clearPreviousNotification }) {
  const history = useHistory();
  const [openNewOnExited, setOpenNewOnExited] = React.useState(false);

  React.useEffect(() => {
    return history.listen(() => {
      if (notifications.open) {
        hideNotification();
      }
    });
    // eslint-disable-next-line
  }, [notifications.open]);

  // show previous notification if new one was opened while old exists
  const visibleNotification = notifications.previousNotification ? notifications.previousNotification : notifications;

  const message =
    visibleNotification.message || DEFAULT_MESSAGES_TO_TYPE_MAP[visibleNotification.type] || 'Empty notification.';
  const title = '';
  return (
    (<Snackbar
      data-testid="app-snackbar"
      open={notifications.open}
      autoHideDuration={8000}
      onClose={(event, reason) => {
        hideNotification();
        if (reason === 'clickaway' && notifications.previousNotification) {
          // prev notification still exists and new one was opened
          setOpenNewOnExited(true);
        }
      }}
      TransitionComponent={Slide}
      anchorOrigin={{
        vertical: visibleNotification.verticalAnchor || 'top',
        horizontal: visibleNotification.horizontalAnchor || 'center',
      }}
      message={visibleNotification.type ? null : message}
      title={visibleNotification.type ? null : title}
      TransitionProps={{
        onExited: () => {
          if (openNewOnExited) {
            // close old one and show new after
            clearPreviousNotification();
            openNotification();
            setOpenNewOnExited(false);
          }
        }
      }}>
      {visibleNotification.type ? (
        <Alert
          onClose={hideNotification}
          severity={visibleNotification.type}
          data-testid={TEST_ID_TO_TYPE_MAP[visibleNotification.type]}
        >
          {title ? <Box>{title}</Box> : null}
          {message}
        </Alert>
      ) : null}
    </Snackbar>)
  );
}

const mapStateToProps = ({ notifications }) => ({ notifications });

const mapDispatchToProps = (dispatch) => ({
  hideNotification: () => dispatch(hideNotification()),
  openNotification: () => dispatch(openNotification()),
  clearPreviousNotification: () => dispatch(clearPreviousNotification()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSnackbar);
