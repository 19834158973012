import { paths } from '../../routes/constants';
import { getSidebarRoutes } from '../../routes';
import { getUserDashboardFlags, applyDashboardFlags } from '../../routes/helpers';
import { getSearchQueryFromLocation } from '../Order/helpers';
import { BRAND_NAME, EMAIL, LOCAL_STORAGE_EMAIL, LOCAL_STORAGE_BRAND_NAME } from '../../Auth/Constants/constants';

export const resolveUserRedirect = (flags) => {
  const fulfillmentAndShippingEnabled = flags.shipping_enabled && flags.fulfilment_enabled;
  const fulfillmentOnlyEnabled = !flags.shipping_enabled && flags.fulfilment_enabled;

  if (flags.shipping_enabled || fulfillmentAndShippingEnabled) {
    return paths.MANAGE_SHIPMENTS.replace(':listId', 'list');
  }

  if (fulfillmentOnlyEnabled) {
    return paths.SALES_OVERVIEW;
  }
};
export const verifyNextParameter = (nextRoute, redirectByFlag, user) => {
  const userDashboardFlags = getUserDashboardFlags(user);
  const userRoutes = applyDashboardFlags(getSidebarRoutes(user.dashboard_version), userDashboardFlags);
  const parentRoutes = [...userRoutes].map((route) => route.path.replace(':listId', 'list'));
  let childRoutes = [];
  // eslint-disable-next-line array-callback-return
  userRoutes.map((route) => {
    if (route.children) {
      [...route.children].map((child) => childRoutes.push(child.path.replace(':listId', 'list')));
    }
  });
  const nextPathParam = [...parentRoutes, ...childRoutes].find((path) => path === nextRoute);
  let redirectPath = redirectByFlag;

  if (nextRoute && nextPathParam) {
    redirectPath = nextPathParam;
  }

  return redirectPath;
};

export const checkEmailInvitationParams = () =>
  Boolean(
    getSearchQueryFromLocation(window.location)?.get(EMAIL) &&
      getSearchQueryFromLocation(window.location)?.get(BRAND_NAME)
  );
export const getEmailInviteParams = () => ({
  email: getSearchQueryFromLocation(window.location)?.get(EMAIL),
  brand_name: getSearchQueryFromLocation(window.location)?.get(BRAND_NAME),
});

// this is mainly used to match the next parameter form shopify
export const matchNextParameter = (nextUrl, urlToMatch, { pathParams = false }) => {
  if (nextUrl && urlToMatch) {
    const nextParts = nextUrl.split('/');
    const urlToMatchParts = urlToMatch.split('/');
    if (nextParts.length !== urlToMatchParts.length) return false;
    if (pathParams) {
      nextParts.splice(-1);
      urlToMatchParts.splice(-1);
    }
    const strNextParts = nextParts.join('');
    const strurlToMatchParts = urlToMatchParts.join('');
    if (strNextParts === strurlToMatchParts) return true;
  }
  return false;
};
export const checkEmailInvitationLocalStorage = () =>
  Boolean(localStorage.getItem(LOCAL_STORAGE_EMAIL) && localStorage.getItem(LOCAL_STORAGE_BRAND_NAME));
export const getEmailInviteLocalStorage = () => ({
  email: localStorage.getItem(LOCAL_STORAGE_EMAIL),
  brand_name: localStorage.getItem(LOCAL_STORAGE_BRAND_NAME),
});
export const DeleteEmailInviteLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_EMAIL);
  localStorage.removeItem(LOCAL_STORAGE_BRAND_NAME);
};

export const redirectAfterSignUp = (user, history) => {
  const { fulfilment_enabled, shipping_enabled } = getUserDashboardFlags(user);
  const onlyFulfilment = fulfilment_enabled && !shipping_enabled;
  const shippingEnabled = shipping_enabled;
  if (onlyFulfilment) {
    history.push(paths.MARKETPLACE_SETTINGS);
    return;
  } else if (shippingEnabled) {
    history.push({
      pathname: paths.MANAGE_SHIPMENTS.replace(':listId', 'list'),
      search: '?referrer=shopify',
    });
    return;
  } else {
    history.push(paths.INDEX);
    // history.push({
    //   pathname: paths.MANAGE_SHIPMENTS.replace(":listId", "list"),
    //   search: "?referrer=shopify",
    // });
  }
};

export const isMigrationEnabledForDev = () => {
  return Boolean(
    window.locadui.AUTHORIZATION_URL &&
      window.locadui.AUTH_CLIENT_ID &&
      window.locadui.SIGNUP_URL &&
      window.locadui.SIGNOUT_URL
  );
};
