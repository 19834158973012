export const ORDER = {
  title: 'Orders',
  ORDER_DASHBOARD: {
    title: 'Order Dashboard',
  },
  MANAGE_ORDERS: {
    title: 'Manage Orders',
  },
  ORDER_UPLOADS: {
    title: 'Order Uploads',
    uploadType: 'ORDER_BULK_UPLOAD',
  },
  ORDER_FORECAST: {
    title: 'Manage Forecast',
  },
};
