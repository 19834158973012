export const AUTH = {
  title: 'Auth',
  LOGIN: {
    title: 'Login',
  },
  LINK: {
    title: 'Link Brand to Account',
  },
  EMAIL_EXPIRED: {
    title: 'Expired Email Invite',
  },
  EMAIL_INVITATION: {
    title: 'Sign Up from Email Invite',
  },
  EMAIL_MISMATCH: {
    title: 'Email Mismatch',
  },
  SIGNUP: {
    title: 'Signup',
  },
  SHOPIFY_SIGNUP: {
    title: 'ShopifySignup',
  },
  SHOPIFY_CREATE_USER: {
    title: 'ShopifyCreateUser',
  },
  SHOPIFY_MAP_USER_LOCATION: {
    title: 'ShopifyUserMapLocation',
  },
  SHOPIFY_HUBSPOT_USER_SIGN_IN: {
    title: 'ShopifyHubspotUserSignin',
  },
  AMAZON_SIGNUP: {
    title: 'AmazonSignup',
  },
  AUTH_404: {
    title: '404 Page',
  },
  AUTH_500: {
    title: '500 Page',
  },
  AUTH_LOGOUT: {
    title: 'Logout',
  },
};
