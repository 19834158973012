export const GET_SHIPPING_PROFILES = 'GET_SHIPPING_PROFILES';
export const GET_SHIPPING_PROFILES_SUCCESS = 'GET_SHIPPING_PROFILES_SUCCESS';
export const GET_SHIPPING_PROFILES_FAIL = 'GET_SHIPPING_PROFILES_FAIL';
export const GET_SHIPPING_PROFILES_INFO = 'GET_SHIPPING_PROFILES_INFO';
export const GET_SHIPPING_PROFILES_SUCCESS_INFO = 'GET_SHIPPING_PROFILES_SUCCESS_INFO';
export const GET_SHIPPING_PROFILES_FAIL_INFO = 'GET_SHIPPING_PROFILES_FAIL_INFO';

export const GET_SHIPPING_PROFILE_WAREHOUSES = 'GET_SHIPPING_PROFILE_WAREHOUSES';
export const GET_SHIPPING_PROFILE_WAREHOUSES_SUCCESS = 'GET_SHIPPING_PROFILE_WAREHOUSES_SUCCESS';
export const GET_SHIPPING_PROFILE_WAREHOUSES_FAIL = 'GET_SHIPPING_PROFILE_WAREHOUSES_FAIL';

export const GET_SHIPPING_PROFILE_PICKUP_LOCATIONS = 'GET_SHIPPING_PROFILE_PICKUP_LOCATIONS';
export const GET_SHIPPING_PROFILE_PICKUP_LOCATIONS_SUCCESS = 'GET_SHIPPING_PROFILE_PICKUP_LOCATIONS_SUCCESS';
export const GET_SHIPPING_PROFILE_PICKUP_LOCATIONS_FAIL = 'GET_SHIPPING_PROFILE_PICKUP_LOCATIONS_FAIL';
