export const IMG_SRC = {
  images: [
    {
      url: '/static/img/avatars/slides/Sales_Overview.png',
      title: 'Sales overview across all your channels',
    },
    {
      url: '/static/img/avatars/slides/Orders_Dashboard.png',
      title: 'Visibility in order fulfillment in every step',
    },
    {
      url: '/static/img/avatars/slides/Shipment_Dashboard.png',
      title: 'Real-time view of your Shipment',
    },
  ],
  DEMOS: {
    DARK: '/static/img/screenshots/dark-small.png',
    LIGHT: '/static/img/screenshots/light-small.png',
    BLUE: '/static/img/screenshots/blue-small.png',
    GREEN: '/static/img/screenshots/green-small.png',
    INDIGO: '/static/img/screenshots/indigo-small.png',
    TEAL: '/static/img/screenshots/teal-small.png',
  },
  AWB: '/static/img/icons/assignment_late.svg',
  AWB_READ: '/static/img/icons/AWB_Read.svg',
  AWB_NA: '/static/img/icons/AWB_NA.svg',
  AWB_NEW: '/static/img/icons/AWB_New.svg',
  NOT_OK_ICON: '/static/img/icons/rejected-parcel.svg',
  PICKUP_DATE: '/static/img/icons/Pickup_date.svg',
  LOCAL_SHIPPING: '/static/img/icons/local_shipping.svg',
  FIRE_BOLT: '/static/img/icons/fire-bolt.svg',
  EXCEL_ICON: '/static/img/icons/icons-excel-medium.svg',
  STD_SHIPPING: '/static/img/icons/standard_shipping.svg',
  SHIPPING_PROFILE: '/static/img/icons/shipping_profile.svg',
  SUPPORT: 'https://faq.golocad.com/support/home',
  BOOKSHIPMENT_TnC: '/static/img/group.svg',
  ALL_SYSTEMS_GO: '/static/img/icons/all-systems-go.png',
  DEFAULT_CARRIER_ITEM_LOGO: '/static/img/icons/default-carrier-item-icon.png',
  PACK_HEIRARCHY_UNIT: '/static/img/icons/pack_heirarchy_unit.svg',
  PACK_HEIRARCHY_PRIMARY: '/static/img/icons/hierarchical-primary.svg',
  PACK_HEIRARCHY_SECONDARY: '/static/img/icons/hierarchical-secondary.svg',
  PACK_HEIRARCHY_TERTIARY: '/static/img/icons/hierarchical-tertiary.svg',
};
