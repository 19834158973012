const initialState = {
  visible: true,
};

export default function frontDeskWidget(state = initialState, action) {
  switch (action.type) {
    case "SET_FRONT_DESK_WIDGET_VISIBILITY":
      return { ...state, visible: action.payload };

    default:
      return state;
  }
}
