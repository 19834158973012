export const getOnlyCurrencySymbol = (countryCurrencyCode, local = 'en-US') => {
  const parts = new Intl.NumberFormat(local, { style: 'currency', currency: countryCurrencyCode }).formatToParts(100);
  const { value } = parts.find((t) => t.type === 'currency');
  return value;
};
export const getUserBowserLocal = () =>
  navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

export const formatNumber = (number, local = 'en-US') => {
  // const formatter = new Intl.NumberFormat(local);
  const formatter = new Intl.NumberFormat(local, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return formatter.format(number);
};

export const getFormattedCurrency = (number, countryCurrencyCode, local = 'en-US') => {
  if (!number && typeof number !== 'number') {
    return '';
  }
  return new Intl.NumberFormat(local, { style: 'currency', currency: countryCurrencyCode }).format(number);
};
