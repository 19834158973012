import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAuthLayoutRoutes, getDashboardLayoutRoutes, getIndexRoute } from './index';

import DashboardLayout from '../layouts/Dashboard';
import AuthLayout from '../layouts/Auth';
import Page404 from '../Auth/Views/Page404';
import { LoginUserContext } from '../providers/Auth/AuthProvider';
import { loadUser } from '../redux/auth/actions';
import { paths } from './constants';
import ReactGA from 'react-ga4';
import { getSearchQueryFromLocation } from '../helpers/Order/helpers';
import { isMigrationEnabledForDev, matchNextParameter } from '../helpers/Auth/helpers';
import NoBrandUser from '../Auth/Views/NoBrandUser';
import BrandCreation from '../Auth/Views/Onboarding/BrandCreation';
import LocationMapping from '../Auth/Views/Onboarding/LocationMapping';
import Skeleton from '../layouts/Skeleton';
//import MigrationConfrimationScreen from '../Auth/Views/MigrationConfirmation';

const loginContainerWidth = '100%';

class Routes extends React.Component {
  componentDidMount() {
    this.props.loadUser();
  }

  componentDidUpdate() {
    if (!this.props.auth.userLoaded) {
      this.props.loadUser();
    }
  }

  setGoogleAnalyticsUserData = () => {
    if (!this.props.auth.user) {
      return;
    }
    const { username, is_staff, dashboard_version, fulfilment_enabled, shipping_enabled, current_brand } =
      this.props.auth.user;

    ReactGA.gtag('set', 'user_properties', {
      username,
      is_staff,
      dashboard_version,
      brand_name: current_brand?.name,
      is_etaily: current_brand?.is_etaily,
      fulfilment_enabled: Boolean(fulfilment_enabled),
      shipping_enabled: Boolean(shipping_enabled),
    });
  };

  checkIfAvailableBrands() {
    return (
      !this.props.auth.user.available_brands?.length &&
      !matchNextParameter(getSearchQueryFromLocation(window.location)?.get('next'), paths.AUTH_NO_AVIALBLE_BRAND, {
        pathParams: true,
      })
    );
  }

  checkIfUserisMigrated() {
    if (isMigrationEnabledForDev()) {
      return (
        Boolean(this.props.auth.user.available_brands?.length) &&
        !(this.props.auth.user.cognito_merged || this.props.auth.user.cognito_migrated)
      );
    }
    return false;
  }

  privateChildRoutes = (Layout, routes) => {
    return routes.map(({ children, path, component: Component, skeletonLayout = false }, index) =>
      children ? (
        this.privateChildRoutes(Layout, children)
      ) : !this.props.auth.user ? (
        <Route
          key={index}
          path={path}
          exact
          render={() => (
            <Redirect
              to={
                window.location.pathname ===
                getIndexRoute(this.props.auth.user?.dashboard_version || this.props.auth.default_dashboard_version)
                  ? paths.AUTH_LOGIN
                  : paths.AUTH_LOGIN + '?next=' + window.location.href.replace(window.location.origin, '')
              }
            />
          )}
        />
      ) : this.checkIfAvailableBrands() ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => {
            return <Redirect to={paths.AUTH_SIGNUP_CREATE_BRAND} />;
          }}
        />
      ) : (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => {
            if (skeletonLayout) {
              return (
                <Skeleton>
                  <Component {...props} />{' '}
                </Skeleton>
              );
            } else {
              return (
                <Layout>
                  <Component {...props} />
                </Layout>
              );
            }
          }}
        />
      )
    );
  };

  childRoutes = (Layout, routes, width = null) =>
    routes.map(({ children, path, component: Component }, index) =>
      children ? (
        this.childRoutes(Layout, children, width)
      ) : (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout width={width}>
              <Component {...props} />
            </Layout>
          )}
        />
      )
    );

  render() {
    if (!this.props.auth.userLoaded) {
      return <div></div>;
    } else {
      this.setGoogleAnalyticsUserData();
      return (
        <LoginUserContext.Provider value={this.props.auth}>
          <Switch>
            {this.privateChildRoutes(
              DashboardLayout,
              getDashboardLayoutRoutes(
                this.props.auth.user?.dashboard_version || this.props.auth.default_dashboard_version,
                this.props.auth.user
              )
            )}
            {this.childRoutes(
              AuthLayout,
              getAuthLayoutRoutes(this.props.auth.user?.dashboard_version || this.props.auth.default_dashboard_version),
              loginContainerWidth
            )}
            <Route
              exact
              path={paths.INDEX}
              render={() => (
                <Redirect
                  to={getIndexRoute(
                    this.props.auth.user?.dashboard_version || this.props.auth.default_dashboard_version
                  )}
                />
              )}
            />
            <Route path={paths.PULLOUTS_UPLOAD} render={() => <Redirect to={paths.CREATE_DISTRIBUTION_ORDER} />} />
            {this.props.auth.user ? (
              <Route exact path={paths.AUTH_SIGNUP_CREATE_BRAND} render={(props) => <BrandCreation {...props} />} />
            ) : null}
            {this.props.auth.user ? (
              <Route exact path={paths.AUTH_SIGNUP_BRAND_MAPPING} render={(props) => <LocationMapping {...props} />} />
            ) : null}
            <Route exact path={paths.AUTH_NO_AVIALBLE_BRAND} render={() => <NoBrandUser />} />
            <Route
              render={() => (
                <AuthLayout>
                  <Page404 />
                </AuthLayout>
              )}
            />
          </Switch>
        </LoginUserContext.Provider>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUser: () => dispatch(loadUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
