const actionTypes = {
  LOAD_USER_SUCCESS: 'LOAD_USER_SUCCESS',
  LOAD_USER_FAIL: 'LOAD_USER_FAIL',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAIL: 'USER_LOGIN_FAIL',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAIL: 'USER_UPDATE_FAIL',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_MIGRATION: 'USER_MIGRATION',
  SET_GLOBAL_DATE_FILTER: 'SET_GLOBAL_DATE_FILTER',
  UPDATE_BRAND_DETAILS_SUCCESS: 'UPDATE_BRAND_DETAILS_SUCCESS',
  UPDATE_BRAND_DETAILS_FAIL: 'UPDATE_BRAND_DETAILS_FAIL',
};

export default actionTypes;
