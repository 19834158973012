export const OTHERS = {
  BADGE: {
    BETA: "Beta",
    NEW: "New",
  },
  COMING: {
    title: "[COMING]",
  },
  PAYMENT: {
    title: "Payment",
  },
  PAYMENT_CALLBACK: {
    title: "PaymentCallback",
  },
  PRIVACY: {
    title: "Privacy",
    secTitle: "[WIP] Privacy",
  },
  HELP_CENTER: {
    title: "Help Center",
    secTitle: "[WIP] Help Center",
  },
  TERMS_OF_SERVICE: {
    title: "Terms Of Service",
  },
};
