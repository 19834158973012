import { stopProductTourPrompt } from '../../../../redux/joyride/StartProductTourPrompt/actions';
import { stop, terminate } from '../../../../redux/joyride/SelfServeOnboarding/actions';
export function productTourPromptCallBack({ data, dispatch }) {
  const { action } = data;
  if (action === 'next') {
    dispatch(stopProductTourPrompt({ parentRoute: '/shipments' }));
    dispatch(stop());
    dispatch(terminate());
  }
}
