export const CARRIER = {
  title: "Carrier Integrations",
  DASHBOARD: {
    title: "Carrier Integrations",
  },
  DETAIL: {
    title: "Carrier Details",
  },
  ASCENDIA: {
    title: "Asendia Integration",
  },
  AUSPOST: {
    title: "AusPost Integration",
  },
  DHL: {
    title: "DHL Integration",
  },
  ENTREGO: {
    title: "Entrego Integration",
  },
  JNTPH: {
    title: "JNT PH Integration",
  },
  JNTSG: {
    title: "JNTSG Integration",
  },
  KERRY: {
    title: "Kerry Integration",
  },
  LALAMOVE: {
    title: "Lalamove Integration",
  },
  LBC: {
    title: "LBC Integration",
  },
  NINJAVAN: {
    title: "Ninjavan Integration",
  },
  PICKUPP: {
    title: "Pickupp Integration",
  },
  SENDLE: {
    title: "Sendle Integration",
  },
  SINGPOST: {
    title: "Singpost Integration",
  },
  TELEPORT: {
    title: "Teleport Integration",
  },
  XDE: {
    title: "XDE Integration",
  },
};
