import { paths } from './constants';
import async from '../Components/Async';
import {
  BILLING,
  CARRIER,
  INBOUND,
  LOCAD_STORAGE_AND_FULFILLMENT,
  PRODUCTS,
  PULLOUT_AND_B2B,
  RETURNS,
  SETTINGS,
  SHIPMENTS,
  WALLET,
} from '../constants';

/*
  use_shipping_profile: Based on this flag we show/hide carrier management
*/
export const getUserDashboardFlags = (user) => {
  return {
    fulfilment_enabled: user?.fulfilment_enabled,
    shipping_enabled: user?.shipping_enabled,
    wallet_enabled: user?.current_brand?.module_settings?.wallet_profile?.wallet_details?.active || false,
    top_up_enabled: user?.current_brand?.module_settings?.wallet_profile?.wallet_details?.top_up_enabled || false,
    use_shipping_profiles: user?.current_brand?.module_settings?.carrier_profile?.use_shipping_profile || false,
    returnkey_id: user?.current_brand?.returnkey_brand_identifier,
    isStaff: user?.is_staff,
    isBillingModuleVisible: user?.isBillingModuleVisible,
  };
};
export const applyDashboardFlags = (sidebarRoutes, flags) => {
  const routes = [...sidebarRoutes];
  const routeIndex = (routeName) => routes.findIndex((route) => route.id === routeName);
  // const isStaff = flags.isStaff === true;
  const walletEnabled = flags.wallet_enabled;
  //Fulfilment only
  const fulfillmentOnlyEnabled = flags.fulfilment_enabled && !flags.shipping_enabled;
  //Shipping
  const shippingEnabled = flags.shipping_enabled && !flags.fulfilment_enabled;
  const returnkeyEnabled = flags.returnkey_id != null;
  //Fulfilment + Shipping
  const fulfillmentAndShippingEnabled = flags.fulfilment_enabled && flags.shipping_enabled;
  const carrierManagementEnabled = flags.use_shipping_profiles;

  if (fulfillmentOnlyEnabled) {
    routes[routeIndex(SHIPMENTS.title)].children = routes[routeIndex(SHIPMENTS.title)].children.filter(
      (route) => route.name === SHIPMENTS.SHIPMENT_D.title || route.name === SHIPMENTS.TRACK_SHIPMENT.title
    );
    routes.splice(routeIndex(SHIPMENTS.PICKUP_L.title), 1);
    routes[routeIndex(SETTINGS.title)].children = routes[routeIndex(SETTINGS.title)].children.filter(
      (route) => route.name !== CARRIER.title
    );
    routes.splice(
      routes.findIndex((route) => route.name === LOCAD_STORAGE_AND_FULFILLMENT.title),
      1
    );
  }
  if (!returnkeyEnabled) {
    routes.splice(
      routes.findIndex((route) => route.name === RETURNS.title),
      1
    );
  }
  if (!flags.isBillingModuleVisible) {
    if (routeIndex(BILLING.title) > -1) routes.splice(routeIndex(BILLING.title), 1);
  }
  if (shippingEnabled) {
    routes[routeIndex(PRODUCTS.title)] = {
      id: PRODUCTS.CATALOGUE_PRODUCTS.title,
      component: async(() => import('../Inventory/Views/CatalogueProducts')),
      icon: routes[routeIndex(PRODUCTS.title)].icon,
      name: PRODUCTS.CATALOGUE_PRODUCTS.title,
      path: paths.INVENTORY_CATALOGUE_LISTING,
    };
    if (routeIndex(INBOUND.title) > -1) routes.splice(routeIndex(INBOUND.title), 1);
    if (routeIndex(PULLOUT_AND_B2B.title) > -1) routes.splice(routeIndex(PULLOUT_AND_B2B.title), 1);
    routes[routeIndex(SETTINGS.title)].children = routes[routeIndex(SETTINGS.title)].children.filter(
      (route) =>
        route.name === SETTINGS.ACCOUNT_SETTINGS.title ||
        route.name === SETTINGS.MARKETPLACE_INTEGRATIONS.title ||
        route.name === SETTINGS.CARRIER_MANAGEMENT.title
    );
  }
  if (!walletEnabled) {
    if (routeIndex(WALLET.title) > -1) routes.splice(routeIndex(WALLET.title), 1);
  }
  if (fulfillmentAndShippingEnabled) {
    if (routeIndex(SETTINGS.title) > -1) {
      routes[routeIndex(SETTINGS.title)].children = routes[routeIndex(SETTINGS.title)].children.filter(
        (route) => route.name !== CARRIER.title
      );
    }
    if (routeIndex(LOCAD_STORAGE_AND_FULFILLMENT.title) > -1) {
      routes.splice(
        routes.findIndex((route) => route.name === LOCAD_STORAGE_AND_FULFILLMENT.title),
        1
      );
    }
  }
  // if (!isStaff) {
  //   if (routeIndex(PULLOUT_AND_B2B.title) > -1) {
  //     const children = routes[routeIndex(PULLOUT_AND_B2B.title)].children;
  //     const childIndex = children.findIndex((r) => r.id === PULLOUT_AND_B2B.PULLOUT_REQUESTS.title);
  //     if (childIndex >= 0) {
  //       children.splice(childIndex, 1); // removing routes for non-staff user
  //     }
  //   }
  // }
  if (!carrierManagementEnabled) {
    if (Array.isArray(routes[routeIndex(SETTINGS.title)]?.children))
      routes[routeIndex(SETTINGS.title)].children = routes[routeIndex(SETTINGS.title)].children.filter(
        (route) => route.name !== SETTINGS.CARRIER_MANAGEMENT.title
      );
  }

  return routes;
};
