import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";

const CustomTypography = styled(Typography)`
  margin-top: 1vh;
  margin-left: 0.5vw;
`;
const CustomInfoIcon = styled(InfoIcon)`
  margin-left: 0.5vw;
`;

const DomainMovedMessage = ({ msg }) => {
  return (
    <>
      <CustomInfoIcon />
      <CustomTypography mt={2} variant="subtitle2" gutterBottom>
        {msg}
      </CustomTypography>
    </>
  );
};

export default DomainMovedMessage;
