import React from 'react';
import { connect } from 'react-redux';
import { ListItemText } from '@mui/material'; //Grid, Box, ListItem,
//import { makeStyles } from '@mui/styles';
//import { EmojiObjects } from '@mui/icons-material';

import { updateRouteIndex, start } from '../../../../redux/joyride/SelfServeOnboarding/actions';
import { terminatePrompt } from '../../../../redux/joyride/StartProductTourPrompt/actions';
import withStyles from '@mui/styles/withStyles';
import { getRouteIndex, getSubstringId } from './helpers';
import { onboardingStepDefinition } from './constants';

/* const useStyles = makeStyles((theme) => {
  return {
    imgBox: {
      marginLeft: '-0.7rem',
      width: '2.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.success.dark,
    },
    category: {
      margin: 0,
      [`& span`]: {
        color: theme.palette.success.dark,
        fontSize: `${theme.typography.body1.fontSize}px`,
        fontWeight: theme.sidebar.category.fontWeight,
      },
    },
    linkContainer: {
      background: '#060A06',
      cursor: 'pointer',
      margin: '0 0 4.4rem 0 !important',
    },
  };
}); */
function ProductTourLinkButton({ routes, start, productTourPrompt, terminatePrompt, updateRouteIndex }) {
  //const { imgBox, category, linkContainer } = useStyles();
  return (
    <ListItemText
      onClick={() => {
        terminatePrompt();
        setTimeout(() => {
          updateRouteIndex({
            openRouteIndex: getRouteIndex(routes, getSubstringId(onboardingStepDefinition?.[0]?.target)),
            loading: true,
            run: false,
            continuous: false,
          });
        }, 200);
        setTimeout(() => {
          start({ loading: false, run: true, continuous: true });
        }, 300);
      }}
    >
      {'Product Tour'}
    </ListItemText>
  );
}

const mapStateToProps = (state) => ({
  productTourPrompt: state.startProductTourPrompt,
});

const mapDispatchToProps = (dispatch) => ({
  terminatePrompt: () => dispatch(terminatePrompt()),
  updateRouteIndex: (data) => dispatch(updateRouteIndex(data)),
  start: (data) => dispatch(start(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles()(ProductTourLinkButton));
