import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ErrorBoundary from '../Components/ErrorBoundary';

import { CssBaseline, Paper } from '@mui/material';

import AppSnackbar from '../Components/AppSnackbar';
import AppConfirmationDialog from '../Components/AppConfirmationDialog';
import FrontDeskWidgetAction from '../Components/FrontDeskWidgetAction';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Skeleton = ({ children, header = false }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Root>
      <FrontDeskWidgetAction type="show" />
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        {header && <Header onDrawerToggle={handleDrawerToggle} />}
        <MainContent
          sx={{
            padding: {
              lg: 2,
              xs: 1,
            },
          }}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </MainContent>
        <Footer />
      </AppContent>
      <AppSnackbar />
      <AppConfirmationDialog />
    </Root>
  );
};

export default Skeleton;
