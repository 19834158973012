export const ORDER_FORECAST_BULK_TEMPLATE = '/static/Outbound_Forecast_Template-v2.xlsx';

export const HELPER_TEXT_UPLOAD_FILE = 'Upload details of Order Forecast';

export const OnHoldStatuses = ['on_hold', 'preorder', 'scheduled'];

export const FORECAST_STATUS = {
  UNDER_ESTIMATED: 'UNDER_ESTIMATED',
  OVER_ESTIMATED: 'OVER_ESTIMATED',
  CORRECTLY_ESTIMATED: 'CORRECTLY_ESTIMATED',
  NOT_ESTIMATED: 'NOT_ESTIMATED',
  ESTIMATED: 'ESTIMATED',
};

export const LEGEND_DESCRIPTION = {
  UNDER_ESTIMATED: 'Actual order volume is below 20% variance of forecast',
  OVER_ESTIMATED: 'Actual order volume is above 20% variance of forecast',
  CORRECTLY_ESTIMATED: 'Actual order volume is within 20% variance of forecast',
  NOT_ESTIMATED: 'Forecast is not updated yet',
  ESTIMATED: 'Forecast is updated',
};

export const LEGEND_COLORCODE = {
  UNDER_ESTIMATED: 'var(--color-bright-orange)',
  OVER_ESTIMATED: 'var(--color-bright-red)',
  CORRECTLY_ESTIMATED: '#50d860',
  NOT_ESTIMATED: '#A4AFAF',
  ESTIMATED: '#847BFF',
};

export const BULK_UPLOAD_STATUS = {
  QUEUED: 'QUEUED',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
};

export const Releasable_Statuses = ['ON_HOLD', 'PREORDER', 'SCHEDULED'];

export const FORECAST_NOTE =
  'If forecast is not provided or is inaccurate (> 20% variance), Locad will not be liable for any breaches in outbound SLA. Please consistently provide updated forecasts especially for planned campaigns or expected surges in volume.';
