import React from 'react';
import './onboarding.css';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash.get';
import { IconButton, Tooltip } from '@mui/material';
import { logout } from '../../../redux/auth/actions';
import { PowerSettingsNew } from '@mui/icons-material';

function OnboardingHeader({ step, subheader, totalStep = 2 }) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  return (
    <div className="onboarding-container">
      <div className="header-container">
        <span className="onboarding-header-text font-32">Welcome to </span>
        <img src="/static/img/logos/locad-logo-white.svg" className="image-modifier" alt="locad logo" />
      </div>
      <span className="onboarding-header-text font-20">
        {get(user, 'email', '')}{' '}
        <Tooltip arrow placement="right" title="Logout">
          <IconButton id="logout" onClick={() => dispatch(logout())} style={{ marginLeft: '8px' }} size="small">
            <PowerSettingsNew style={{ fontSize: '20px', color: 'red' }} />
          </IconButton>
        </Tooltip>
      </span>
      <span className="onboarding-header-text font-20">{subheader}</span>
      <span className="onboarding-header-text font-20">{`Step ${step}/${totalStep}`}</span>
    </div>
  );
}

export default OnboardingHeader;
