import { TooltipComponent } from './TooltipComponent';

const styles = {
  options: {
    arrowColor: '#fff',
  },
  buttonNext: {
    margin: 0,
    backgroundColor: 'var(--colors-secondary)',
    border: 'none',
    color: 'black',
    '&:hover': {
      color: 'white',
      backgroundColor: 'black',
    },
  },
};

const options = {
  disableOverlayClose: true,
  floaterProps: {
    disableAnimation: true,
  },
};

const component = {
  tooltipComponent: TooltipComponent,
};

export function stylesOptionsAndComponents() {
  return {
    ...{
      styles: styles,
      ...options,
      ...component,
    },
  };
}
