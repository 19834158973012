import * as actionTypes from './actionTypes';

const initialInventoryState = {
  product: null,
  isLoading: false,
  isUpdating: false,
  isCreating: false,
  error: null,
  updatingError: null,
  creatingError: null,
};

const initialInventoryListingState = {
  listing: {
    data: null,
    isLoading: false,
    error: null,
    loaded: false,
  },
  filters: {
    data: null,
    isLoading: false,
    error: null,
    loaded: false,
  },
  activeFilters: {
    status: 'Active',
    stock_status: null,
    inventory_movement_status: null,
  },
  tableState: {
    page: 0,
    rowsPerPage: 20,
    searchText: '',
    sortOrder: {
      // name: 'items_sold_lookback',
      // direction: 'desc'
    },
  },
};

export function inventory(state = initialInventoryState, action) {
  switch (action.type) {
    case actionTypes.GET_INVENTORY_PRODUCT:
      return { ...state, isLoading: true };
    case actionTypes.GET_INVENTORY_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        product: action.response,
        error: null,
      };
    case actionTypes.GET_INVENTORY_PRODUCT_FAIL:
      return { ...state, isLoading: false, product: null, error: action.error };
    case actionTypes.CLEAR_INVENTORY_PRODUCT:
      return { ...state, product: null };

    case actionTypes.UPDATE_INVENTORY_PRODUCT:
      return { ...state, isUpdating: true };
    case actionTypes.UPDATE_INVENTORY_PRODUCT_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updatingError: null,
        product: { ...state.product, ...action.response },
      };
    case actionTypes.UPDATE_INVENTORY_PRODUCT_FAIL:
      return { ...state, isUpdating: false, updatingError: action.error };

    case actionTypes.CREATE_INVENTORY_PRODUCT:
      return { ...state, isCreating: true };
    case actionTypes.CREATE_INVENTORY_PRODUCT_SUCCESS:
      return { ...state, isCreating: false, creatingError: null };
    case actionTypes.CREATE_INVENTORY_PRODUCT_FAIL:
      return { ...state, isCreating: false, creatingError: action.error.data };

    case actionTypes.SET_INVENTORY_UPDATING_ERROR:
      return { ...state, updatingError: action.payload };
    case actionTypes.SET_INVENTORY_CREATING_ERROR:
      const { field, error } = action.payload;
      if (state.creatingError) {
        return {
          ...state,
          creatingError: { ...state.creatingError, [field]: error },
        };
      } else {
        return state;
      }

    case actionTypes.RESET_INVENTORY_CREATING_ERRORS:
      return { ...state, creatingError: null };

    case actionTypes.RESTORE_INVENTORY_REDUCERS:
      return initialInventoryState;

    default:
      return state;
  }
}

export function inventoryListing(state = initialInventoryListingState, action) {
  switch (action.type) {
    case actionTypes.GET_INVENTORY_LISTING_ITEMS:
      return updateListing(state, { isLoading: true });
    case actionTypes.GET_INVENTORY_LISTING_ITEMS_SUCCESS:
      return updateListing(state, {
        isLoading: false,
        loaded: true,
        data: action.response,
        error: null,
      });
    case actionTypes.GET_INVENTORY_LISTING_ITEMS_FAIL:
      return updateListing(state, {
        isLoading: false,
        error: action.error,
        loaded: true,
      });

    case actionTypes.GET_INVENTORY_LISTING_FILTERS:
      return updateFilters(state, { isLoading: true });
    case actionTypes.GET_INVENTORY_LISTING_FILTERS_SUCCESS:
      return updateFilters(state, {
        isLoading: false,
        loaded: true,
        data: action.response,
        error: null,
      });
    case actionTypes.GET_INVENTORY_LISTING_FILTERS_FAIL:
      return updateFilters(state, {
        isLoading: false,
        error: action.error,
        loaded: true,
      });

    case actionTypes.SET_INVENTORY_LISTING_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: { ...state.activeFilters, ...action.payload.filters },
        tableState: { ...state.tableState, page: 0 },
      };
    case actionTypes.UPDATE_INVENTORY_LISTING_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: { ...action.payload.filters },
        tableState: { ...state.tableState, page: 0 },
      };

    case actionTypes.SET_INVENTORY_LISTING_TABLE_STATE:
      return {
        ...state,
        tableState: { ...state.tableState, ...action.payload.tableState },
      };

    case actionTypes.UPDATE_INVENTORY_PRODUCT_SUCCESS:
      return updateListing(state, {
        data: {
          ...state.data,
          results: state.listing.data.results.map((item) => {
            if (item.id === action.response.id) {
              return {
                ...item,
                ...action.response,
              };
            } else {
              return item;
            }
          }),
        },
      });

    case actionTypes.RESTORE_INVENTORY_REDUCERS:
      return initialInventoryListingState;

    default:
      return state;
  }
}

const updateInventoryListingState = (key) => (state, newState) => ({
  ...state,
  [key]: { ...state[key], ...newState },
});

const updateListing = updateInventoryListingState('listing');

const updateFilters = updateInventoryListingState('filters');
