export const GET_CATALOGUE_LISTING_ITEMS = "GET_CATALOGUE_LISTING_ITEMS";
export const GET_CATALOGUE_LISTING_ITEMS_SUCCESS =
  "GET_CATALOGUE_LISTING_ITEMS_SUCCESS";
export const GET_CATALOGUE_LISTING_ITEMS_FAIL =
  "GET_CATALOGUE_LISTING_ITEMS_FAIL";

export const GET_CATALOGUE_LISTING_DETAIL = "GET_CATALOGUE_LISTING_DETAIL";
export const GET_CATALOGUE_LISTING_DETAIL_SUCCESS =
  "GET_CATALOGUE_LISTING_DETAIL_SUCCESS";
export const GET_CATALOGUE_LISTING_DETAIL_FAIL =
  "GET_CATALOGUE_LISTING_DETAIL_FAIL";

export const GET_CATALOGUE_LISTING_FILTERS = "GET_CATALOGUE_LISTING_FILTERS";
export const GET_CATALOGUE_LISTING_FILTERS_SUCCESS =
  "GET_CATALOGUE_LISTING_FILTERS_SUCCESS";
export const GET_CATALOGUE_LISTING_FILTERS_FAIL =
  "GET_CATALOGUE_LISTING_FILTERS_FAIL";

export const UPDATE_CATALOGUE_PRODUCT_MAPPING =
  "UPDATE_CATALOGUE_PRODUCT_MAPPING";
export const UPDATE_CATALOGUE_PRODUCT_MAPPING_SUCCESS =
  "UPDATE_CATALOGUE_PRODUCT_MAPPING_SUCCESS";
export const UPDATE_CATALOGUE_PRODUCT_MAPPING_FAIL =
  "UPDATE_CATALOGUE_PRODUCT_MAPPING_FAIL";

export const SET_CATALOGUE_LISTING_ACTIVE_FILTERS =
  "SET_CATALOGUE_LISTING_ACTIVE_FILTERS";
export const SET_CATALOGUE_LISTING_TABLE_STATE =
  "SET_CATALOGUE_LISTING_TABLE_STATE";
export const RESTORE_CATALOGUE_LISTING_REDUCER =
  "RESTORE_CATALOGUE_LISTING_REDUCER";
export const CLEAR_INVENTORY_MAPPING_ERRORS = "CLEAR_INVENTORY_MAPPING_ERRORS";
