export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

const initState = {
  activeTab: localStorage.getItem("partnerhub-active-tab")
    ? localStorage.getItem("partnerhub-active-tab")
    : "Warehouse",
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    default:
      return state;
  }
}
