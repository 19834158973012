import {
  GET_MARKETPLACES,
  GET_MARKETPLACES_SUCCESS,
  GET_MARKETPLACES_FAIL,
} from "./actionTypes";

const initialState = {
  marketplaces: [],
  brand: null,
  carrierConnections: [],
  isLoading: false,
  error: null,
};

export default function marketplaces(state = initialState, action) {
  switch (action.type) {
    case GET_MARKETPLACES: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case GET_MARKETPLACES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        marketplaces: action.response.marketplaces,
        carrierConnections: action.response.available_carrier_connections,
        brand: action.response.brand,
      };
    }
    case GET_MARKETPLACES_FAIL: {
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
