import {
  CLEAR_PREV_NOTIFICATION,
  HIDE_NOTIFICATION,
  OPEN_NOTIFICATION,
  SHOW_NOTIFICATION,
} from "./actionTypes";

const initialState = {
  message: null,
  open: false,
  type: null,
  verticalAnchor: "top",
  horizontalAnchor: "center",
  key: null,
  previousNotification: null,
};

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return {
        message: action.payload.message,
        type: action.payload.type,
        verticalAnchor: action.payload.verticalAnchor,
        horizontalAnchor: action.payload.horizontalAnchor,
        open: true,
        key: Date.now(),
        previousNotification: state.open ? state : null,
      };

    case OPEN_NOTIFICATION:
      return {
        ...state,
        open: true,
      };

    case HIDE_NOTIFICATION:
      return {
        ...state,
        open: false,
        key: null,
      };

    case CLEAR_PREV_NOTIFICATION:
      return {
        ...state,
        previousNotification: null,
      };

    default:
      return state;
  }
}
