import styled, { createGlobalStyle } from 'styled-components';
import { rgba } from 'polished';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { spacing } from '@mui/system';
import {
  ListItem,
  Paper as MuiPaper,
  List as MuiList,
  IconButton as MuiIconButton,
  Chip,
  ListItemText,
  Typography,
  Collapse,
} from '@mui/material';

export const drawerWidth = 260;
export const closeDrawerWidth = 4;
export const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  } 
  body {
    background: ${(props) => props.theme.body.background};
  }
  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

export const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;
export const ClosedDrawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${closeDrawerWidth}rem;
    flex-shrink: 0;
  }
  display: flex;
  flex-direction: column;
  background: var(--colors-primary);
  position: fixed;
  z-index: 12000;
  height: 100vh;
  top: 0;
  background-color: ${(props) => props.theme.sidebar.background} !important;
`;

export const SimpleLink = styled(ListItem)`
  padding: 0;
`;
export const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const SidebarFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.2rem;
  width: ${closeDrawerWidth}rem;
  bottom: 0;
  background-color: ${(props) => props.theme.sidebar.footer.background} !important;
`;
export const IconButton = styled(MuiIconButton)`
  color: ${(props) => props.theme.sidebar.color};
  :hover {
    background-color: var(--color-hover-charcol) !important;
    color: var(--colors-secondary-hover);
  }
  svg {
    width: 22px;
    height: 22px;
  }
`;
export const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;
export const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;
export const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;
export const arrowIconRight = {
  display: 'flex',
  position: 'absolute',
  marginLeft: '3.2rem',
  marginTop: '-0.7rem',
  borderRadius: '50%',
  boxShadow: '0px 2px 2px 0px rgba(0,0,0,.122),0px 1px 10px 0px rgba(0,0,0,.078)',
  backgroundColor: 'white',
  cursor: 'pointer',
  zIndex: '1',
};
export const childrenList = {
  position: 'absolute',
  background: 'var(--colors-primary)',
  marginLeft: `${closeDrawerWidth}rem`,
  marginTop: '-2rem',
  borderRadius: '4px',
  padding: '0.5rem',
  cursor: 'pointer',
};

export const Paper = styled(MuiPaper)(spacing);

export const MainContent = styled(Paper)`
  padding-bottom: 12px;
  flex: 1;
  background: ${(props) => props.theme.body.background};
  width: ${(props) => (props.isSideNavOpen ? props.innerWidth - 290 : props.innerWidth - 84)}px;

  @media (max-width: 960px) {
    width: ${(props) => props.innerWidth - 20}px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

export const Category = styled(ListItem)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  opacity: 0.8;
  transition: border-radius 0.1s ease;
  svg {
    color: ${(props) => props.theme.sidebar.color};
  }
  &:hover {
    opacity: 1;
    background: rgba(61, 61, 61, 1);
  }
  &.${(props) => props.activeclassname} {
    opacity: 1;
    svg {
      color: ${(props) => props.theme.palette.secondary.contrastText};
    }
    background-color: ${(props) => props.theme.palette.secondary.dark};
  }
`;

export const BulletStyle = {
  width: '.7rem',
  marginRight: '.4rem',
  color: 'var(--colors-contrast-text-primary)',
  opacity: 0.8,
};

export const Link = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(2)};
  padding-bottom: ${(props) => props.theme.spacing(2)};
  width: 85%;
  margin: auto;
  border-radius: 1.3rem;
  white-space: nowrap;
  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }
  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }
  &.${(props) => props.activeclassname} {
    > * {
      color: var(--colors-primary) !important;
    }
    background-color: ${(props) => props.theme.palette.secondary.dark};
    span {
      color: ${(props) => props.theme.palette.secondary.contrastText};
    }
  }
`;

export const CollapsableChildLink = styled(ListItem)`
  border-radius: 1.3rem;
  white-space: nowrap;
  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }
  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 1)};
  }
  &.${(props) => props.activeclassname} {
    > * {
      color: ${(props) => props.theme.palette.secondary.contrastText} !important;
    }
    background-color: ${(props) => props.theme.palette.secondary.dark};
    span {
      color: ${(props) => props.theme.palette.secondary.contrastText};
    }
  }
`;
export const ChildLinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
`;

export const LinkBadge = styled(Chip)`
  font-size: 10px;
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  height: 20px;
  position: absolute;
  right: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;
export const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

export const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;
export const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)} ${(props) => props.theme.spacing(6)}px
    ${(props) => props.theme.spacing(1)};
  opacity: 0.9;
  display: block;
  margin: auto;
`;
export const CollapsableChildren = styled(Collapse)`
  position: absolute;
  background: ${(props) => props.theme.palette.primary.main};
  border-radius: 0 0.8rem 0.8rem 0;
  margin-left: -1.9rem;
  margin-top: -4.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  min-width: 15rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`;

export const BoxHead = styled(Typography)`
  margin-left: 1.1rem;
  font-size: 1rem;
  padding: 0.3rem 0;
`;
export const IconsWrapper = styled.div`
  overflow: auto;
  height: 100%;
`;
