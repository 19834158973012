import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Grid,
  IconButton,
  // Link,
  Typography,
} from '@mui/material';
import { spacing } from '@mui/system';
import LoadingButton from './LoadingButton';
import { ChevronRight, ChevronLeft, Close } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';

const Dialog = styled(MuiDialog)(spacing);
const DialogTitle = styled(MuiDialogTitle)(spacing);
const DialogActions = styled(MuiDialogActions)(spacing);
const DialogContent = styled(MuiDialogContent)(spacing);
const DialogContentWrapper = styled.div`
  margin: 10px 0;
`;
const CancelButton = styled(Button)`
  // margin-left: 0.5vw;
`;
const DialogSubTitle = styled(Typography)`
  margin-left: 1.7rem;
`;
const CustomSubTitle = styled(Box)`
  margin-left: 1.7rem;
`;
const styles = {
  dialogPaper: {
    height: '80vh',
  },
};

function SimpleDialog({
  title,
  disclaimerFooter,
  open,
  children,
  onSave,
  onCancel,
  onClose,
  actionsDisabled,
  actionsSaveDisabled = false,
  isSaving,
  saveText = 'Save',
  saveButtonColor = 'secondary',
  cancelText = 'Cancel',
  cancelButtonVariant = 'contained',
  cancelButtonColor = 'primary',
  subtitle,
  customSubtitle,
  maxWidth = 'xs',
  footerButtonRight = false,
  linkHref = '',
  linkText = '',
  closeIcon = false,
  showCancelButton = true,
  showSaveButton = true,
  nextButton = false,
  backButton = false,
  handleNextClick,
  handleBackClick,
  nextButtonDisabled,
  setHeight,
  classes,
  fullWidth = true,
  customDialogActions,
  ...rest
}) {
  const resolveConfirmButton = () => {
    return (
      <Box style={{ marginBottom: '0.8rem' }}>
        {nextButton && (
          <Button
            color="primary"
            variant="contained"
            endIcon={<ChevronRight />}
            size="small"
            onClick={handleNextClick}
            style={{
              marginRight: '1rem',
            }}
            disabled={nextButtonDisabled}
          >
            Next
          </Button>
        )}
        {backButton && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<ChevronLeft />}
            size="small"
            onClick={handleBackClick}
            style={{
              marginRight: '1rem',
            }}
          >
            Back
          </Button>
        )}

        {showCancelButton ? (
          onClose || onCancel ? (
            <CancelButton
              variant={cancelButtonVariant}
              onClick={onClose || onCancel}
              color={cancelButtonColor}
              size="small"
              disabled={actionsDisabled}
              data-testid="Cancel-Btn"
            >
              {cancelText}
            </CancelButton>
          ) : null
        ) : null}
        {onSave && showSaveButton
          ? saveText && (
              <LoadingButton
                isLoading={isSaving}
                variant="contained"
                type="submit"
                color={saveButtonColor}
                size="small"
                onClick={onSave}
                disabled={actionsSaveDisabled || actionsDisabled}
                style={{
                  // marginRight: "auto",
                  marginLeft: '1rem',
                }}
                data-testid="Submit-Btn"
              >
                {saveText}
              </LoadingButton>
            )
          : null}
      </Box>
    );
  };

  const renderDisclaimerFooter = () => {
    return (
      <>
        {disclaimerFooter ? (
          <Box component="span" style={{ fontSize: '12px', marginTop: '1rem', marginLeft: '0' }}>
            <span style={{ color: 'red' }}>*</span>
            {disclaimerFooter}
          </Box>
        ) : null}
      </>
    );
  };

  /*
  Commented render link method as per our discussion on 20th Dec, 2022. We will add it later, once we get the confirmation from Mikee
  */
  // const renderLink = () => {
  //   return (
  //     <>
  //       {linkText ? (
  //         <Link variant="body2" href={linkHref}>
  //           {linkText}
  //         </Link>
  //       ) : null}
  //     </>
  //   );
  // };

  return (
    (<Dialog
        mb={13}
        maxWidth={maxWidth}
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        classes={{ paper: setHeight && classes.dialogPaper }}
        PaperProps={{ style: { overflowY: 'hidden' } }}
        {...rest}
      >
      <Grid container justifyContent="space-between">
        <Grid item xs={10}>
          <DialogTitle px={7} pt={6} pb={2} id="form-dialog-title">
            {title}
          </DialogTitle>
          {subtitle && <DialogSubTitle variant="subtitle2">{subtitle}</DialogSubTitle>}
          {customSubtitle && <CustomSubTitle>{customSubtitle}</CustomSubTitle>}
        </Grid>
        {closeIcon && (onClose || onCancel) ? (
          <Grid item>
            <IconButton
              onClick={onClose || onCancel}
              style={{ margin: '5px 5px 0 0' }}
              size="large">
              <Close />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
      <DialogContent px={7}>
        <DialogContentWrapper>{children}</DialogContentWrapper>
      </DialogContent>
      <DialogActions
        pb={6}
        px={7}
        style={{
          // display: "flex",
          // flexDirection: "row",
          textAlign: 'left',
          left: '0',
          justifyContent: footerButtonRight && linkText ? 'space-between' : 'none',
          // marginRight: footerButtonRight ? 'none' : 'auto',
          marginLeft: footerButtonRight ? 'auto' : 'none',
        }}
      >
        {customDialogActions ? (
          customDialogActions
        ) : (
          <Fragment>
            {/* {renderLink()} */}
            {resolveConfirmButton()}
            {renderDisclaimerFooter()}
          </Fragment>
        )}
      </DialogActions>
    </Dialog>)
  );
}

export default withStyles(styles)(SimpleDialog);
