import React from 'react';
import { Box, Button as MuiButton, Alert, styled } from '@mui/material';
import { spacing } from '@mui/system';
import PageHeader from './PageHeader';
import { button } from '../constants/button';
import { notifications } from '../constants';

const Button = styled(MuiButton)(spacing);

export default class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error('Error caught in ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Error path
      return (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <PageHeader title={notifications.ERROR.title} />
          <Alert severity="error" data-testid="error-alert" sx={{ mb: 2 }}>
            {notifications.ERROR.MAIL_TEXT}
          </Alert>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => window.location.reload()}
              variant="contained"
              color="secondary"
              mt={6}
              data-testid="refresh-errorb-button"
            >
              {button.REFRESH_PAGE}
            </Button>
          </Box>
        </Box>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
