// import BTBrik from "../fonts/BTBrik-Regular.ttf";

// const BTBrikFont = {
//   fontFamily: "BTBrikFont",
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 400,
//   src: `local('BTBrik'), local('BTBrik-Regular'), url(${BTBrik}) format('ttf')`,
//   unicodeRange: `U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF`,
// };

const defaultFontFamily = [
  "-apple-system",
  "BTBrikFont",
  "Space Grotesk",
  // "-apple-system",
  // "BlinkMacSystemFont",
  // '"Segoe UI"',
  // "Roboto",
  // '"Helvetica Neue"',
  // "Arial",
  // "sans-serif",
  // '"Apple Color Emoji"',
  // '"Segoe UI Emoji"',
  // '"Segoe UI Symbol"',
];

const typography = {
  useNextVariants: true,
  fontFamily: defaultFontFamily.join(","),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  h1: {
    fontSize: "2rem",
    fontFamily: "BTBrikFont",
    fontWeight: 800,
    lineHeight: 1.2,
  },
  h2: {
    fontSize: "1.75rem",
    fontWeight: 800,
    fontFamily: "BTBrikFont",
    lineHeight: 1.2,
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: 800,
    fontFamily: "BTBrikFont",
    lineHeight: 1.2,
  },
  h4: {
    fontSize: "x-large",
    fontFamily: "BTBrikFont",
    fontWeight: 200,
    lineHeight: 1.2,
  },
  h5: {
    fontSize: "1.125rem",
    fontFamily: "BTBrikFont",
    fontWeight: 200,
    lineHeight: 1.2,
  },
  h6: {
    fontSize: "large",
    fontFamily: "Space Grotesk",
    fontWeight: 600,
    lineHeight: 1.2,
  },
  subtitle1: {
    fontFamily: "Space Grotesk",
    fontSize: "x-large",
    fontWeight: 600,
  },
  subtitle2: {
    fontFamily: "Space Grotesk",
    fontSize: 14,
  },
  body1: {
    fontSize: 14,
    fontFamily: "Space Grotesk",
  },
  body2: {
    fontSize: 14,
    fontFamily: "Space Grotesk",
    fontWeight: "bold",
  },
  caption: {
    fontSize: 16,
    fontFamily: "Space Grotesk",
    fontWeight: "600",
  },
  overline: {
    fontSize: 11,
    fontFamily: "Space Grotesk",
  },
  button: {
    textTransform: "none",
    fontFamily: "Space Grotesk",
    fontWeight: 700,
  },
};

export default typography;
