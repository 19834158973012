/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './editlocation.css';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import CreateIcon from '@mui/icons-material/Create';
import HouseIcon from '@mui/icons-material/House';
import { useDispatch, useSelector } from 'react-redux';
import { FILL_DETAILS_TEXT, TNCModalAction, WAREHOUSE_SELECT_DETAILS } from '../../Constants/constants';
import TextOrSelect from '../Forms/TextOrSelect';
import { Formik, Form } from 'formik';
import {
  validationSchema,
  initialValue,
  getStatesUrl,
  getCityUrl,
  getSubdivisonUrl,
  isTncEnabledForCountry,
  createStoreDataMapper,
  isOperableArray,
  isEmptyArray,
} from './form.helper';
import { useAxios } from '../../../hooks/useAxios';
import TermsnCondition from '../TnC/TermsnCondition';
import { Grid } from '../../Components/common';
import {
  COUNTRIES_URL,
  ONBOARD_CREATE_MARKETPLACE_CONNECTION,
  ONBOARD_CREATE_STORE,
  SHOPIFY_ACCEPT_TNC,
  SHOPIFY_GET_TNC,
} from '../../../api/api';
import { createOptions } from '../../../helpers/createOptions';
import { showNotification } from '../../../redux/notifications/actions';
import { useHistory } from 'react-router-dom';
import { paths } from '../../../routes/constants';
import { getUserDashboardFlags } from '../../../routes/helpers';
import SimpleBackdrop from '../../../Components/BackDrop';
import { loadUser } from '../../../redux/auth/actions';
import SimpleDividerWithText from '../../../Components/SimpleDividerWithText';
import { REFERER } from '../../Views/Onboarding/const';

function MapLocationFormForBrand({
  address,
  addressSelectOption = [],
  warehouseSelectOption = [],
  onboardingRequestId,
  loading,
  refer = REFERER.NONE,
}) {
  const formikRef = useRef();
  const [tncModal, setTNCModal] = useState(false); // to open or close TNC modal
  const [shouldShowLocation, setShouldShowLocation] = useState(true);
  const [shopifyLocation, setShopifyLocation] = useState({});
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [subDivisonOptions, setSubDivisonOptions] = useState([]);

  const addressLineValueGenerator = (index) => {
    if (isOperableArray(address)) {
      return `${address[index]?.address1 || ''}, ${address[index]?.address2 || ''}, ${address[index]?.city || ''}, ${
        address[index]?.state || ''
      }, ${address[index]?.zip || ''}`;
    } else {
      return '';
    }
  };
  const zipValueGenerator = (index) => (isOperableArray(address) ? address[index]?.zip || '' : '');

  const addressValueDependency = (index) => {
    if (isOperableArray(address)) {
      setStateOptions([]);
      setCityOptions([]);
      setSubDivisonOptions([]);
      formikRef.current.setFieldValue('warehouse', '');
      formikRef.current.setFieldValue('state', '');
      formikRef.current.setFieldValue('city', '');
      formikRef.current.setFieldValue('subdivision', '');
      setShopifyLocation(address[index]);
    }
    return index;
  };

  const warehouseValueGenerator = () => {
    return 0;
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);

  const handleRedirectAfterSuccessfulSetup = ({ user }) => {
    const { fulfilment_enabled, shipping_enabled } = getUserDashboardFlags(user);
    const onlyFulfilment = fulfilment_enabled && !shipping_enabled;
    const shippingEnabled = shipping_enabled;
    if (onlyFulfilment) {
      history.push(paths.MARKETPLACE_SETTINGS);
      return;
    } else if (shippingEnabled) {
      history.push({
        pathname: paths.MANAGE_SHIPMENTS.replace(':listId', 'list'),
        search: `?referrer=${refer}`,
      });
      return;
    } else {
      history.push({
        pathname: paths.MANAGE_SHIPMENTS.replace(':listId', 'list'),
        search: `?referrer=${refer}`,
      });
    }
  };

  const [tncType, setTncType] = useState('');

  const { data: tncData, callAxios: getTncData, isLoading: tncDataLoading } = useAxios({ callOnMount: false });
  const { baseAxiosCall: createStore } = useAxios({
    url: ONBOARD_CREATE_STORE,
    method: 'post',
    callOnMount: false,
    showError: true,
  });
  const { data: marketPlaceConnection, baseAxiosCall: createMarketPlaceConnection } = useAxios({
    url: ONBOARD_CREATE_MARKETPLACE_CONNECTION,
    callOnMount: false,
    method: 'post',
    showError: true,
  });
  const { callAxios: submitTnc } = useAxios({ url: SHOPIFY_ACCEPT_TNC, method: 'post', callOnMount: false });

  useEffect(() => {
    if (marketPlaceConnection) {
      history.push(paths.INDEX);
    }
  }, [marketPlaceConnection]);

  const [countryOptions, setCountryOptions] = useState([]);
  const { data: countries } = useAxios({ url: COUNTRIES_URL });

  const {
    data: states,
    callAxios: getStatesAction,
    isLoading: statesLoading,
  } = useAxios({
    callOnMount: false,
  });
  const {
    data: cities,
    callAxios: getCitiesAction,
    isLoading: citiesLoading,
  } = useAxios({
    callOnMount: false,
  });
  const {
    data: subdivs,
    callAxios: getSubDivisonAction,
    isLoading: subDivLoading,
  } = useAxios({
    callOnMount: false,
  });

  useEffect(() => {
    if (countries && countries.length) {
      const options = createOptions(countries, 'countriesWithId');
      setCountryOptions(options);
      if (isEmptyArray(addressSelectOption)) {
        getStatesAction({ url: getStatesUrl(formikRef.current.values['country']) });
      }
    }
  }, [countries]);

  /**
   * Below two use effects handles the scenario of pre-fill form
   * When we have locations form shopify
   *
   */
  useEffect(() => {
    if (Object.keys(shopifyLocation).length && shouldShowLocation) {
      const { mapped_country_id = '', mapped_city_id = '', mapped_state_id = '' } = shopifyLocation;
      if (mapped_country_id) {
        formikRef.current.setFieldValue('country', mapped_country_id);
        getStatesAction({ url: getStatesUrl(mapped_country_id) });
      }
      if (mapped_state_id) {
        getCitiesAction({ url: getCityUrl(mapped_state_id) });
      }
      if (mapped_city_id) {
        getSubDivisonAction({ url: getSubdivisonUrl(mapped_city_id) });
      }
    }
  }, [shopifyLocation, shouldShowLocation]);

  useEffect(() => {
    if (isOperableArray(stateOptions) && isOperableArray(cityOptions) && isOperableArray(subDivisonOptions)) {
      if (shopifyLocation && isOperableArray(Object.keys(shopifyLocation))) {
        formikRef.current.setFieldValue('state', shopifyLocation.mapped_state_id || '');
        formikRef.current.setFieldValue('city', shopifyLocation.mapped_city_id || '');
        formikRef.current.setFieldValue('subdivision', shopifyLocation.mapped_subdivision_id || '');
        setShopifyLocation({});
      }
    }
  }, [stateOptions, cityOptions, subDivisonOptions]);

  useEffect(() => {
    if (states) {
      const options = createOptions(states, 'defaultWithName');
      setStateOptions(options);
    }
  }, [states]);

  useEffect(() => {
    if (cities) {
      const options = createOptions(cities, 'defaultWithName');
      setCityOptions(options);
    }
  }, [cities]);

  useEffect(() => {
    if (subdivs) {
      const options = createOptions(subdivs, 'defaultWithName');
      setSubDivisonOptions(options);
    }
  }, [subdivs]);

  useEffect(() => {
    if (isOperableArray(warehouseSelectOption)) {
      setShouldShowLocation(false);
    }
  }, [warehouseSelectOption]);

  const formSubmitHandler = useCallback(
    (onboardingRequetId, values, setSubmitting) => {
      setSubmitting(true);
      const { value, location_code, warehouse } = createStoreDataMapper(
        onboardingRequetId,
        values,
        address,
        countries,
        states,
        cities,
        subdivs
      );
      // TODO: This section needs to be improved, skipping this for dev testing complexity
      if (warehouse) {
        createMarketPlaceConnection({
          data: {
            onboarding_request_id: onboardingRequestId,
            warehouse_id: warehouse,
            location_code: location_code,
          },
        })
          .then((marketPlaceConnection) => {
            dispatch(loadUser());
            if (marketPlaceConnection) {
              handleRedirectAfterSuccessfulSetup({ user });
            }
          })
          .catch((e) => {
            dispatch(showNotification(e.message, 'error'));
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        createStore({ data: value })
          .then((createdStore) => {
            if (createdStore.warehouse_id) {
              return createMarketPlaceConnection({
                data: {
                  onboarding_request_id: onboardingRequestId,
                  warehouse_id: createdStore.warehouse_id,
                  location_code: location_code,
                },
              });
            } else if (createdStore.message.toLowerCase() === 'hubspot') {
              history.push(paths.AUTH_SHOPIFY_HUBSPOT_USER_SIGN_IN);
              return Promise.resolve(null);
            }
          })
          .then((marketPlaceConnection) => {
            dispatch(loadUser());
            if (marketPlaceConnection) {
              handleRedirectAfterSuccessfulSetup({ user });
            }
          })
          .catch((e) => {
            dispatch(showNotification(e.message, 'error'));
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    [dispatch, user, address, countries, states, cities, subdivs]
  );

  const tncModalHandler = useCallback(
    (action, id, formik) => {
      if (action === TNCModalAction.ACCEPT) {
        submitTnc({ data: { id: tncData.id, type: tncType } });
        formSubmitHandler(onboardingRequestId, formik.values, formik.setSubmitting);
        setTNCModal(false);
      } else {
        formik.setSubmitting(false);
        setTNCModal(false);
      }
    },
    [tncData]
  );

  const finishSetupHandler =
    (onboardingRequetId) =>
    (values, { setSubmitting, validateForm }) => {
      validateForm().then((err) => {
        const errorFields = Object.keys(err);
        if (errorFields && errorFields.length === 0) {
          const tnc = isTncEnabledForCountry(values.country, countries);
          if (tnc && !user.is_staff) {
            setTncType(tnc);
            getTncData({ url: SHOPIFY_GET_TNC, params: { type: tnc } });
            setTNCModal(true);
          } else {
            formSubmitHandler(onboardingRequetId, values, setSubmitting);
          }
        }
      });
    };

  const shouldShowWareHouseDropdown = useCallback(
    (formik) => {
      return (
        isOperableArray(warehouseSelectOption) &&
        (formik.values.location || formik.values.location === 0) &&
        !shouldShowLocation
      );
    },
    [warehouseSelectOption, shouldShowLocation]
  );

  const shouldShowChooseWarehouseOption = useCallback(
    (formik) => {
      return isOperableArray(warehouseSelectOption) && (formik.values.location || formik.values.location === 0);
    },
    [warehouseSelectOption]
  );

  const triggerCountryChange = (countryId) => {
    setStateOptions([]);
    setCityOptions([]);
    setSubDivisonOptions([]);
    formikRef.current.setFieldValue('state', shopifyLocation.mapped_state_id || '');
    formikRef.current.setFieldValue('city', shopifyLocation.mapped_city_id || '');
    formikRef.current.setFieldValue('subdivision', shopifyLocation.mapped_subdivision_id || '');
    if (countryId) getStatesAction({ url: getStatesUrl(countryId) });
  };

  const triggerStateChange = (stateId) => {
    setCityOptions([]);
    setSubDivisonOptions([]);
    formikRef.current.setFieldValue('city', shopifyLocation.mapped_city_id || '');
    formikRef.current.setFieldValue('subdivision', shopifyLocation.mapped_subdivision_id || '');
    if (stateId) getCitiesAction({ url: getCityUrl(stateId) });
  };

  const triggerCityChange = (cityId) => {
    setSubDivisonOptions([]);
    formikRef.current.setFieldValue('subdivision', shopifyLocation.mapped_subdivision_id || '');
    if (cityId) getSubDivisonAction({ url: getSubdivisonUrl(cityId) });
  };

  return (
    <>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={finishSetupHandler(onboardingRequestId)}
        validateOnChange={false}
        validateOnBlur={true}
        innerRef={(f) => (formikRef.current = f)}
      >
        {(formik) => (
          <>
            <SimpleBackdrop show={statesLoading || citiesLoading || subDivLoading || loading} />
            <Form>
              <div className="editMapLocationContainer">
                <div className="full-width flex-simple">
                  <TextOrSelect
                    select={addressSelectOption && addressSelectOption.length}
                    label="Location Name"
                    name="location"
                    options={addressSelectOption}
                    dependentField="location"
                    dependency={addressValueDependency}
                    helperText={FILL_DETAILS_TEXT}
                  />
                </div>
                {shouldShowWareHouseDropdown(formik) ? (
                  <div className="full-width flex-simple gap-2">
                    <div className="full-width flex-simple">
                      <TextOrSelect
                        select={warehouseSelectOption && warehouseSelectOption.length}
                        label="Warehouse"
                        name="warehouse"
                        options={warehouseSelectOption}
                        dependentField="location"
                        dependency={warehouseValueGenerator}
                        helperText={WAREHOUSE_SELECT_DETAILS}
                      />
                    </div>
                    <div className="full-width flex-simple">
                      <SimpleDividerWithText />
                      <Button
                        style={{
                          fontWeight: 'bold',
                          alignSelf: 'center',
                          fontSize: '1.2rem',
                          color: 'var(--colors-secondary)',
                        }}
                        endIcon={<CreateIcon />}
                        onClick={(e) => setShouldShowLocation(true)}
                      >
                        Create a New Location
                      </Button>
                    </div>
                  </div>
                ) : (
                  false
                )}

                {/* Mannual Address Entry Section */}
                {(formik.values.location || formik.values.location === 0) && shouldShowLocation && (
                  <>
                    <TextOrSelect
                      customClass="address"
                      label="Address"
                      dependentField="location"
                      dependency={addressLineValueGenerator}
                      name="address_line1"
                    />
                    <TextOrSelect type="number" label="Phone Number" name="phone" />
                    <TextOrSelect
                      type="number"
                      label="Zip Code"
                      name="zipcode"
                      dependency={zipValueGenerator}
                      dependentField="location"
                    />
                    <TextOrSelect
                      select={countryOptions && countryOptions.length}
                      label="Country"
                      options={countryOptions}
                      name="country"
                      changeNotifier={triggerCountryChange}
                      key="country"
                      searchable={true}
                    />
                    <TextOrSelect
                      select={stateOptions && stateOptions.length}
                      label="State"
                      options={stateOptions}
                      changeNotifier={triggerStateChange}
                      name="state"
                      key="state"
                      searchable={true}
                    />
                    <TextOrSelect
                      select={cityOptions && cityOptions.length}
                      label="City"
                      options={cityOptions}
                      name="city"
                      changeNotifier={triggerCityChange}
                      key="city"
                      searchable={true}
                    />
                    <TextOrSelect
                      select={subDivisonOptions && subDivisonOptions.length}
                      label="Sub-Division"
                      options={subDivisonOptions}
                      name="subdivision"
                      key="subdivision"
                      searchable={true}
                    />
                    {shouldShowChooseWarehouseOption(formik) ? (
                      <div className="full-width flex-simple">
                        <SimpleDividerWithText />
                        <Button
                          style={{
                            fontWeight: 'bold',
                            alignSelf: 'center',
                            fontSize: '1.2rem',
                            color: 'var(--colors-secondary)',
                          }}
                          endIcon={<HouseIcon />}
                          onClick={(e) => setShouldShowLocation(false)}
                        >
                          Choose existing Warehouse
                        </Button>
                      </div>
                    ) : (
                      false
                    )}
                  </>
                )}
                {/* End = Mannual Address Entry Section */}
              </div>
              <Grid mt={8} mb={5} paddingX="1rem" container direction="row" justifyContent="space-between">
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<ArrowBackSharpIcon />}
                  onClick={(e) => {
                    window.history.back();
                  }}
                  sx={{
                    minWidth: '164px',
                    minHeight: '40px',
                    textAlign: 'center',
                    '&.MuiButtonBase-root': {
                      border: '0.1rem solid var(--colors-secondary)',
                      backgroundColor: 'var(--colors-primary)',
                      color: 'var(--colors-contrast-text-primary)',
                      borderRadius: '4px',
                      '&:disabled': {
                        backgroundColor: 'var(--color-hover-charcol) !important',
                        color: 'var(--colors-contrast-text-primary) !important',
                      },
                      '&:hover': {
                        backgroundColor: 'var(--colors-secondary-hover)',
                        color: 'var(--colors-contrast-text-seconday)',
                        border: '0.1rem solid var(--colors-primary)',
                      },
                    },
                  }}
                >
                  Brand Creation
                </Button>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  disabled={formik.isValidating || formik.isSubmitting}
                  sx={{
                    minWidth: '164px',
                    minHeight: '40px',
                    textAlign: 'center',
                    border: '0.1rem solid var(--colors-primary)',
                    '&.MuiButtonBase-root': {
                      color: 'black',
                      backgroundColor: 'var(--colors-secondary-hover)',
                      borderRadius: '4px',
                      '&:disabled': {
                        backgroundColor: 'var(--color-hover-charcol) !important',
                        color: 'var(--colors-contrast-text-primary) !important',
                      },
                      '&:hover': {
                        backgroundColor: 'var(--colors-primary)',
                        color: 'var(--colors-contrast-text-primary)',
                        border: '0.1rem solid var(--colors-secondary)',
                      },
                    },
                  }}
                >
                  {formik.isSubmitting ? (
                    <CircularProgress style={{ color: 'var(--colors-secondary)', width: '25px', height: '25px' }} />
                  ) : (
                    'Finish Setup'
                  )}
                </Button>
              </Grid>
            </Form>
            <TermsnCondition
              isopenModal={tncModal}
              onDialogCloseClick={(e) => {
                dispatch(showNotification('You must accept the Terms & Conditions to proceed with Setup', 'warning'));
                tncModalHandler(TNCModalAction.REJECT, tncData.id, formik);
              }}
              saveText={'Accept & Continue'}
              onSave={(e) => {
                tncModalHandler(TNCModalAction.ACCEPT, tncData.id, formik);
              }}
              cancelText="I Disagree"
              onCancel={(e) => {
                dispatch(showNotification('You must accept the Terms & Conditions to proceed with Setup', 'warning'));
                tncModalHandler(TNCModalAction.REJECT, tncData.id, formik);
              }}
              content={
                tncDataLoading ? (
                  <CircularProgress />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${tncData?.data}`,
                    }}
                  />
                )
              }
            ></TermsnCondition>
          </>
        )}
      </Formik>
    </>
  );
}

export default MapLocationFormForBrand;
