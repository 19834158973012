import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import styled from 'styled-components';
import { Alert } from '@mui/material';

const CustomSnackbar = styled(Snackbar)`
  z-index: 1;
  width: max-content;
`;

const BannerComponent = ({ isOpen, vertical, horizontal, children, severity, onBannerClose }) => {
  return (
    <CustomSnackbar
      open={isOpen}
      severity={severity}
      anchorOrigin={{
        vertical: vertical,
        horizontal: horizontal,
      }}
      onClose={() => onBannerClose(false)}
      data-testid="banner-component"
    >
      <Alert
        sx={{ background: 'none !important', '& .MuiBox-root': { background: 'var(--color-hover-charcol)' } }}
        icon={false}
      >
        {children}
      </Alert>
    </CustomSnackbar>
  );
};

export default BannerComponent;
