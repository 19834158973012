import * as actionTypes from './actionTypes';

const INITIAL_STATE = {
  run: false,
  continuous: false,
  loading: false,
  stepIndex: 0,
  steps: [],
  initStep: 0,
  targetId: '',
  parentRoute: '',
};

// Reducer will manage updating the local state
export default function productTourPrompt(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.START_PRODUCT_TOUR_PROMPT:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.STOP_PRODUCT_TOUR_PROMPT:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.TERMINATE_PROMPT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
