import React from 'react';
import { TextField as MuiTextField } from '@mui/material';

import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& label': {
      color: 'white',
    },
    '& label.Mui-disabled': {
      color: '#645873',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& input': {
      borderBottomColor: 'white',
      paddingLeft: '0.2rem',
    },
    '& input.Mui-disabled': {
      color: '#645873',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'var(--colors-secondary-hover)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
})(MuiTextField);

const TextField = (props) => {
  const { InputProps, ...rest } = props;
  const styles = makeStyles(() => ({
    input: { color: 'white' },
  }));
  const classes = styles();

  return <CustomTextField InputProps={{ className: classes.input, ...InputProps }} {...rest} />;
};

export default TextField;
