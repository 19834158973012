import {
  getMonthFromDateString,
  getTimeFromDateString,
  getYearFromDateString,
  monthFilter,
  normalizeDateToTimezone,
  timeFilter,
  yearFilter,
} from './datetime';

// Like map, but leaves only unique values in the array.
export const uniqueMap = (data, mapFunc = (i) => i) => Array.from(new Set(data.map(mapFunc))).reverse();

export const convertToMoney = (value, currencySymbol) => {
  const currency = currencySymbol ? currencySymbol + ' ' : '';
  return typeof value !== 'undefined' ? `${currency}${Number(parseFloat(value)).toLocaleString()}` : currency + '0';
};

export const sortObjectsByKey =
  (key, order = 'desk') =>
  (a, b) => {
    if (order === 'ask') {
      return a[key] - b[key];
    } else if (order === 'desk') {
      return b[key] - a[key];
    } else {
      return 0;
    }
  };

// When you need to move values from the deeper object to the top level.
export const flatten = (keys) => (object) => {
  return Object.keys(object).reduce((o, firstKey) => {
    if (isStrictObject(object[firstKey]) && keys.includes(firstKey)) {
      const newObject = { ...o };
      Object.keys(object[firstKey]).forEach((nextKey) => {
        newObject[firstKey + '_' + nextKey] = object[firstKey][nextKey];
      });
      return newObject;
    }
    return o;
  }, object);
};

// Reduce value in objects.
export const valueReducer = (field) => (acc, currentItem) => Number(acc) + Number(currentItem[field]);

/**
 * Accept an array of objects and length.
 * leave length -1 items, the last object will contain the data of all the rest objects (numbers will be added).
 */
export const compressData = (data, length) => {
  return length > 0 && data.length > length
    ? [...data.slice(0, length - 1), data.slice(length - 1).reduce(addObjectNumberPropertiesReducer)]
    : data;
};

//if you need to filter items with a few filter functions
export const filterItemsWithFunctions = (items = [], filterFunctions = []) => {
  return filterFunctions.length ? filterFunctions.reduce(filterReducer, items) : items;
};

// reduce number values of objects on some basis.
export const groupData = (data, groupBy, timeKey = 'time_bucket') => {
  const years = uniqueMap(data, (item) => getYearFromDateString(item[timeKey]));

  switch (groupBy) {
    case 'Daily':
      const uniqueTimes = uniqueMap(data, (item) => getTimeFromDateString(item[timeKey]));

      return uniqueTimes.map((time) => {
        return data.filter(timeFilter(time, timeKey)).reduce(addObjectNumberPropertiesReducer, {});
      });

    case 'Monthly':
      return years
        .map((year) => {
          const { yearData, months } = getYearDataAndUniqueMonths(data, year, timeKey);

          return months.map((month) => {
            return yearData.filter(monthFilter(month, timeKey)).reduce(addObjectNumberPropertiesReducer, {});
          });
        })
        .flat();

    case 'Yearly':
      return years.map((year) => {
        return data.filter(yearFilter(year, timeKey)).reduce(addObjectNumberPropertiesReducer, {});
      });

    case 'market':
      const markets = uniqueMap(data, (item) => item.marketplace_name);
      return markets.map((market) => {
        return data.filter((item) => item.marketplace_name === market).reduce(addObjectNumberPropertiesReducer, {});
      });

    case 'status':
      const statuses = uniqueMap(data, (item) => item.status);

      return statuses.map((status) => {
        return data.filter((item) => item.status === status).reduce(addObjectNumberPropertiesReducer, {});
      });

    default:
      return data;
  }
};

// Accepts an array of objects. Converts a key value to a percentage of the sum of all values for that key in objects.
export const convertNumberPropertiesToPercents = (data, key) => {
  if (!data || !Array.isArray(data) || !data.every((item) => item.hasOwnProperty(key))) {
    throw new Error('Wrong data or key do not exist in some item.');
  }
  const totalValue = data ? data.reduce((acc, current) => acc + Number(current[key]), 0) : 0;

  return data ? data.map((item) => (item[key] / totalValue) * 100) : [];
};

// Creates array from existing items.
export const getArrayFromParameters = (...args) => args.filter(Boolean);

// find column index by name or label
export const findColumnIndexes = (columns, entity, values) => {
  return values.map((val) => columns.findIndex((col) => col[entity] === val));
};

// reduce all number properties in objects
export const addObjectNumberPropertiesReducer = (acc, currentValue) => {
  let newObject = {};
  Object.keys(currentValue).forEach((key) => {
    newObject[key] =
      Number(acc[key]) || acc[key] === 0 ? Number(acc[key]) + (Number(currentValue[key]) || 0) : currentValue[key];
  });
  return newObject;
};

export const getYearDataAndUniqueMonths = (data, year, timeKey) => {
  const yearData = data.filter(yearFilter(year, timeKey));
  return {
    yearData,
    months: uniqueMap(yearData, (item) => getMonthFromDateString(item[timeKey])),
  };
};

export const normalizeDateObjectValues = (names) => (item) => {
  names.forEach((name) => {
    if (item[name]) {
      item[name] = normalizeDateToTimezone(item[name]);
    }
  });

  return item;
};

// compare objects
export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
};

export const isStrictObject = (obj) => typeof obj === 'object' && !Array.isArray(obj) && obj !== null;

// Local Helpers

const filterReducer = (currentItems, nextFunction) => {
  return currentItems.filter(nextFunction);
};

const isObject = (object) => {
  return object != null && typeof object === 'object';
};

export const formatWithThousandSeparator = (x) => {
  if (x && (parseInt(x) || parseInt(x) === 0)) return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

  return Number.isInteger(parseInt(x)) ? x : '-';
};
