import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  dividerBorder: {
    flexGrow: 1,
    borderColor: theme.palette.secondary.main,
    border: 'solid',
  },
  dividerText: {
    padding: theme.spacing(0, 2),
  },
}));

function SimpleDividerWithText() {
  const classes = useStyles();

  return (
    <Box className={classes.dividerContainer}>
      <Divider className={classes.dividerBorder} />
      <Typography variant="caption" className={classes.dividerText}>
        OR
      </Typography>
      <Divider className={classes.dividerBorder} />
    </Box>
  );
}

export default SimpleDividerWithText;
