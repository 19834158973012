/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import JoyRide, { ACTIONS, EVENTS } from 'react-joyride';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { showWelcomeMessage, startPartialOnboarding, start } from '../../redux/joyride/SelfServeOnboarding/actions';
import { onboardingCallbackHandler } from './components/Onboarding/callbacks';
import { productTourPromptCallBack } from './components/ProductTourPrompt/callBacks';
import { TooltipComponent } from './components/commonProps/TooltipComponent';
import { stylesOptionsAndComponents } from './components/commonProps';
import { onboardingStepDefinition } from './components/Onboarding/constants';

const Tour = ({ onboarding, productTourPrompt, routes }) => {
  const styleOptionsAndComponentsProps = stylesOptionsAndComponents();
  const dispatch = useDispatch();
  return onboarding?.inProgress ? (
    <JoyRide
      {...onboarding}
      callback={(data) =>
        onboardingCallbackHandler({
          data,
          ACTIONS,
          EVENTS,
          dispatch,
          routes,
        })
      }
      showSkipButton={true}
      showProgress={true}
      locale={{
        last: 'Finish Tour',
      }}
      {...styleOptionsAndComponentsProps}
    />
  ) : (
    <JoyRide
      {...productTourPrompt}
      callback={(data) => productTourPromptCallBack({ data, dispatch, EVENTS })}
      tooltipComponent={TooltipComponent}
      showSkipButton={false}
      showProgress={false}
      disableOverlayClose={true}
      locale={{
        last: 'Got it',
      }}
      floaterProps={{
        disableAnimation: true,
      }}
      {...styleOptionsAndComponentsProps}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    onboarding: { ...state.onboarding },
    productTourPrompt: { ...state.startProductTourPrompt },
  };
};

const mapDispatchToProps = (dispatch) => ({
  start: () =>
    dispatch(
      start({
        currentTargetId: onboardingStepDefinition?.[0]?.target?.substring(
          1,
          onboardingStepDefinition?.[0]?.target.length
        ),
      })
    ),
  showWelcomeMessage: () => dispatch(showWelcomeMessage()),
  startPartialOnboarding: (payload) => dispatch(startPartialOnboarding(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Tour);
