export const dialogText = {
  delAndReopenShipmentBtn: {
    dialogTitle: 'Are you sure you want to cancel-reopen this shipment?',
    dialogTitlePending: 'Are you sure you want to cancel this carrier booking?',
    dialogText1: 'Carrier booking and pickup will be cancelled',
    bodyTextHeader: 'Cancel-Reopen shipment booking',
    dialogText2: 'You can re-book this shipment with other carrier',
    saveText: 'Cancel carrier booking',
    saveTextPending: 'Cancel & Reopen shipment',
    footer: 'Some carriers may still attempt to pick up. You will not be charged if you do not hand over the parcel',
  },
  deleteShipmentBtn: {
    dialogTitle: 'Are you sure you want to permanently delete this shipment order?',
    dialogTitlePending: 'Are you sure you want to cancel this pending pickup?',
    bodyTextHeader: 'Cancel shipment booking',
    dialogText1: 'Shipment Order will be cancelled permanently',
    dialogText2: 'Pickup will be cancelled',
    saveText: 'Delete shipment order',
    saveTextPending: 'Cancel pickup & shipment',
    footer: 'Some carriers may still attempt to pick up. You will not be charged if you do not hand over the parcel',
  },
  onboarding: {
    welcome: `We'll walk you through how to prepare your brand account in order to book your first shipment. Let's get started!`,
  },
};
