import React from 'react';
import { SvgIcon } from '@mui/material';

function SalesOverviewSVG(props) {
  return (
    <SvgIcon {...props}>
      {/* <svg width="28" height="28" viewBox="0 0 36 36" fill="white" xmlns="http://www.w3.org/2000/svg"> */}
      <path d="M4 28V25.4667L6 23.4667V28H4ZM9.5 28V20.1333L11.5 18.1333V28H9.5ZM15 28V18.1333L17 20.1667V28H15ZM20.5 28V20.1667L22.5 18.1667V28H20.5ZM26 28V14.8L28 12.8V28H26ZM4 20.1333V17.3L13.3333 8.03333L18.6667 13.3667L28 4V6.83333L18.6667 16.2L13.3333 10.8667L4 20.1333Z" />
      {/* </svg> */}
    </SvgIcon>
  );
}
export default SalesOverviewSVG;
