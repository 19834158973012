import React from 'react';
import { Button } from '@mui/material';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    visibility:none;
  }
  to{
    visibility: block;
  }
`;

const TooltipBody = styled.div`
  background: #fff;
  width: 450px;
  margin: none;
  padding: none;
  boxshadow: 'rgba(0, 0, 0, 0.35) 0px 5px 14px';
  border-radius: 12px;
  animation: ${fadeIn} 0.5s ease-in-out;
  z-index: 1000 !important;
  padding: 8px !important;
`;
const TooltipContent = styled.div`
  padding: 16px;
`;
const TooltipTitle = styled.div``;

const TooltipFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
`;

const HorizontalLine = styled.hr`
  margin: 0;
  padding: 0;
`;
const ProgressBar = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const SkipLink = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  margin-right: 8px;
`;

const CombineLinksAndButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export function TooltipComponent({ continuous, index, step, primaryProps, tooltipProps, size, skipProps }) {
  return (
    <TooltipBody {...tooltipProps}>
      {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
      <TooltipContent>{step.content}</TooltipContent>
      <HorizontalLine />
      <TooltipFooter
        style={
          step.showProgress && step.showSkipButton
            ? { justifyContent: 'space-between' }
            : { justifyContent: 'flex-end' }
        }
      >
        {continuous && step.showProgress && <ProgressBar>{`step ${index + 1} of ${size}`}</ProgressBar>}
        <CombineLinksAndButtons>
          {continuous && step.showSkipButton && <SkipLink {...skipProps}>{'Skip Tips'}</SkipLink>}
          <Button style={step.styles.buttonNext} {...primaryProps}>
            {primaryProps.title}
          </Button>
        </CombineLinksAndButtons>
      </TooltipFooter>
    </TooltipBody>
  );
}
