import React from 'react';
import styled from 'styled-components';
import { Divider as MuiDivider, Grid as MuiGrid, Typography, Box } from '@mui/material';
import { spacing } from '@mui/system';
import PageTitle from './PageTitle';

/**
 * @param accessoryRight
 * component to be rendered beside page header title
 * @param pageTitle
 * text for react helmet page title, if @param pageTitle doesn't exist,
 * will use @param title for react helmet page title and page header by default
 * */

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const HeaderTitleContainer = styled(Box)`
  display: flex;
  align-items: center;
`;
export default function PageHeader({ title, children, subtitle, pageTitle, accessoryRight, superTag }) {
  const resolvePageTitle = pageTitle ? pageTitle : title;

  return (
    <div data-testid="page-header">
      <PageTitle title={resolvePageTitle} />
      <Grid justifyContent="space-between" alignItems="center" container spacing={3}>
        <Grid item>
          <HeaderTitleContainer>
            <Typography variant="h4" display="inline">
              {title}
              <sup>{superTag}</sup>
            </Typography>
            {accessoryRight && <Box ml={5}>{accessoryRight}</Box>}
          </HeaderTitleContainer>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
      {subtitle && (
        <Grid container>
          <Grid item py={0}>
            <Typography variant="body2">{subtitle}</Typography>
          </Grid>
        </Grid>
      )}
      <Divider mt={!!subtitle ? 1 : 4} mb={6} />
    </div>
  );
}
