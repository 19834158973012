export const PULLOUT_AND_B2B = {
  title: 'B2B/Distribution',
  PULLOUT: {
    title: 'Pullout Number',
  },
  PULLOUT_REQUESTS: {
    title: 'Create B2B / Pullout Order', // 'Pullout Requests',
  },
  PULLOUT_B2B_REQUESTS: {
    title: 'Pullout & B2B Requests',
  },
  PULLOUT_TRACKING: {
    title: 'B2B / Pullout Tracking', //'Pullout Tracking',
  },
  PULLOUT_DETAIL: {
    title: 'Pullout Detail',
  },
  PULLOUT_REVIEW_DETAIL: {
    title: 'Pullout Review Detail',
  },
  CREATE_DISTRIBUTION_ORDER: {
    title: 'Create B2B / Pullout Order', //'Create a Distribution Order',
    id: 'CREATE_DISTRIBUTION_ORDER',
  },
};
