export const SHIPMENTS = {
  title: 'Shipments',
  SHIPMENT_D: {
    title: 'Shipment Dashboard',
  },
  BOOK_SHIPMENTS: {
    title: 'Book Shipments',
    subtitle:
      'Choose your preferred carrier options, or use the optimized carrier recommendations pre-selected by Locad. Select shipments, then click on Book Shipments to proceed.',
  },
  PICKUP_L: {
    title: 'Pickup Locations',
  },
  PENDING_PICKUPS: {
    title: 'Pending Pickups',
    subtitle:
      'View all parcels that are pending pickup. Print Airwaybills and attach parcels. Parcels may take a few hours to update after being picked up.',
  },
  TRACK_SHIPMENT: {
    title: 'Track Shipments',
    secTitle: 'Track Shipment Orders',
    subtitle: 'View all shipment orders that have been successfully created.',
  },
  ADD_SINGLE: {
    title: 'Add single shipment',
  },
  EDIT_SINGLE: {
    title: 'Edit single shipment',
  },
  SHIPMENT_ORDERS: {
    title: 'Shipment Orders',
  },
};

export const FreshDeskCustomerQueryTexts = {
  ADD_BANK_DETAIL: 'I want to set up bank details for COD remittance',
};
