import * as actionTypes from './actionTypes';
import { getJoyrideSteps, getSubstringId } from '../../../Components/Joyride/components/Onboarding/helpers';
import { onboardingStepDefinition } from '../../../Components/Joyride/components/Onboarding/constants';

/**
 * to create an api action:import { createAPIAction } from '../store/apiMiddleware/utils';
 * import your url here and if data is to be passed in the body just pass a body param and like body: {data}
 */

export function updateRouteIndex(payload) {
  return {
    type: actionTypes.UPDATE_ROUTE_INDEX,
    payload,
  };
}
export function showWelcomeMessage({ ...rest }) {
  const showWelcomeMessagePayload = {
    showWelcomeMessage: true,
    inProgress: true,
    continuous: true,
    isSidebarOpen: true,
    openRouteIndex: null,
    useJoyrideIndexRoutes: false,
    run: false,
    loading: false,
    stepIndex: 0,
    step: 0,
    steps: getJoyrideSteps(onboardingStepDefinition),
    initStep: 0,
    currentTargetId: '',
    ...rest,
  };
  return {
    type: actionTypes.SHOW_WELCOME_MESSAGE,
    payload: showWelcomeMessagePayload,
  };
}

export function start({ ...rest }) {
  const startPayload = {
    run: true,
    continuous: true,
    steps: getJoyrideSteps(onboardingStepDefinition),
    stepIndex: 0,
    isSidebarOpen: true,
    useJoyrideIndexRoutes: true,
    inProgress: true,
    currentTargetId: getSubstringId(onboardingStepDefinition?.[0]?.target),
    showWelcomeMessage: false,
    initiatePartialTour: false,
    ...rest,
  };
  return {
    type: actionTypes.START,
    payload: startPayload,
  };
}
export function markPartialTourVisited({ ...rest }) {
  return {
    type: actionTypes.MARK_PARTIAL_TOUR_VISITED,
    payload: {
      initiatePartialTour: true,
      ...rest,
    },
  };
}
export function startPartialOnboarding({ steps, ...rest }) {
  const startPartialOnboardingPayload = {
    run: true,
    continuous: true,
    steps: getJoyrideSteps(steps),
    stepIndex: 0,
    isSidebarOpen: true,
    useJoyrideIndexRoutes: true,
    currentTargetId: steps?.[0]?.target?.substring(1, steps?.[0]?.target?.substring),
    showWelcomeMessage: false,
    ...rest,
  };
  return {
    type: actionTypes.START_PARTIAL_ONBOARDING,
    startPartialOnboardingPayload,
  };
}

export function incrementNextStep(payload) {
  return {
    type: actionTypes.NEXT_OR_PREV,
    payload,
  };
}

export function stop() {
  const payload = {
    run: false,
    continuous: false,
    inProgress: false,
    openRouteIndex: null,
    useJoyrideIndexRoutes: false,
    stepIndex: 0,
    step: 0,
    steps: [],
    currentTargetId: null,
  };
  return {
    type: actionTypes.STOP,
    payload,
  };
}
export function terminate() {
  return { type: actionTypes.TERMINATE, payload: { inProgress: true } };
}
