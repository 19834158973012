import React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DomainMovedMessage from "./MessgaeBody";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

const CustomBox = styled(Box)`
  display: flex;
  flex-direction: row;
  border-radius: 7px;
  background-color: white;
  align-items: center;
  border: 0.1rem solid ${(props) => props.theme.sidebar.background};
`;

const CustomButton = styled(Button)`
  color: red;
`;

const CustomTypography = styled(Typography)`
  margin-top: 1vh;
  color: var(--colors-secondary);
`;

const DomainMovedbanner = ({ closebanner, removeDomainMessage }) => {
  return (
    (<CustomBox>
      <DomainMovedMessage msg="NEW LOOK, SAME LOCAD: Our newly-designed dashboard maintains the same functions." />
      <CustomButton
        color="secondary"
        size="small"
        onClick={() => removeDomainMessage(true)}
      >
        <CustomTypography variant="button" gutterBottom>
          Got It
        </CustomTypography>
      </CustomButton>
      <IconButton
        aria-label="close"
        color="inherit"
        onClick={() => closebanner(false)}
        size="large">
        <CloseIcon />
      </IconButton>
    </CustomBox>)
  );
};
export default DomainMovedbanner;
