import React from 'react'
import { Provider } from "react-redux";

const WithReduxStore = ({ store, children }) => {
  return (
    <Provider store={store}>
      { children }
    </Provider>
  )
}

export default WithReduxStore