import React from "react";
import SimpleDialog from "../../../Components/SimpleDialog";

const TermsnCondition = ({
  isopenModal,
  onDialogCloseClick,
  content,
  subtitle,
  cancelText,
  saveText,
  onSave,
}) => {
  return (
    <SimpleDialog
      maxWidth={false}
      style={{
        float: "right",
        width: "60%",
        margin: "auto",
      }}
      open={isopenModal}
      subtitle={subtitle}
      cancelText={cancelText}
      onClose={onDialogCloseClick}
      saveText={saveText}
      onSave={onSave}
      title={"Terms and Conditions"}
    >
      <div data-testid="Ship-modal-content">{content}</div>
    </SimpleDialog>
  );
};

export default TermsnCondition;
