import { normalizeDateOnlyToTimezone } from './datetime';

/**
 * to create options in one way through all the application.
 */

const getAddressLabel = (address, known_as) =>
  `${known_as} - ${address.city}, ${address.country}. ${address.address_line1} ${address.address_line2} ${address.address_line3}`;

const TYPES_TO_MAP_FUNCTIONS = {
  default: (item) => ({ label: item, value: item }),
  defaultWithName: (item) => ({ label: item.name, value: item.id }),
  locations: ({ id, address, known_as }) => ({
    label: getAddressLabel(address, known_as),
    value: id,
  }),
  // TODO hide (symbol) when it is null
  currencies: (item) => ({
    label: `${item.name} (${item.symbol})`,
    value: item.code,
  }),
  countries: (item) => ({ label: item.country_name, value: item.country_code }),
  countriesWithId: (item) => ({
    label: item.country_name,
    value: item.id,
    code: item.country_code,
  }),
  carrierConnections: (item) => ({
    label: `${item.carrier_connection_name || item.name} ${item.country_name}`,
    value: item.carrier_connection_id,
  }),
  shipmentTypes: (item) => ({
    label: `${item[0]} (${item[1]})`,
    value: item[0],
  }),
  pickupDate: (item) => ({
    label: `${normalizeDateOnlyToTimezone(item.date)}`,
    value: item.date,
  }),
  dateSlots: (item) => ({
    label: `${item.start_time} - ${item.end_time}`,
    value: item.id,
  }),
  pickupLocationCarrierConnections: (item) => ({
    label: `${item.carrier_name} ${item.country} (${item.connection_name})`,
    value: item.id,
  }),
  warehouseConnections: (item) => ({
    label: `${item.name} ${item.country_name}`,
    value: item.warehouse_id,
  }),
  bookmarkAddress: (item) => ({
    label: `${item.known_as}    --   ${item.city}, ${item.state}, (${item.country})`,
    value: item.id,
  }),
  shopifyAddresses: (item, index) => ({
    label: `${item?.name || ''} - , ${item?.address1 || ''}, ${item?.address2 || ''}, ${item?.city || ''}, ${
      item?.localized_country_name || ''
    }, ${item?.localized_province_name || ''}, ${item?.zip || ''}`,
    value: index,
  }),
  shippingProfiles: (item) => ({
    label: `${item?.name} [${item?.country?.code}]`,
    value: item?.id,
  }),
  b2bChannels: (item) => ({
    label: item?.channel_name,
    value: item?.id,
  }),
  shippingCountries: (item) => ({
    label: item?.country,
    value: item?.country_code,
  }),
  onboardingWarehouse: (item) => ({
    label: `${item.warehouse.name} (${item.warehouse.country.country_code})`,
    value: item.warehouse.id,
  }),
};

export const createOptions = (items, type = 'default') => {
  if (!Array.isArray(items)) {
    return [];
  }
  if (TYPES_TO_MAP_FUNCTIONS[type]) {
    return items.map(TYPES_TO_MAP_FUNCTIONS[type]);
  } else {
    console.error(`Map function for type ${type} is not defined.`);
    return [];
  }
};
