import { SET_THEME } from './actionTypes';
import actionTypes from '../auth/actionTypes';

export default function reducer(state = { currentTheme: 0 }, actions) {
  switch (actions.type) {
    case SET_THEME:
      return {
        ...state,
        currentTheme: actions.payload,
      };

    case actionTypes.LOAD_USER_SUCCESS:
      return checkEtailyTheme(actions, state);

    case actionTypes.USER_UPDATE_SUCCESS:
      return checkEtailyTheme(actions, state);

    default:
      return state;
  }
}

function checkEtailyTheme(actions, state) {
  if (actions.response.current_brand.is_etaily) {
    return { ...state, currentTheme: 1 };
  } else {
    return { ...state, currentTheme: 0 };
  }
}
