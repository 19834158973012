import { LS_OMS_CURRENT_ACTIVE_BRAND, PERMISSIONS } from '../../Auth/Constants/constants';
import { DEFAULT_RELEASED_DATE } from '../../Billing/Constants/Constants';
import actionTypes from './actionTypes';
import { logOutCognito } from '../../Auth/Hooks/useCognito';
import { isMigrationEnabledForDev } from '../../helpers/Auth/helpers';
import get from 'lodash.get';

const DATE_FILTER_STORAGE_KEY = 'GDF';

const initialState = {
  userLoaded: false,
  user_migration_in_progress: false,
  user: null,
  // to resolve the 404 issue on logout when version specific route does not available
  default_dashboard_version: 2,
  authErrors: null,
  loginErrors: null,
  updateErrors: null,
  // date filter value to save it even after page update.
  globalDateFilter: JSON.parse(localStorage.getItem(DATE_FILTER_STORAGE_KEY)) || null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case actionTypes.USER_MIGRATION:
      return {
        ...state,
        user_migration_in_progress: action.payload,
      };
    case actionTypes.LOAD_USER_SUCCESS:
      localStorage.setItem(LS_OMS_CURRENT_ACTIVE_BRAND, action.response?.current_brand?.id);
      return {
        ...state,
        userLoaded: true,
        user: addUserPermissionsBasedData(action.response),
        default_dashboard_version: action.response?.dashboard_version || initialState.default_dashboard_version,
        authErrors: null,
      };

    case actionTypes.LOAD_USER_FAIL:
      return {
        ...state,
        userLoaded: true,
        user: null,
        authErrors: action?.error?.data,
      };

    case actionTypes.USER_LOGIN_SUCCESS:
      if (!action.skipToken) {
        localStorage.setItem('token', action.response.access);
        localStorage.setItem('refresh', action.response.refresh);
      }
      return { ...state, loginErrors: null, userLoaded: false };

    case actionTypes.USER_LOGIN_FAIL:
      return { ...state, loginErrors: action.error?.data || null };

    case actionTypes.USER_LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
      localStorage.removeItem(LS_OMS_CURRENT_ACTIVE_BRAND);
      if (!action.skipCognito && isMigrationEnabledForDev()) {
        logOutCognito({ signOutUrl: window.locadui.SIGNOUT_URL, cliendId: window.locadui.AUTH_CLIENT_ID });
        return {
          ...state,
        };
      }
      return {
        ...state,
        userLoaded: true,
        user: null,
        authErrors: null,
        updateErrors: null,
        loginErrors: null,
      };

    case actionTypes.USER_UPDATE_SUCCESS:
      localStorage.setItem(LS_OMS_CURRENT_ACTIVE_BRAND, action.response?.current_brand?.id);
      return {
        ...state,
        user: {
          ...state.user,
          ...addUserPermissionsBasedData(action.response),
        },
      };

    case actionTypes.USER_UPDATE_FAIL:
      return { ...state, updateErrors: action.error.data };

    case actionTypes.UPDATE_BRAND_DETAILS_SUCCESS:
      console.log(action);
      return {
        ...state,
        user: {
          ...state.user,
          current_brand: {
            ...state.user?.current_brand,
            public_display_name: get(
              action,
              'response.public_display_name',
              state.user?.current_brand?.public_display_name
            ),
          },
        },
      };

    case actionTypes.SET_GLOBAL_DATE_FILTER:
      localStorage.setItem(DATE_FILTER_STORAGE_KEY, JSON.stringify(action.payload));
      return { ...state, globalDateFilter: action.payload };

    default:
      return state;
  }
}

function addUserPermissionsBasedData(user) {
  return {
    ...user,
    billingReleasedDate: getBillingReleasedDate(user),
    isBillingModuleVisible:
      user.current_brand?.billing_visibility &&
      (!user?.current_brand?.module_settings?.billing_profile ||
        user?.current_brand?.module_settings?.billing_profile?.billing_visibility),
    isBillingRateEditAllowed: user.permissions.includes(PERMISSIONS.BILLING_RATE_EDIT),
    canRunBills: user.permissions.includes(PERMISSIONS.RUN_BILLS_FROM_UI),
    billingVersion: user.current_brand.module_settings?.billing_profile?.storage_configuration?.version || 'V1',
  };
}

export const getBillingReleasedDate = (user) =>
  user.current_brand.module_settings?.billing_profile?.billing_released_date || DEFAULT_RELEASED_DATE;
