/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, InputLabel, FormControl, FormHelperText } from '@mui/material';
import React, { useEffect } from 'react';
import { StyledSelect as Select } from './styled-components';
// import useDebounce from '../../../hooks/useDebounce';
import TextField from '../CustomTextField';
import { useField, useFormikContext } from 'formik';
import { styled, withStyles } from '@mui/styles';
import AppSelect from '../../../Components/form/AppSelect';

const CustomFormHelperText = withStyles((theme) => ({
  root: {
    color: 'var(--colors-secondary)',
    fontSize: '0.9rem',
    fontStyle: 'italic',
  },
}))(FormHelperText);

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    color: 'white', // Enforce label color
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'white', // Ensure it stays white when focused
  },
});

function TextOrSelect({
  select,
  options,
  label,
  customClass,
  dependentField,
  dependency,
  changeNotifier,
  helperText = '    ',
  searchable = false,
  ...props
}) {
  const { values, touched, dirty } = useFormikContext();
  const [field, meta, helper] = useField(props);
  const debouncedValue = values[dependentField]; //useDebounce(values[dependentField], 500);
  useEffect(() => {
    if (dependentField) {
      const dependencyValue = values[dependentField];
      if ((touched[dependentField] || dirty) && dependency && (dependencyValue || dependencyValue === 0)) {
        const calculatedValue = dependency(dependencyValue);
        helper.setValue(Number.isSafeInteger(calculatedValue) ? calculatedValue : calculatedValue || '');
      } else {
        helper.setValue('');
      }
    }
  }, [debouncedValue]);

  return (
    <FormControl variant="standard" className={customClass} error={Boolean(meta.touched && meta.error)}>
      {!select ? (
        <TextField label={label} {...field} {...props} variant="standard" />
      ) : searchable ? (
        <AppSelect
          options={options}
          label={label}
          searchable={searchable}
          labelId={`${label}-id-label`}
          id={`${label}-id-label-select`}
          {...field}
          {...props}
          onChange={(e) => {
            let value = e?.target?.value;
            if (changeNotifier && typeof changeNotifier === 'function') changeNotifier(value);
            helper.setValue(value);
          }}
          autoFocus={false}
          innerInputProps={{
            style: {
              color: 'var(--colors-contrast-text-primary)',
              borderBottom: '1px solid var(--colors-contrast-text-primary)',
            },
          }}
          CustomTextField={CustomTextField}
        />
      ) : (
        <>
          <InputLabel style={{ color: 'var(--colors-contrast-text-primary)' }} id={`${label}-id-label`}>
            {label}
          </InputLabel>
          <Select
            labelId={`${label}-id-label`}
            id={`${label}-id-label-select`}
            {...field}
            {...props}
            onChange={(e) => {
              if (['click', 'keydown'].includes(e.type) && Boolean(changeNotifier)) {
                changeNotifier(e.target.value);
              }
              field.onChange(e);
            }}
            variant="standard"
          >
            {options?.map(({ label, value }, index) => {
              return (
                <MenuItem value={value} key={index}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </>
      )}
      <CustomFormHelperText>{Boolean(meta.touched && meta.error) ? meta.error : helperText}</CustomFormHelperText>
    </FormControl>
  );
}

export default TextOrSelect;
