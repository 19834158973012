import {
  CLOSE_CONFIRMATION_DIALOG,
  OPEN_CONFIRMATION_DIALOG,
} from "./actionTypes";

export function openConfirmationDialog(title, text, callback) {
  return {
    type: OPEN_CONFIRMATION_DIALOG,
    payload: {
      title,
      text,
      callback,
    },
  };
}

export function closeConfirmationDialog() {
  return {
    type: CLOSE_CONFIRMATION_DIALOG,
  };
}
