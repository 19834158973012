const PRIVACYURL = 'https://golocad.com/legal/privacy-policy/';
const TnCURL = 'https://golocad.com/legal/terms-and-conditions/';

export const openPrivacy_TnCPage = (type) => {
  switch (type) {
    case 'Privacy':
      window.open(PRIVACYURL, '_blank');
      break;

    case 'Terms of Service':
      window.open(TnCURL, '_blank');
      break;

    default:
      break;
  }
};
