import { Typography, CssBaseline } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import Layout from '../Components/Layout';
import { NoBrandAssigned, SHOPIFY_PUBLIC_APP_URL } from '../Constants/constants';
import styled, { createGlobalStyle } from 'styled-components';
import { AuthSecondaryMUIButton, CustomBtn } from '../Components/common';
import { logout } from '../../redux/auth/actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { paths } from '../../routes/constants';
import { IMG_SRC } from '../../constants';
import { Power, ShoppingBag } from 'react-feather';
const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: ${(props) => props.theme.body.login?.layoutBg};
    transition: background 0.3s;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const NoBrandContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  max-width: 650px;
  gap: 1rem;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

function NoBrandUser() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();
  if (!user) {
    history.replace(paths.AUTH_LOGIN);
    return <></>;
  }
  return (
    <NoBrandContainer>
      <img alt="not-ok-icon" src={IMG_SRC.NOT_OK_ICON} />
      <MessageContainer>
        <Typography className="header-st1">{NoBrandAssigned.header}</Typography>
        <Typography variant="caption">
          <div dangerouslySetInnerHTML={{ __html: NoBrandAssigned.body(user?.email || '') }}></div>
        </Typography>
      </MessageContainer>
      <ButtonContainer>
        <CustomBtn
          size="small"
          variant="contained"
          startIcon={<ShoppingBag />}
          onClick={(e) => {
            window.open(SHOPIFY_PUBLIC_APP_URL, '_self');
          }}
        >
          <Typography variant="h6">Check out our Shopify app</Typography>
        </CustomBtn>
        <AuthSecondaryMUIButton
          size="small"
          type="submit"
          variant="contained"
          endIcon={<Power />}
          onClick={(e) => {
            dispatch(logout());
            history.replace(paths.AUTH_LOGIN);
          }}
        >
          <Typography variant="h6">Logout</Typography>
        </AuthSecondaryMUIButton>
      </ButtonContainer>
    </NoBrandContainer>
  );
}

const UserWithoutBrand = () => (
  <Root>
    <GlobalStyle />
    <CssBaseline />
    <Layout content={<NoBrandUser />} progressbar={null} />
  </Root>
);

export default UserWithoutBrand;
