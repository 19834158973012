import {
  CARRIER_ENTREGO_VALIDATE_URL,
  CARRIER_LALAMOVE_VALIDATE_URL,
  CARRIER_PICKUPP_VALIDATE_URL,
  CARRIER_NINJAVAN_VALIDATE_URL,
  CARRIER_XDE_VALIDATE_URL,
  CARRIER_JNTPH_VALIDATE_URL,
  CARRIER_JNTSG_VALIDATE_URL,
  CARRIER_LBC_VALIDATE_URL,
  CARRIER_DHL_VALIDATE_URL,
  CARRIER_AUSPOST_VALIDATE_URL,
  CARRIER_ASENDIA_VALIDATE_URL,
  CARRIER_SINGPOST_VALIDATE_URL,
  CARRIER_SENDLE_VALIDATE_URL,
  CARRIER_KERRY_VALIDATE_URL,
  CARRIER_TELEPORT_VALIDATE_URL,
  LOGIN_URL,
  MARKETPLACE_BBO_VALIDATE_URL,
  MARKETPLACE_GINEE_VALIDATE_URL,
  MARKETPLACE_KUMU_VALIDATE_URL,
  MARKETPLACE_WOOCOMMERCE_VALIDATE_URL,
  MARKETPLACE_ZALORA_VALIDATE_URL,
  MARKETPLACE_CAPILLARY_VALIDATE_URL,
  CUSTOM_WEBSTORE_VALIDATE_URL,
  PASSWORD_CHANGE_URL,
  USER_URL,
  WAREHOUSE_WAREO_VALIDATE_URL,
  WAREHOUSE_UNIWARE_VALIDATE_URL,
  WAREHOUSE_LOGIWA_VALIDATE_URL,
  MARKETPLACE_WIX_VALIDATE_URL,
  MARKETPLACE_TOKOPEDIA_VALIDATE_URL,
  WAREHOUSE_WAREO_3_VALIDATE_URL,
} from '../../api/api';
import actionTypes from './actionTypes';
import { createAPIAction } from '../store/apiMiddleware/utils';

export const loadUser = () =>
  createAPIAction({
    url: USER_URL,
    method: 'get',
    actionTypes: [null, actionTypes.LOAD_USER_SUCCESS, actionTypes.LOAD_USER_FAIL],
  });

export const login = (username, password) =>
  createAPIAction({
    url: LOGIN_URL,
    method: 'post',
    actionTypes: [null, actionTypes.USER_LOGIN_SUCCESS, actionTypes.USER_LOGIN_FAIL],
    body: { username, password },
  });

export const logout = (payload = {}) => ({ type: actionTypes.USER_LOGOUT, skipCognito: payload.skipCognito || false });

export const updateUser = (field, value) =>
  createAPIAction({
    url: USER_URL,
    method: 'patch',
    actionTypes: [null, actionTypes.USER_UPDATE_SUCCESS, actionTypes.USER_UPDATE_FAIL],
    body: { [field]: value },
  });

export const changeUserPassword = (old_password, new_password, confirm_new_password) =>
  createAPIAction({
    url: PASSWORD_CHANGE_URL,
    method: 'put',
    body: { old_password, new_password, confirm_new_password },
  });

export const woocommerceAuthorize = (body) =>
  createAPIAction({
    url: MARKETPLACE_WOOCOMMERCE_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const wixAuthorize = (body) =>
  createAPIAction({
    url: MARKETPLACE_WIX_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const tokopediaAuthorize = (body) =>
  createAPIAction({
    url: MARKETPLACE_TOKOPEDIA_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const zaloraAuthorize = (body) =>
  createAPIAction({
    url: MARKETPLACE_ZALORA_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const capillaryAuthorize = (body) =>
  createAPIAction({
    url: MARKETPLACE_CAPILLARY_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const customAuthorize = (body) =>
  createAPIAction({
    url: CUSTOM_WEBSTORE_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const kumuAuthorize = (body) =>
  createAPIAction({
    url: MARKETPLACE_KUMU_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const bboAuthorize = (body) =>
  createAPIAction({
    url: MARKETPLACE_BBO_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const gineeAuthorize = (body) =>
  createAPIAction({
    url: MARKETPLACE_GINEE_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const wareo3Authorize = (body) =>
  createAPIAction({
    url: WAREHOUSE_WAREO_3_VALIDATE_URL,
    method: 'post',
    body: body,
  });
export const wareoAuthorize = (body) =>
  createAPIAction({
    url: WAREHOUSE_WAREO_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const uniwareAuthorize = (body) =>
  createAPIAction({
    url: WAREHOUSE_UNIWARE_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const logiwaAuthorize = (body) =>
  createAPIAction({
    url: WAREHOUSE_LOGIWA_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const getLogiwaAuthorizeData = (params) =>
  createAPIAction({
    url: WAREHOUSE_LOGIWA_VALIDATE_URL,
    method: 'get',
    params,
  });

export const ninjavanAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_NINJAVAN_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const entregoAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_ENTREGO_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const xdeAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_XDE_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const lalamoveAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_LALAMOVE_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const pickuppAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_PICKUPP_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const jntphAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_JNTPH_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const jntSgAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_JNTSG_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const lbcAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_LBC_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const dhlAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_DHL_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const auspostAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_AUSPOST_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const asendiaAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_ASENDIA_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const sendleAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_SENDLE_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const kerryAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_KERRY_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const teleportAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_TELEPORT_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const singpostAuthorize = (body) =>
  createAPIAction({
    url: CARRIER_SINGPOST_VALIDATE_URL,
    method: 'post',
    body: body,
  });

export const setGlobalDateFilter = (filterObject) => ({
  type: actionTypes.SET_GLOBAL_DATE_FILTER,
  payload: filterObject,
});

export const setBillingGlobalDateFilter = (filterObject) => ({
  type: actionTypes.SET_BILLING_GLOBAL_DATE_FILTER,
  payload: filterObject,
});

export const userMigrationInProgress = (value) => ({
  type: actionTypes.USER_MIGRATION,
  payload: value,
});
