export const FULFILLMENT = {
  F_DASHBOARD: {
    title: 'Fulfillment Dashboard',
  },
  F_DASHBOARD_V2: {
    title: 'Fulfillment Dashboard - V2',
  },
  CREATE_SHIPMENT_ORDER: {
    title: 'Create Shipment Order',
  },
  EDIT_SHIPMENT_ORDER: {
    title: 'Edit Shipment Order',
  },
  CREATE_PICKUP_LOCATION: {
    title: 'Create Pickup Location',
  },
};
