import { useDispatch, useSelector } from "react-redux";

/**
 *
 * @param selector
 * useDispatch and useSelector combined cause they often used together
 */

export const useRedux = (selector) => {
  const dispatch = useDispatch();
  const data = useSelector(selector);
  return [data, dispatch];
};
