import { format, getMonth, getYear } from 'date-fns';
import { padZeroForMonth } from '../../helpers/datetime';
import authTypes from '../auth/actionTypes';
import { getBillingReleasedDate } from '../auth/reducer';
import * as actionTypes from './actionTypes';

const today = new Date();

const initialBillingFilterState = {
  month: {
    label: format(today, 'MMMM'),
    value: getMonth(today) + 1,
  },
  year: {
    label: format(today, 'yyyy'),
    value: getYear(today),
  },
};

export default function billing(state = initialBillingFilterState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_MONTH_FILTER:
      return {
        ...state,
        month: action.payload,
      };

    case actionTypes.UPDATE_YEAR_FILTER:
      let month = state.month;
      if (action.payload.value === state.billingReleasedYear && month.value < state.billingReleasedMonth) {
        month = {
          label: format(state.billingReleasedDate, 'MMMM'),
          value: getMonth(state.billingReleasedDate) + 1,
        };
      } else if (action.payload.value === getYear(today) && month.value > getMonth(today) + 1) {
        month = {
          label: format(today, 'MMMM'),
          value: getMonth(today) + 1,
        };
      }
      return {
        ...state,
        year: action.payload,
        month,
      };

    case authTypes.LOAD_USER_SUCCESS:
    case authTypes.USER_UPDATE_SUCCESS:
      const billingReleasedDate = new Date(getBillingReleasedDate(action.response));
      const billingReleasedMonth = getMonth(billingReleasedDate) + 1;
      const currentMonth = getMonth(today) + 1;
      let monthValue = padZeroForMonth(currentMonth - 1 < billingReleasedMonth ? currentMonth : currentMonth - 1);

      return {
        ...state,
        billingReleasedDate,
        billingReleasedMonth: billingReleasedMonth,
        billingReleasedYear: getYear(billingReleasedDate),
        year: {
          label: format(today, 'yyyy'),
          value: getYear(today),
        },
        month: {
          label: format(new Date(`2000-${monthValue}-01`), 'MMMM'),
          value: currentMonth,
        },
      };

    default:
      return state;
  }
}
