import React from "react";
import { connect } from "react-redux";
import SimpleDialog from "./SimpleDialog";
import { closeConfirmationDialog } from "../redux/confirmationDialog/actions";

function AppConfirmationDialog({
  confirmationDialog,
  closeConfirmationDialog,
}) {
  const onSave = React.useCallback(() => {
    confirmationDialog.callback();
    closeConfirmationDialog();
    // eslint-disable-next-line
  }, [confirmationDialog.callback]);

  return (
    <SimpleDialog
      title={confirmationDialog.title}
      open={confirmationDialog.open}
      onClose={closeConfirmationDialog}
      onSave={onSave}
      saveText="Yes"
      data-testid="confirmation-dialog"
    >
      {confirmationDialog.text}
    </SimpleDialog>
  );
}

const mapStateToProps = ({ confirmationDialog }) => ({ confirmationDialog });

const mapDispatchToProps = (dispatch) => ({
  closeConfirmationDialog: () => dispatch(closeConfirmationDialog()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppConfirmationDialog);
