import React from 'react';
import { SvgIcon } from '@mui/material';

function StorageAndFulfillment(props) {
  return (
    <SvgIcon {...props}>
      {/* <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path d="M5.3335 28V12L16.0002 4L26.6668 12V28H22.6668V14.6667H9.3335V28H5.3335ZM11.3335 26H20.6668V22.3333H11.3335V26ZM11.3335 20.3333H20.6668V16.6667H11.3335V20.3333Z" />
      {/* </svg> */}
    </SvgIcon>
  );
}
export default StorageAndFulfillment;
