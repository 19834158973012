import React from 'react';
import { SvgIcon } from '@mui/material';

function ReportSVG(props) {
  return (
    <SvgIcon {...props}>
      {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path d="M4.375 21.6251V3.3501H14.1L14.5833 5.5001H20.65V16.0251H13.075L12.6 13.9001H6.725V21.6251H4.375Z" />
      {/* </svg> */}
    </SvgIcon>
  );
}
export default ReportSVG;
