import * as Yup from 'yup';
import { AREAS_1_URL, AREAS_2_URL, STATES_URL } from '../../../api/api';

const tncAvailableCountry = ['philippines'];

export const tncEnableCountry = {
  PH: 'SHIPPING_PH',
  SG: 'SHIPPING_SG',
};

const otherFieldsRequiredMessage = 'This field is required';

export const validationSchema = Yup.object({
  location: Yup.string().required('This field is required'),
  warehouse: Yup.number().optional(),
  address_line1: Yup.string().when('warehouse', (wareHouse, schema) =>
    wareHouse ? schema.optional() : schema.required(otherFieldsRequiredMessage)
  ),
  phone: Yup.string().when('warehouse', (wareHouse, schema) =>
    wareHouse ? schema.optional() : schema.required(otherFieldsRequiredMessage)
  ),
  zipcode: Yup.string().when('warehouse', (wareHouse, schema) =>
    wareHouse ? schema.optional() : schema.required(otherFieldsRequiredMessage)
  ),
  city: Yup.string().when('warehouse', (wareHouse, schema) =>
    wareHouse ? schema.optional() : schema.required(otherFieldsRequiredMessage)
  ),
  country: Yup.string().when('warehouse', (wareHouse, schema) =>
    wareHouse ? schema.optional() : schema.required(otherFieldsRequiredMessage)
  ),
  state: Yup.string().when('warehouse', (wareHouse, schema) =>
    wareHouse ? schema.optional() : schema.required(otherFieldsRequiredMessage)
  ),
  subdivision: Yup.string().when('warehouse', (wareHouse, schema) =>
    wareHouse ? schema.optional() : schema.required(otherFieldsRequiredMessage)
  ),
}).test('wareHouseOrFields', 'Either a warehouse must be selected or create a new location', function (value) {
  if (
    value.warehouse ||
    (value.address_line1 &&
      value.phone &&
      value.zipcode &&
      value.city &&
      value.country &&
      value.state &&
      value.subdivision)
  ) {
    return true;
  }
  return this.createError({
    path: 'warehouse', // Specify the path of the field to which the error should be attached
    message: 'Either a warehouse must be selected or create a new location',
  });
});

export const initialValue = {
  location: '',
  address_line1: '',
  warehouse: '',
  phone: '',
  zipcode: '',
  country: 1,
  state: '',
  subdivision: '',
  city: '',
};

export const getStatesUrl = (countryId) => STATES_URL.replace('country_id', countryId);
export const getCityUrl = (stateId) => AREAS_1_URL.replace('state_id', stateId);
export const getSubdivisonUrl = (area1Id) => AREAS_2_URL.replace('area1_id', area1Id);

export const isTncCountry = (countryId, countries) => {
  const country = countries.find((c) => c.id === countryId);
  if (country) {
    return tncAvailableCountry.includes(country.country_name.toLowerCase());
  }
  return false;
};

export const isTncEnabledForCountry = (countryId, countries) => {
  const { country_code } = countries.find((c) => c.id === countryId);
  if (country_code) {
    return tncEnableCountry[country_code];
  }
  return undefined;
};

export const isOperableArray = (obj) => obj && Array.isArray(obj) && obj.length > 0;
export const isEmptyArray = (obj) => obj && Array.isArray(obj) && obj.length === 0;

export const createStoreDataMapper = (onboardingRequestId, values, address, countries, states, cities, subDivs) => {
  const { location, address_line1, phone, zipcode, country, state, subdivision, city, warehouse } = values;
  let locationCode = '';

  const baseModel = {
    onboarding_request_id: onboardingRequestId,
    address_id: '',
    mapping: {
      location_name: location,
      location_detail: {
        address1: address_line1,
        address2: '',
        mapped_country_id: country,
        mapped_state_id: '',
        mapped_administrative_area1_id: '',
        mapped_administrative_area2_id: '',
        phone: phone,
        city: city,
        state: state,
        country: country,
        barangay: subdivision,
        zipcode: zipcode,
      },
    },
  };

  if (typeof location === 'number' && isOperableArray(address)) {
    baseModel.address_id = address[location]?.address_id || '';
    baseModel.mapping.location_name = address[location]?.name;
    locationCode = address[location].location_code;
  }
  if (typeof country === 'number') {
    baseModel.mapping.location_detail.mapped_country_id = country;
    baseModel.mapping.location_detail.country = countries.find((c) => c.id === country).country_code;
  }
  if (typeof state === 'number') {
    baseModel.mapping.location_detail.mapped_state_id = state;
    baseModel.mapping.location_detail.state = states.find((s) => s.id === state).name;
  }
  if (typeof city === 'number') {
    baseModel.mapping.location_detail.mapped_administrative_area1_id = city;
    baseModel.mapping.location_detail.city = cities.find((c) => c.id === city).name;
  }
  if (typeof subdivision === 'number') {
    baseModel.mapping.location_detail.mapped_administrative_area2_id = subdivision;
    baseModel.mapping.location_detail.barangay = subDivs.find((sdv) => sdv.id === subdivision).name;
  }

  return { value: baseModel, location_code: locationCode, warehouse };
};
