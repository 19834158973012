import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './MainApp';

import { Chart, registerables } from 'chart.js';
import axios from 'axios';
import { addTokenToRequest } from './hoc/apiInterceptor';
import './index.css';

//register used chart elements
Chart.register(...registerables);

// Add a request interceptor
axios.interceptors.request.use(addTokenToRequest, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// const app= process.env.REACT_APP_TYPE // two accepted values are partner-hub, fulfilment

ReactDOM.render(<App />, document.getElementById('root'));
