export const notifications = {
  ERROR: {
    title: 'Error',
    something_wrong: 'Something went wrong. Please, try again later.',
    password: 'Password does not match!',
    error_occured: 'Error Occured!',
    MAIL_TEXT:
      'An error has occurred. Please try again later. If it persists, kindly send an email to help@golocad.com for assistance.',
    allow_popups: 'You need to allow our app to open popups.',
    access_key: 'Error with Access Key/Secret Key combination. Please check your credential.',
    client_ID: 'Error with Client ID/Secret/Connection Name combination. Please check your credential.',
    api_token: 'Error with API Token. Please check your credentials.',
    report_submit: 'Error Submitting Report - ',
    select_doc: 'Please select a document type or upload a document.',
    fetch_warehouses: 'Failed to fetch warehouses.',
    no_warehouse_inbound: 'No connected warehouses for inbound',
    warehouse_pullout_b2b: 'No connected warehouses for pullout & B2B',
    auth_token_credentials: 'Unable to authorise with Token. Please check your credentials.',
    conn_id_team: 'Error with Connection Id. Please check with Tech Team.',
    vendor_code_uniware: 'Wrong Vendor code. Please check if this vendor code exists in Uniware.',
    del_profile: 'Error Deleting Profile',
    del_zone: 'Error Deleting Zone',
    updating_profile: 'Error Updating Profile',
    packaging_rate: 'Something went wrong!',
    marketplace_name_update: 'Error updating marketplace name.',
  },
  SUCCESS: {
    title: 'Success!',
    copied: 'Copied',
    user_created: 'User Created!',
    store_created: 'Store Created',
    acc_linked: 'Account linked successfully',
    assignment: 'Assignment Success!',
    settings_saved: 'Settings Saved!',
    updated_bills: 'Updated bills will be reflected in sometime.',
    rate_saved: 'Rate has been saved. Now you can close this page.',
    rates_saved: 'Rates Saved',
    awb_fetched: 'All Airwaybills has been fetched successfully.',
    pod_fetched: 'All POD has been fetched successfully.',
    address_changed: 'Address was successfully changed',
    text_copied: 'Text copied.',
    report_submitted: 'Report Submitted Successfully.',
    report_sent_shortly: 'Report will be sent to your email shortly.',
    bulk_upload: 'Bulk was successfully uploaded.',
    product_update: 'Product has been updated.',
    product_created: 'Product was successfully created.',
    pickup_location: 'Pickup location was successfully created.',
    pickup_location_updated: 'Pickup Location was successfully updated.',
    mappings: 'Mappings Saved',
    go_live: 'Go-live was successful!',
    shipping_profile_changed: 'Shipping Profile Changed Successfully',
    password_changed: 'Your password has been changed successfully.',
    profile_updated: 'Your profile has been updated',
    def_operating_hrs: 'Default Operating Hours Saved!',
    custom_operating_hrs: 'Custom Operating Hours Saved!',
    file_uploaded: 'File is successfully uploaded.',
    pullout_uploaded: 'Pullout file was successfully uploaded.',
    packaging_rate: 'Rates Updated successfully',
    shipment_types: 'Shipment types updates successfully',
    marketplace_name_update: 'Marketplace name updated.',
  },
  INFO: {
    title: 'Info',
    draft_order: 'Draft Order was updated',
    shipment_added_next: 'Shipment has been added successfully. Next, select and book your shipment.',
    shipment_saved_draft: 'Shipment Order was saved as a draft.',
    shipment_updated: 'Shipment Order is updated successfully',
    no_avail_carr: 'No more available carrier connections.',
    re_shipped: 'Re Shipped Successfully',
  },
  WARNING: {
    title: 'Warning',
    accept_tnc: 'You must accept the Terms & Conditions to proceed with Setup',
    forecast_data: 'No Forecast Data Found for the Period',
    carrier_conn: 'No valid carrier connections.',
    fill_fields: 'Please, fill all required fields.',
    config_change: 'Some configuration has been changed, please contact support',
  },
};
