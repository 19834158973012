export const SHOW_WELCOME_MESSAGE = 'SHOW_WELCOME_MESSAGE';
export const START = 'START';
export const NEXT_OR_PREV = 'NEXT_OR_PREV';
export const RESET = 'RESET';
export const RESTART = 'RESTART';
export const STOP = 'STOP';
export const TERMINATE = 'TERMINATE';
export const START_PARTIAL_ONBOARDING = 'START_PARTIAL_ONBOARDING';
export const MARK_PARTIAL_TOUR_VISITED = 'MARK_PARTIAL_TOUR_VISITED';
export const UPDATE_STEP_STATUS = 'UPDATE_STEP_STATUS';
export const UPDATE_ROUTE_INDEX = 'UPDATE_ROUTE_INDEX';
