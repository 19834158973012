import { useEffect } from 'react';
import { useAddQuery, useQuery } from './useQuery';
import { useSelector } from 'react-redux';
import { PERMISSIONS } from '../Auth/Constants/constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export const calculateRowsPerPage = ({ offset, page }) => {
  return offset / page;
};
export const getQueryParamsPagination = () => {
  const search = new URLSearchParams(window.location.search);

  const data = {
    page: search?.get('page') ? Number(search.get('page')) - 1 : 0,
    offset: search?.get('offset') ? Number(search.get('offset')) : 0,
  };

  return data;
};

const usePreservePagination = ({ pageData, activeFilters }) => {
  const { page, offset } = pageData;
  const permissions = useSelector((state) => state?.auth?.user?.permissions);

  const preservePagination = permissions.includes(PERMISSIONS.FEATURE_PRESERVE_TABLE_PAGINATION_MP);
  const history = useHistory();
  const rowsPerPage = calculateRowsPerPage({ offset, page });

  const search = useQuery();
  //eslint-disable-next-line
  const [_, setMultipleQuery] = useAddQuery();

  useEffect(() => {
    if (preservePagination) {
      setMultipleQuery({
        ...search,
        page: page + 1,
        offset,
      });
    }
    if (!preservePagination) {
      const queryParams = new URLSearchParams(search);
      queryParams.delete('page');
      queryParams.delete('offset');
      history.replace({
        search: queryParams.toString(),
      });
    }
    //eslint-disable-next-line
  }, [page, rowsPerPage, activeFilters, search]);
};

export default usePreservePagination;
