import { GET_UNITS, GET_UNITS_FAIL, GET_UNITS_SUCCESS } from './actionTypes';
import { createOptions } from '../../helpers/createOptions';
import { STORAGE_TYPE_OPTIONS } from '../../Inventory/Constants';

const initialState = {
  countries: [],
  currencies: [],
  weight_units: [],
  dimension_units: [],
  status: [
    { label: 'Active', value: true },
    { label: 'Archived', value: false },
  ],
  fragile: [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ],
  is_split_enabled: [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ],
  serial_number_enabled: [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ],
  expiry_managed_enabled: [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ],
  batch_managed_enabled: [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ],
  sell_by: [
    { label: '10', value: 10 },
    { label: '30', value: 30 },
    { label: '60', value: 60 },
    { label: '90', value: 90 },
    { label: '120', value: 120 },
  ],
  storage_type: STORAGE_TYPE_OPTIONS,
  isLoading: false,
  error: null,
};

export default function units(state = initialState, action) {
  switch (action.type) {
    case GET_UNITS:
      return { ...state, isLoading: true };
    case GET_UNITS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currencies: createOptions(action.response.currencies, 'currencies'),
        weight_units: createOptions(action.response.weight_units),
        dimension_units: createOptions(action.response.dimension_units),
        countries: action.response.countries,
      };

    case GET_UNITS_FAIL:
      return {
        ...state,
        isLoading: false,
        currencies: [],
        weight_units: [],
        dimension_units: [],
        error: action.error.data,
      };
    default:
      return state;
  }
}
