import { add, sub, endOfMonth, startOfMonth, startOfYear, lastDayOfYear, getMonth, getYear, isBefore } from 'date-fns';

export const getYearFromDateString = (date) => new Date(date).getFullYear();
export const getMonthFromDateString = (date) => new Date(date).getMonth();
export const getTimeFromDateString = (date) => new Date(date).getTime();
export const yearFilter = (year, timeKey) => (item) => getYearFromDateString(item[timeKey]) === year;
export const monthFilter = (month, timeKey) => (item) => getMonthFromDateString(item[timeKey]) === month;
export const timeFilter = (time, timeKey) => (item) => getTimeFromDateString(item[timeKey]) === time;

/**
 * Last X months [X = 1.2.3 ...anyNumber]
 * For Previous Month , we skip the current month
 * Calculate Previous 3 month
 * example: If current Date is Nov 30,2022 ,
 * For Previous 3 month
 * Start Date : Aug 01, 2022
 * End Date: Oct 31, 2022
 */
export const getLastXMonths = (x) => {
  const start = startOfMonth(sub(new Date(), { months: x }));
  const end = endOfMonth(sub(new Date(), { months: 1 }));
  return { start, end };
};
/**
 * Next X months [X = 1.2.3 ...anyNumber]
 * For Next Months , we consider the current month
 * To Calculate Next 3 month
 * example: If current Date is Nov 30,2022 ,
 * For Next 3 month
 * Start Date : Nov 01, 2022
 * End Date: Jan 31, 2023
 */
export const getNextXMonths = (x) => {
  const end = endOfMonth(add(new Date(), { months: x > 0 ? x - 1 : x }));
  const start = startOfMonth(new Date());
  return { start, end };
};

export const getThisMOnth = () => {
  return {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  };
};

export function getCurrentDatetime() {
  return new Date();
}

export function getTodayStart() {
  let date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}
export function getTodayEnd() {
  let date = new Date();
  date.setHours(23, 59, 0, 0);
  return date;
}
export function getYesterdayStart() {
  let date = new Date();
  date.setDate(date.getDate() - 1);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function get7DaysStart() {
  let date = new Date();
  date.setDate(date.getDate() - 6);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function get14DaysStart() {
  let date = new Date();
  date.setDate(date.getDate() - 13); // Subtract 13 to get 14 days ago
  date.setHours(0, 0, 0, 0);
  return date;
}

export function get30DaysStart() {
  let date = new Date();
  date.setDate(date.getDate() - 29);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function get60DaysStart() {
  let date = new Date();
  date.setDate(date.getDate() - 59);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function getThisMonthStart() {
  let date = new Date();
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function getLastMonthStart() {
  let date = new Date();
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);
  date.setHours(0, 0, 0, 0);
  return date;
}

export const getLastMonthEnd = () => {};

export function getThisYearStart() {
  let date = new Date();
  date.setDate(1);
  date.setMonth(0);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function getLastYearStart() {
  let date = new Date();
  date.setDate(1);
  date.setMonth(0);
  date.setFullYear(date.getFullYear() - 1);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function getBeginningOfTime() {
  let date = new Date();
  date.setDate(1);
  date.setMonth(0);
  date.setFullYear(1970);
  date.setHours(0, 0, 0, 0);
  return date;
}

export const normalizeDateToTimezone = (date) =>
  date
    ? new Date(date).toLocaleDateString('default', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    : '-';

export const normalizeDateOnlyToTimezone = (date) =>
  date
    ? new Date(date).toLocaleDateString('default', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })
    : '-';
export const normalizeToTimeOnlyToTimezone = (date) =>
  date
    ? new Date(date).toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    : '-';
export const normalizeDateRange = (date, type, toLocal) => {
  let newDate = new Date(date);
  if (type === 'start') {
    newDate.setHours(0, 0, 0, 0);
  } else {
    newDate.setHours(0, 0, 0, 0);
    if (!toLocal) {
      newDate.setDate(newDate.getDate() + 1);
    }
  }
  if (toLocal) {
    return newDate.toLocaleDateString();
  }
  return newDate.toISOString();
};

export const getDefaultDateRangeFilters = () => [
  { value: 'Today', start: getTodayStart(), end: getCurrentDatetime() },
  { value: 'Yesterday', start: getYesterdayStart(), end: getTodayStart() },
  { value: 'Last 7 Days', start: get7DaysStart(), end: getCurrentDatetime() },
  { value: 'Last 30 Days', start: get30DaysStart(), end: getCurrentDatetime() },
  { value: 'Last 60 Days', start: get60DaysStart(), end: getCurrentDatetime() },
  {
    value: 'This Month',
    start: getThisMonthStart(),
    end: getCurrentDatetime(),
  },
  { value: 'Last Month', start: getLastMonthStart(), end: getThisMonthStart() },
  { value: 'This Year', start: getThisYearStart(), end: getCurrentDatetime() },
  { value: 'Last Year', start: getLastYearStart(), end: getThisYearStart() },
  { value: 'All Time', start: getBeginningOfTime(), end: getCurrentDatetime() },
];

export const orderForeCastDateMenu = [
  {
    label: 'This Month',
    value: getThisMOnth(),
  },
  {
    label: 'Next 3 Months',
    value: getNextXMonths(3),
  },
  {
    label: 'This Year',
    value: {
      start: startOfYear(new Date()),
      end: lastDayOfYear(new Date()),
    },
  },
  {
    label: 'Last Month',
    value: getLastXMonths(1),
  },
  {
    label: 'Last 3 Months',
    value: getLastXMonths(3),
  },
  {
    label: 'Last Year',
    value: {
      start: startOfYear(sub(new Date(), { years: 1 })),
      end: lastDayOfYear(sub(new Date(), { years: 1 })),
    },
  },
];

export const getPrevMonthWithYear = (date = new Date()) => {
  const previousMonth = sub(date, { months: 1 });
  return {
    month: getMonth(previousMonth) + 1,
    year: getYear(previousMonth),
  };
};
export const getNextMonthWithYear = (date = new Date()) => {
  const nextMonth = add(date, { months: 1 });
  return {
    month: getMonth(nextMonth) + 1,
    year: getYear(nextMonth),
  };
};
export const getCurrentMonthWithYear = (date = new Date()) => {
  return {
    month: getMonth(date) + 1,
    year: getYear(date),
  };
};

// Check if date1 is before date2
export const checkIfDateIsPrevious = (date1, date2) => {
  return isBefore(date1, date2);
};

// filter by date range filters
export const byActiveDateFilter =
  ({ start: startTime, end: endTime }, timeKey = 'time_bucket') =>
  (item) => {
    const time = Date.parse(item[timeKey]);
    return time >= Date.parse(startTime) && time < Date.parse(endTime);
  };
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const padZeroForMonth = (n) => {
  return n < 10 ? '0' + n : n;
};
