import React from 'react';
import { Grid as MUIGrid, Box } from '@mui/material';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import ImageCarousel from './ImageCarousel';

const Grid = MUIGrid;
const GridItem = styled(MUIGrid)`
  height: 100vh;
`;
const GradientBox = styled(Box)`
  background: ${(props) => props.theme.palette.primary.main};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const ProgressBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  position: absolute;
  top: 0px;
`;
const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  height: 80%;
`;

const Layout = ({ content, progressbar }) => {
  return (
    <Grid container>
      <GridItem xs={6} item>
        <GradientBox>
          <ProgressBox>
            {progressbar && (
              <Grid xs={12} md={12} xl={12} item>
                {progressbar}
              </Grid>
            )}
          </ProgressBox>
          <FormBox>{content}</FormBox>
        </GradientBox>
      </GridItem>
      <GridItem item xs={6}>
        <ImageCarousel />
      </GridItem>
    </Grid>
  );
};

Layout.propTypes = {
  content: PropTypes.node,
};

export default Layout;
