import { useEffect } from 'react';

export const useLocalStorageListner = (lookUpkey, onStorageChange, mode = 'local') => {
  useEffect(() => {
    const handler = (ev) => {
      const { key, newValue, oldValue } = ev;
      if (key === lookUpkey && oldValue && oldValue !== newValue) {
        console.log(`${lookUpkey} change detected, running hanlder`);
        onStorageChange({ new: newValue, old: oldValue });
      }
    };
    window.addEventListener('storage', handler);

    return () => {
      window.removeEventListener('storage', handler);
    };
  }, [lookUpkey, onStorageChange]);
};
