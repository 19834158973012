/* eslint-disable no-unused-vars */
import { blue, green, grey, indigo, red, teal } from '@mui/material/colors';

// const inverseLocadLogoPath = "/static/img/logos/logo-inverse.png";
// const usualLocadLogoPath = "/static/img/logos/logo.png";
const inverseLocadLogoPath = '/static/img/logos/brand_logo.svg';
const usualLocadLogoPath = '/static/img/logos/brand_logo.svg';
const blackEtailyLogoPath = '/static/img/logos/etaily-black.png';
const whiteEtailyLogoPath = '/static/img/logos/etaily-white.png';
const imagePlaceholder = '/static/img/placeholder.png';
const locadMiniLogoPath = '/static/img/logos/locad_grey_mini.svg';
const partnerhubMiniLogoPath = '/static/img/logos/logo_partnerhub_mini_grey.svg';

const locadNewBrandingVariant = {
  name: 'Locad',
  palette: {
    // Keeping the default as primary as it's causing issues at a lot of placess
    default: {
      main: '#060A06',
      light: '#3D3D3D',
      dark: '#060A06',
      contrastText: '#FFF',
    },
    primary: {
      main: '#060A06',
      light: '#3D3D3D',
      dark: '#060A06',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#50D860',
      light: '#C8FFCF',
      dark: '#94FFA1',
      contrastText: '#1A1A1A',
    },
    info: {
      main: '#847BFF',
      light: '#C2BDFF',
      dark: '#5A50E2',
      contrastText: '#FFF',
    },
    warning: {
      main: '#F7A12C',
      light: '#FFC672',
      dark: '#FF8202',
      contrastText: '#FFF',
    },
    success: {
      main: '#50D860',
      light: '#C8FFCF',
      dark: '#94FFA1',
      contrastText: '#FFF',
    },
    error: {
      main: '#FC4F59',
      light: '#FF8884',
      dark: '#EE212E',
      contrastText: '#FFF',
    },
    disabled: {
      main: '#A4AFAF',
      light: '#CFDCDC',
      dark: '#6D7474',
      contrastText: '#F0F6F4',
    },
    background: {
      main: '#CFDCDC',
      light: '#F0F6F4',
      dark: '#A4AFAF',
      white: '#E5E5E5',
      // one more for background ECF1F1
    },
    pieChart: {
      color1: '#847BFF',
      color2: '#FF875C',
      color3: '#82FFF6',
      color4: '#50D860',
      color5: '#FC4F59',
      color6: '#A4AFAF',
      color7: '#3D3D3D',
      color8: '#5A50E2',
      color9: '#FFAC8E',
      color10: '#04E4D4',
      color11: '#EA6638',
      color12: '#C2BDFF',
      color13: '#94FFA1',
    },
    barChart: {
      color1: '#847BFF',
      color2: '#FF875C',
      color3: '#82FFF6',
      color4: '#50D860',
      color5: '#FC4F59',
      color6: '#A4AFAF',
      color7: '#3D3D3D',
      color8: '#5A50E2',
      color9: '#FFAC8E',
      color10: '#04E4D4',
      color11: '#EA6638',
      color12: '#C2BDFF',
      color13: '#94FFA1',
      color14: '#EE212E ',
    },
  },
  header: {
    color: '#FFFFFF',
    background: '#060A06',
    search: {
      color: '#FFFFFF',
    },
    indicator: {
      background: '#2D0065',
    },
  },
  sidebar: {
    color: '#FFFFFF',
    background: '#060A06',
    header: {
      color: '#FFFFFF',
      background: '#060A06',
      brand: {
        color: '#62408C',
      },
    },
    footer: {
      color: '#FFFFFF',
      background: '#1A1A1A',
      online: {
        background: '#42D48B',
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  body: {
    background: '#FFF',
    logos: {
      white: inverseLocadLogoPath,
      dark: usualLocadLogoPath,
      darkMini: locadMiniLogoPath,
    },
    imagePlaceholder,
    login: {
      btnText: '#2C0065',
      layoutBg: '#1A1A1A',
    },
  },
};

const etailyVariant = {
  name: 'Etaily',
  palette: {
    default: {
      main: '#14243C',
      contrastText: '#FFF',
    },
    primary: {
      main: '#14243C',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#14243C',
      contrastText: '#FFF',
    },
    info: {
      main: '#2C4456',
      contrastText: '#FFF',
    },
    warning: {
      main: '#F7A12C',
      light: '#FFC672',
      dark: '#FF8202',
      contrastText: '#FFF',
    },
    success: {
      main: '#42D48B',
      light: '#8DE3B8',
      dark: '#00B55B',
      contrastText: '#FFF',
    },
    error: {
      main: '#ED3D40',
      light: '#FF857C',
      dark: '#CB1B1B',
      contrastText: '#FFF',
    },
    disabled: {
      main: '#A4AFAF',
      light: '#CFDCDC',
      dark: '#6D7474',
      contrastText: '#F0F6F4',
    },
    background: {
      main: '#CFDCDC',
      light: '#F0F6F4',
      dark: '#A4AFAF',
      white: '#E5E5E5',
      // one more for background ECF1F1
    },
    pieChart: {
      color1: '#847BFF',
      color2: '#FF875C',
      color3: '#82FFF6',
      color4: '#50D860',
      color5: '#FC4F59',
      color6: '#A4AFAF',
      color7: '#3D3D3D',
      color8: '#5A50E2',
      color9: '#FFAC8E',
      color10: '#04E4D4',
      color11: '#EA6638',
      color12: '#C2BDFF',
      color13: '#94FFA1',
    },
    barChart: {
      color1: '#847BFF',
      color2: '#FF875C',
      color3: '#82FFF6',
      color4: '#50D860',
      color5: '#FC4F59',
      color6: '#A4AFAF',
      color7: '#3D3D3D',
      color8: '#5A50E2',
      color9: '#FFAC8E',
      color10: '#04E4D4',
      color11: '#EA6638',
      color12: '#C2BDFF',
      color13: '#94FFA1',
    },
  },
  header: {
    color: '#FFFFFF',
    background: '#2C4456',
    search: {
      color: '#FFFFFF',
    },
    indicator: {
      background: '#000000',
    },
  },
  sidebar: {
    color: '#FFFFFF',
    background: '#14243C',
    header: {
      color: '#FFFFFF',
      background: '#14243C',
      brand: {
        color: '#14243C',
      },
    },
    footer: {
      color: '#FFFFFF',
      background: '#14243C',
      online: {
        background: '#14243C',
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
  body: {
    background: '#F7F7FC',
    logos: {
      white: whiteEtailyLogoPath,
      dark: whiteEtailyLogoPath,
      darkMini: whiteEtailyLogoPath,
    },
    imagePlaceholder,
  },
};

const variants = [locadNewBrandingVariant, etailyVariant];

export default variants;
