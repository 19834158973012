import React from "react";

// returns false if component was unmounted

export const useMounted = () => {
  const mounted = React.useRef(true);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted.current;
};
