import { Tooltip, Typography, Box, useTheme } from '@mui/material';
import { Home } from '@mui/icons-material';
import React from 'react';
import { Category } from './dashboardcss';

const SidebarCategory = ({ name, icon = <Home />, ...rest }) => {
  const theme = useTheme();

  const message = (
    <Typography variant="body2" style={{ borderRadius: '5px', color: theme.palette.secondary.contrastText }}>
      {name}
    </Typography>
  );
  return (
    <Tooltip
      enterTouchDelay={0}
      placement="top-end"
      title={message}
      slotProps={{
        tooltip: {
          sx: {
            bgcolor: theme.palette.secondary.main + ' !important',
            marginLeft: '4rem !important',
            marginBottom: '-2.5rem !important',
          },
        },
      }}
    >
      <Category {...rest}>
        <Box activeclassname="active" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {icon}
        </Box>
      </Category>
    </Tooltip>
  );
};
export default SidebarCategory;
