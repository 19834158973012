/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_TOKEN_URL } from '../../api/api';
import { useAxios } from '../../hooks/useAxios';
import { useQuery } from '../../hooks/useQuery';
import { paths } from '../../routes/constants';
import { showNotification } from '../../redux/notifications/actions';
import { logout } from '../../redux/auth/actions';
import { LoginFailMessaages } from '../Constants/constants';

const OAUTH_STATE_KEY = 'cognito-state';
const NEXT_URL = 'next-url';

const saveToken = ({ access, refresh }) => {
  window.localStorage.setItem('token', access);
  window.localStorage.setItem('refresh', refresh);
};

const generateStateRandomString = () => {
  const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let array = new Uint8Array(40);
  window.crypto.getRandomValues(array);
  array = array.map((x) => validChars.codePointAt(x % validChars.length));
  const randomState = String.fromCharCode.apply(null, array);
  return randomState;
};

const saveState = (state) => {
  window.localStorage.setItem(OAUTH_STATE_KEY, state);
};

const removeState = () => {
  window.localStorage.removeItem(OAUTH_STATE_KEY);
};

const saveNextUrl = (nextUrl) => {
  window.localStorage.setItem(NEXT_URL, nextUrl);
};
const getNextUrl = () => window.localStorage.getItem(NEXT_URL);
const removeNextURL = () => window.localStorage.removeItem(NEXT_URL);

const checkState = (stateKey) => {
  const savedKey = window.localStorage.getItem(OAUTH_STATE_KEY);
  return savedKey === stateKey;
};

const cleanUp = () => {
  removeState();
  removeNextURL();
};

const prepareAuthenticationUrl = (loginUrl, clientId, redirectUrl, state) => {
  return `${loginUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&state=${state}`;
};

const openLogin = (url) => window.location.replace(url);

function useCogntio({ authorizationUrl, clientId, redirectUrl, signUpUrl, enableMigaration = false }) {
  // const counter = useRef(0)
  const user = useSelector((state) => state.auth.user);
  const [nextUrl, setNextUrl] = useState(() => getNextUrl());
  const [tokens, setTokens] = useState();
  const dispatch = useDispatch();

  const { code, state, next } = useQuery();
  const {
    callAxios: getTokens,
    data: tokenResponse,
    error: tokenError,
  } = useAxios({ callOnMount: false, showError: true });

  useEffect(() => {
    if (next) {
      setNextUrl(next);
      saveNextUrl(next);
    }
  }, [next]);

  useEffect(() => {
    /**
     * Improved Login Flow handling login from multiple tab
     */
    /**
     * Improved Login Flow handling login from multiple tab
     */

    /**
     * DO NOT USE
     * do not use generic else block for any action,
     * else block will be fired during initial login when no code/state or tokens will be there
     * use specific else if or if block to handle scenarios
     */
    console.log('Starting User Authentication Flow');
    if (user) {
      console.log('USER-FOUND: Found User in the process', user);
      if (enableMigaration) {
        console.log('USER-FOUND: Migration Flag Enabled for the user', user);
        if (code && state && checkState(state) && !tokens) {
          console.log('USER-FOUND: Setting code', code);
          setTokens(code);
        } else if (state && !checkState(state)) {
          dispatch(showNotification('Data mismatch found. Redirecting to home.', 'warning'));
          setTimeout(() => (window.location.href = '/'), 2000);
        }
      } else {
        if (code && state && !tokens) {
          console.log('USER-FOUND: Calling Authorization Endpoint');
          const url = `${AUTH_TOKEN_URL}?cognito_code=${code}&redirect_uri=${redirectUrl}&cognito_client_id=${clientId}`;
          getTokens({ url });
        }
      }
    } else {
      console.log('USER-NOTFOUND: No existing User');
      if (code && state && checkState(state) && !tokens) {
        console.log('USER-FOUND: Calling Authorization Endpoint');
        const url = `${AUTH_TOKEN_URL}?cognito_code=${code}&redirect_uri=${redirectUrl}&cognito_client_id=${clientId}`;
        getTokens({ url });
      } else if (state && !checkState(state)) {
        dispatch(showNotification(LoginFailMessaages.whenStateDoesntMatch, 'warning'));
        setTimeout(() => dispatch(logout()), 2000);
      }
    }
    /** ------- */
    /* 
        if (code && state && checkState(state) && !tokens) {
          if (!user && !enableMigaration) { // this one for new login in single tab
            const url = `${AUTH_TOKEN_URL}?cognito_code=${code}&redirect_uri=${redirectUrl}&cognito_client_id=${clientId}`;
            getTokens({ url });
        } else if (user && enableMigaration) { // this one for migration
          setTokens(code);
        } else if (user && !enableMigaration) { // this one for new login in multiple tabs
          const url = `${AUTH_TOKEN_URL}?cognito_code=${code}&redirect_uri=${redirectUrl}&cognito_client_id=${clientId}`;
          getTokens({ url });
        }
      } 
      */
  }, [code, state, enableMigaration]);

  useEffect(() => {
    if (tokenResponse) {
      setTokens(tokenResponse);
    }
  }, [tokenResponse]);

  useEffect(() => {
    return () => {
      if (tokens) {
        cleanUp();
      }
    };
  }, []);

  const doLoging = (options = {}) => {
    const stateKey = options.state || generateStateRandomString();
    const url = prepareAuthenticationUrl(
      authorizationUrl,
      clientId,
      options.redirectUrl || redirectUrl,
      stateKey,
      options
    );
    console.log('redirect url', url);
    saveState(stateKey);
    openLogin(url);
  };

  const doSignUp = (options = {}) => {
    const stateKey = options.state || generateStateRandomString();
    const url = prepareAuthenticationUrl(signUpUrl, clientId, redirectUrl, stateKey, options);
    console.log('redirect url for Sign-up', url);
    saveState(stateKey);
    openLogin(url);
  };

  return [tokens, tokenError, nextUrl, state, doLoging, doSignUp, { saveToken }];
}

export const logOutCognito = ({ signOutUrl, cliendId }) => {
  const url = `${signOutUrl}?client_id=${cliendId}&&logout_uri=${`${window.location.origin}${paths.AUTH_LOGOUT}`}`;
  openLogin(url);
};

export default useCogntio;
