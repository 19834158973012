import axios from '../api/HttpService';
import { TOKEN_REFRESH_URL } from '../api/api';
import { paths } from '../routes/constants';

const shouldIntercept = (error) => {
  try {
    if (error.response.config.url === TOKEN_REFRESH_URL && error.response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
      window.location = '/';
    }
    return error.response.status === 401;
  } catch (e) {
    return false;
  }
};

const setTokenData = (tokenData = {}, axiosClient) => {
  localStorage.setItem('token', tokenData.accessToken);
};

const handleTokenRefresh = () => {
  try {
    const refreshToken = window.localStorage.getItem('refresh');
    if (!refreshToken) {
      window.location.replace(paths.AUTH_LOGIN);
    } else
      return new Promise((resolve, reject) => {
        axios
          .post(TOKEN_REFRESH_URL, { refresh: refreshToken })
          .then(({ data }) => {
            const tokenData = {
              accessToken: data.access,
            };
            resolve(tokenData);
          })
          .catch((err) => {
            window.location.replace(paths.AUTH_LOGIN);
          });
      });
  } catch (error) {
    window.location.replace(paths.AUTH_LOGIN);
  }
};

const attachTokenToRequest = (request, token) => {
  request.headers['Authorization'] = 'Token ' + token;
  // If there is an edge case where access token is also set in request query,
  // this is also a nice place to add it
  // Example: /orders?token=xyz-old-token
  // if (/\/orders/.test(request.url)) {
  //     request.params.token = token;
  // }
};
const axiosInterceptor = (axiosClient, customOptions = {}) => {
  let isRefreshing = false;
  let failedQueue = [];
  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions,
  };
  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const interceptor = (error) => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error);
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest._queued = true;
          options.attachTokenToRequest(originalRequest, token);
          return axiosClient.request(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then((tokenData) => {
          options.setTokenData(tokenData, axiosClient);
          options.attachTokenToRequest(originalRequest, tokenData.accessToken);
          processQueue(null, tokenData.accessToken);
          resolve(axiosClient.request(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };

  axiosClient.interceptors.response.use(undefined, interceptor);
};

export default axiosInterceptor;

export const addTokenToRequest = (config) => {
  // Do something before request is sent
  const token = localStorage.getItem('token');
  if (token && !config.headers.Authorization) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: 'Token ' + localStorage.getItem('token'),
      },
    };
  } else {
    return config;
  }
};
