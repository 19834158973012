export const INVENTORY = {
  title: 'Inventory',
  INVENTORY_DASHBOARD: {
    title: 'Inventory Dashboard',
  },
};

export const WALLET = {
  title: 'Wallet',
  subtitle: 'View current wallet balance and past transactions',
};

export const LOCAD_STORAGE_AND_FULFILLMENT = {
  title: 'Locad Storage & Fulfillment',
  subtitle: "Learn more about Locad's storage and fulfillment services!",
};

export const RETURNS = {
  title: 'Returnkey',
};

export const SALES_OVERVIEW = {
  title: 'Sales Overview',
};

export const SHIPPING = {
  title: 'Shipping',
};

export const PICKUP_LOCATIONS = {
  title: 'Pickup Locations',
};

export const HOME = {
  title: 'Home',
};

export const SUPPORT = {
  title: 'Support',
};
export const VOUCHER = {
  min_char_limit: 'Voucher should be at least 6 characters long.',
  invalid_voucher: 'Invalid Voucher Code!',
  valid_voucher: 'Valid Voucher Code!',
  credit_days: 'Your wallet will be credited within 7 days, if voucher code is valid.',
  select_currency: 'Please select default currency also, to validate voucher code.',
};
