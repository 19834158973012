import React from 'react';
import { Box, Chip, Grid, Tooltip, Typography } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { CheckCircle, FiberManualRecord, Info, WatchLater } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { normalizeDateOnlyToTimezone } from '../helpers/datetime';
import { getStatusColor } from '../helpers/other';

const useStyles = makeStyles(() => ({
  customWidth: {
    minWidth: 370,
  },
}));
export const pendingDelivery = 'PENDING_DELIVERY';
export const inProgress = 'IN_PROGRESS';
export const completed = 'COMPLETED';
export const notAvailable = 'NOT_AVAILABLE';
export const overdueSubstring = 'OVERDUE';
export const pendingSubstring = 'PENDING';
const invalidDate = 'Invalid Date';

const RemittanceChipWithTooltip = ({ remitObj = {}, theme }) => {
  const classes = useStyles();
  const getStatusIcon = (stat) => {
    if (stat) {
      if (stat.includes(overdueSubstring)) {
        return <Info fontSize="small" style={{ color: '#FF8884' }} />;
      } else if (stat.includes(pendingSubstring)) {
        return <WatchLater fontSize="small" style={{ color: '#FFAC8E' }} />;
      } else if (normalizeDateOnlyToTimezone(stat) !== invalidDate) {
        return <CheckCircle fontSize="small" color="secondary" />;
      } else {
        return '';
      }
    }
  };

  const remitTooltipStatus = (remitBy) => {
    const normalizedRemitByDate = normalizeDateOnlyToTimezone(remitBy);
    return normalizedRemitByDate === invalidDate ? remitBy : normalizedRemitByDate;
  };

  const { status, carrier_remit_days, locad_remit_days, remitted_by_carrier, remitted_by_locad, cod_live_date } =
    remitObj;

  const notAvailableTooltipDescription = () => {
    return (
      <Box style={{ padding: '.5rem', display: 'flex' }}>
        <Info style={{ transform: 'rotate(180deg)', color: '#C2BDFF' }} />
        <Box style={{ display: 'flex', marginBottom: '.2rem', alignItems: 'center' }}>
          <Typography style={{ marginLeft: '.5rem' }}>
            COD statuses are only available for orders delivered after {normalizeDateOnlyToTimezone(cod_live_date)}.
            <br></br>Older orders would be reported via email.
          </Typography>
        </Box>
      </Box>
    );
  };
  const pendingDeliveryTooltipDescription = () => {
    return (
      <Box style={{ padding: '.5rem' }}>
        <Box style={{ display: 'flex', marginBottom: '.2rem', alignItems: 'center' }}>
          <Info style={{ transform: 'rotate(180deg)', color: '#C2BDFF' }} />
          <Typography style={{ marginLeft: '.5rem' }}>This order has not been delivered yet.</Typography>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography style={{ fontSize: '13px', margin: '0.1rem 0' }}>
            * Carrier to remit within {carrier_remit_days} days of delivery
          </Typography>
          <Typography style={{ fontSize: '13px', margin: '0.1rem 0' }}>
            ** Locad to remit within {locad_remit_days} days of carrier remittance
          </Typography>
        </Box>
      </Box>
    );
  };
  const normalTooltipDescription = () => {
    return (
      <Grid container style={{ padding: '.5rem' }}>
        <Grid container>
          <Grid xs={12} sm={12} container direction="row" style={{ marginBottom: '.2rem' }}>
            <Grid item xs={6} sm={6}>
              <Typography>
                <FiberManualRecord style={{ fontSize: '6px', marginBottom: '2px', marginRight: '.4rem' }} />
                Remitted by Carrier
              </Typography>
            </Grid>
            <Grid
              container
              xs={6}
              sm={6}
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <Typography>{remitTooltipStatus(remitted_by_carrier)}</Typography>
              <Box>{getStatusIcon(remitted_by_carrier)}</Box>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} container direction="row">
            <Grid item xs={6} sm={6}>
              <Typography>
                <FiberManualRecord style={{ fontSize: '6px', marginBottom: '2px', marginRight: '.4rem' }} />
                Remitted by Locad
              </Typography>
            </Grid>
            <Grid
              container
              xs={6}
              sm={6}
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <Typography>{remitTooltipStatus(remitted_by_locad)}</Typography>
              <Box>{getStatusIcon(remitted_by_locad)}</Box>
            </Grid>
          </Grid>
        </Grid>
        <Box style={{ display: 'flex', flexDirection: 'column', marginTop: '.1rem' }}>
          <Typography style={{ fontSize: '13px', margin: '0.1rem 0' }}>
            * Carrier to remit within {carrier_remit_days} days of delivery
          </Typography>
          <Typography style={{ fontSize: '13px', margin: '0.1rem 0' }}>
            ** Locad to remit within {locad_remit_days} days of carrier remittance
          </Typography>
        </Box>
      </Grid>
    );
  };

  const tooltipTitle = () => {
    if (status === pendingDelivery) {
      return pendingDeliveryTooltipDescription();
    } else if (status === notAvailable) {
      return notAvailableTooltipDescription();
    } else {
      return normalTooltipDescription();
    }
  };

  return (
    <>
      {status === '-' || !!!status ? (
        <Chip
          label={status}
          style={{ backgroundColor: getStatusColor(status, theme.palette), color: 'white', fontSize: '14px' }}
          size="small"
        />
      ) : (
        <Tooltip
          title={tooltipTitle()}
          interactive
          arrow
          placement="left-start"
          classes={{ tooltip: classes.customWidth }}
        >
          <Chip
            label={status}
            style={{ backgroundColor: getStatusColor(status, theme.palette), color: 'white', fontSize: '14px' }}
            size="small"
          />
        </Tooltip>
      )}
    </>
  );
};

export default withTheme(RemittanceChipWithTooltip);
