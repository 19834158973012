import {
  GET_SHIPPING_PROFILES,
  GET_SHIPPING_PROFILES_FAIL,
  GET_SHIPPING_PROFILES_SUCCESS,
  GET_SHIPPING_PROFILES_INFO,
  GET_SHIPPING_PROFILES_SUCCESS_INFO,
  GET_SHIPPING_PROFILES_FAIL_INFO,
  GET_SHIPPING_PROFILE_WAREHOUSES,
  GET_SHIPPING_PROFILE_WAREHOUSES_SUCCESS,
  GET_SHIPPING_PROFILE_WAREHOUSES_FAIL,
  GET_SHIPPING_PROFILE_PICKUP_LOCATIONS,
  GET_SHIPPING_PROFILE_PICKUP_LOCATIONS_SUCCESS,
  GET_SHIPPING_PROFILE_PICKUP_LOCATIONS_FAIL,
} from './actionTypes';

const initialState = {
  shippingProfilesData: [],
  isLoading: false,
  error: null,
};
const shippingInfoInitialState = {
  shippingProfileInfoData: [],
  warehousesList: [],
  pickupLocationsList: [],
  selectedData: [],
  isLoading: false,
  error: null,
};

export function shippingProfiles(state = initialState, action) {
  switch (action.type) {
    case GET_SHIPPING_PROFILES: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case GET_SHIPPING_PROFILES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        shippingProfilesData: action.response,
        // brand: action.response,
      };
    }
    case GET_SHIPPING_PROFILES_FAIL: {
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
export function shippingProfileInfo(state = shippingInfoInitialState, action) {
  switch (action.type) {
    case GET_SHIPPING_PROFILES_INFO: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case GET_SHIPPING_PROFILES_SUCCESS_INFO: {
      return {
        ...state,
        isLoading: false,
        error: null,
        shippingProfileInfoData: action.response,
        // brand: action.response,
      };
    }
    case GET_SHIPPING_PROFILES_FAIL_INFO: {
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    }
    case GET_SHIPPING_PROFILE_WAREHOUSES: {
      return {
        ...state,
        isLoading: true,
        error: null,
        pickupLocationsList: [],
        selectedData: [],
      };
    }
    case GET_SHIPPING_PROFILE_WAREHOUSES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        warehousesList: action?.response?.warehouses,
        selectedData: action?.response?.selected_warehouses,
      };
    }
    case GET_SHIPPING_PROFILE_WAREHOUSES_FAIL: {
      return {
        ...state,
        error: true,
        isLoading: false,
        selectedData: [],
      };
    }
    case GET_SHIPPING_PROFILE_PICKUP_LOCATIONS: {
      return {
        ...state,
        isLoading: true,
        error: null,
        warehousesList: [],
        selectedData: [],
      };
    }
    case GET_SHIPPING_PROFILE_PICKUP_LOCATIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        pickupLocationsList: action?.response?.pickup_locations,
        selectedData: action?.response?.selected_pickup_locations,
      };
    }
    case GET_SHIPPING_PROFILE_PICKUP_LOCATIONS_FAIL: {
      return {
        ...state,
        error: true,
        isLoading: false,
        selectedData: [],
      };
    }
    default:
      return state;
  }
}
