export const GET_INVENTORY_LISTING_ITEMS = "GET_INVENTORY_LISTING_ITEMS";
export const GET_INVENTORY_LISTING_ITEMS_SUCCESS =
  "GET_INVENTORY_LISTING_ITEMS_SUCCESS";
export const GET_INVENTORY_LISTING_ITEMS_FAIL =
  "GET_INVENTORY_LISTING_ITEMS_FAIL";

export const GET_INVENTORY_LISTING_FILTERS = "GET_INVENTORY_LISTING_FILTERS";
export const GET_INVENTORY_LISTING_FILTERS_SUCCESS =
  "GET_INVENTORY_LISTING_FILTERS_SUCCESS";
export const GET_INVENTORY_LISTING_FILTERS_FAIL =
  "GET_INVENTORY_LISTING_FILTERS_FAIL";
export const UPDATE_INVENTORY_LISTING_ACTIVE_FILTERS
  = 'UPDATE_INVENTORY_LISTING_ACTIVE_FILTERS'

export const GET_INVENTORY_PRODUCT = "GET_INVENTORY_PRODUCT";
export const GET_INVENTORY_PRODUCT_SUCCESS = "GET_INVENTORY_PRODUCT_SUCCESS";
export const GET_INVENTORY_PRODUCT_FAIL = "GET_INVENTORY_PRODUCT_FAIL";

export const UPDATE_INVENTORY_PRODUCT = "UPDATE_INVENTORY_PRODUCT";
export const UPDATE_INVENTORY_PRODUCT_SUCCESS =
  "UPDATE_INVENTORY_PRODUCT_SUCCESS";
export const UPDATE_INVENTORY_PRODUCT_FAIL = "UPDATE_INVENTORY_PRODUCT_FAIL";

export const CREATE_INVENTORY_PRODUCT = "CREATE_INVENTORY_PRODUCT";
export const CREATE_INVENTORY_PRODUCT_SUCCESS =
  "CREATE_INVENTORY_PRODUCT_SUCCESS";
export const CREATE_INVENTORY_PRODUCT_FAIL = "CREATE_INVENTORY_PRODUCT_FAIL";

export const SET_INVENTORY_UPDATING_ERROR = "SET_INVENTORY_UPDATING_ERROR";
export const SET_INVENTORY_CREATING_ERROR = "SET_INVENTORY_CREATING_ERROR";
export const RESET_INVENTORY_CREATING_ERRORS =
  "RESET_INVENTORY_CREATING_ERRORS";

export const SET_INVENTORY_LISTING_ACTIVE_FILTERS =
  "SET_INVENTORY_LISTING_ACTIVE_FILTERS";
export const SET_INVENTORY_LISTING_TABLE_STATE =
  "SET_INVENTORY_LISTING_TABLE_STATE";

export const RESTORE_INVENTORY_REDUCERS = "RESTORE_INVENTORY_REDUCERS";
export const CLEAR_INVENTORY_PRODUCT = "CLEAR_INVENTORY_PRODUCT";

export const SET_BILLING_FULFILLMET_TABLE_STATE = "SET_BILLING_FULFILLMENT_TABLE_STATE";
