export const SETTINGS = {
  title: 'Settings',
  ACCOUNT_SETTINGS: {
    title: 'Account Settings',
  },
  MARKETPLACE_INTEGRATIONS: {
    title: 'Marketplace Integrations',
    DETAILS: {
      title: 'Marketplace Details',
    },
    CUSTOM: {
      AUTHO: {
        title: 'Custom Integration',
      },
    },
    CUSTOM_MARKETPLACE: {
      title: 'Custom Marketplace Integration',
    },
    SHOPEE: {
      title: 'Shopee Integration',
    },
    LAZADA: {
      AUTHO: {
        title: 'Lazada Integration - Authorize',
      },
      VALI: {
        title: 'Lazada Integration - Validate',
      },
    },
    TIKTOK: {
      AUTHO: {
        title: 'Tiktok Integration - Authorize',
      },
      VALI: {
        title: 'Tiktok Integration - Validate',
      },
    },
    TOKOPEDIA: {
      AUTHO: {
        title: 'Tokopedia Integration - Authorize',
      },
      VALI: {
        title: 'Tokopedia Integration - Validate',
      },
    },
    WIX: {
      AUTHO: {
        title: 'Wix Integration - Authorize',
      },
      VALI: {
        title: 'Wix Integration - Validate',
      },
    },
    ZALORA: {
      title: 'Zalora Integration',
    },
    CAPILLARY: {
      AUTHO: {
        title: 'Capillary Integration',
      },
    },
    KUMU: {
      AUTHO: {
        title: 'Kumu Integration',
      },
    },
    BBO: {
      AUTHO: {
        title: 'BBO Integration',
      },
    },
    SHOPIFY: {
      AUTHO: {
        title: 'Shopify Integration - Authorize',
      },
    },
    WOOCOMMERCE: {
      AUTHO: {
        title: 'Woocommerce Integration',
      },
    },
    AMAZON: {
      AUTHO: {
        title: 'Amazon Integration',
      },
    },
    GINEE: {
      AUTHO: {
        title: 'Ginee Integration',
      },
    },
    EBAY: {
      AUTHO: {
        title: 'Ebay Integration - Authorize',
      },
      VALI: {
        title: 'Ebay Integration - Validate',
      },
    },
  },
  WAREHOUSE_INTEGRATIONS: {
    title: 'Warehouse Integrations',
    WAREHOUSE_DETAILS: {
      title: 'Warehouse Details',
    },
    WAREO: {
      title: 'Wareo Integration',
    },
    UNIWARE: {
      title: 'Uniware Integration',
    },
    LOGIWA: {
      title: 'Logiwa Integration',
    },
    WAREO_3: {
      title: 'Wareo 3 Integration',
    },
  },
  CARRIER_MANAGEMENT: {
    title: 'Carrier Management',
    subtitle: 'Carrier management is only available for Shopify and WooCommerce',
  },
};
