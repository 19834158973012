export const EMPTY_DATA_TEXT = {
  DEFAULT: 'Data is empty.',
  NO_DATA: 'No data',
  DONT_HAVE_DATA: "Sorry, you don't have any data",
  NO_UPLOADED_BULKS: 'You have no uploaded bulks yet.',
  NO_ORDERS_PERIOD: "Sorry, you don't have any orders for this period.",
  NO_ORDERS_YET: "Sorry, you don't have any orders yet",
  NO_INVENTORY: "Sorry, you don't have any inventory yet",
  NO_SHIPMENTS_PERIOD: 'Sorry, you have no shipments for this period.',
};
