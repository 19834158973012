import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import { CssBaseline } from "@mui/material";
import AppSnackbar from "../Components/AppSnackbar";
import FrontDeskWidgetAction from "../Components/FrontDeskWidgetAction";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.login?.layoutBg};
    transition: background 0.3s;
  }
`;

const Root = styled.div`
  ${(props) => (props.width ? `width: ${props.width};` : "max-width: 520px;")}
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function Auth({ children, width }) {
  return (
    <Root width={width}>
      <FrontDeskWidgetAction type="show" />
      <AppSnackbar />
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
}

export default Auth;
