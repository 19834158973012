export const stepStatuses = { visited: 'VISITED', new: 'NEW' };
export const onboardingStepDefinition = [
  {
    target: '#book_shipments',
    contentTitle: {
      title: 'Streamline Your Shipments: Book with Ease',
      variant: 'h6',
      gutterBottom: true,
    },
    contentBody: {
      body: "Book your shipments here by uploading an Excel file, or fill out the Add Shipments form manually.\nIf you are integrated with a marketplace like Shopify or Woocommerce, it's even easier. We'll pull your orders into this page automatically!",
      variant: 'body',
    },
    options: {
      disableBeacon: true,
      placement: 'right',
      offset: 25,
    },
    nextTarget: '#marketplace_integrations',
    status: 'NEW',
  },
  {
    target: '#marketplace_integrations',
    contentTitle: {
      title: 'Seamless Marketplace Integrations',
      variant: 'h6',
      gutterBottom: true,
    },
    contentBody: {
      body: 'Connect your Shopify or Woocommerce store here. This will allow us to seamlessly pull orders in and sync statuses back to your store.',
      variant: 'body',
    },
    options: {
      disableBeacon: true,
      placement: 'right',
      offset: 25,
    },
    nextTarget: '#carrier_management',
    status: 'NEW',
  },
  {
    target: '#carrier_management',
    contentTitle: {
      title: 'Simplify Shipping: Efficient Carrier Management',
      variant: 'h6',
      gutterBottom: false,
    },
    contentBody: {
      body: 'Update your carrier preferences here. By default, we make all carriers available, then automatically select the cheapest option for each shipment. Otherwise, you can set your own carrier priority preferences.',
      variant: 'body',
    },
    options: {
      disableBeacon: true,
      placement: 'right',
      offset: 25,
    },
    nextTarget: '#pickup_locations',
    status: 'NEW',
  },
  {
    target: '#pickup_locations',
    contentTitle: {
      title: 'Create and authorize your first Pickup Location',
      variant: 'h6',
      gutterBottom: true,
    },
    contentBody: {
      body: 'Add more pick-up locations on this step. You can specify a default location for all shipments.',
      variant: 'body',
    },
    options: {
      disableBeacon: true,
      placement: 'right',
      offset: 25,
    },
    nextTarget: null,
    status: 'NEW',
  },
];
export const productTourPromptStepDefinition = [
  {
    target: '#product_tour',
    contentTitle: {
      title: 'Rediscover Locad: Restart the Product Tour',
      variant: 'h6',
      gutterBottom: true,
    },
    contentBody: {
      body: "Ready to rediscover the power of Locad? Click to restart the guided tour and explore our platform's features with fresh eyes.",
      variant: 'body',
    },
    options: {
      placement: 'top',
      disableBeacon: true,
      offset: 25,
    },
  },
];
