import { calculateRowsPerPage, getQueryParamsPagination } from '../../hooks/usePreservePagination';
import * as actionTypes from './actionTypes';

const initialConsignmentsState = () => {
  const search = getQueryParamsPagination();
  const rowsPerPage = calculateRowsPerPage({ page: search.page, offset: search.offset });

  return {
    data: null,
    filters: null,
    error: null,
    activeFilters: {
      status: null,
      sku: null,
    },
    tableState: {
      page: search?.page || 0,
      rowsPerPage: rowsPerPage || 20,
      searchText: '',
      sortOrder: {},
    },
    dataLoading: true,
    dataLoaded: false,
    filtersLoading: true,
  };
};

export default function consignments(state = initialConsignmentsState(), action) {
  switch (action.type) {
    case actionTypes.GET_CONSIGNMENTS:
      return {
        ...state,
        dataLoading: true,
      };
    case actionTypes.GET_CONSIGNMENT_FILTERS:
      return {
        ...state,
        filtersLoading: true,
      };

    case actionTypes.GET_CONSIGNMENTS_SUCCESS:
      return {
        ...state,
        dataLoading: false,
        dataLoaded: true,
        data: action.response,
      };
    case actionTypes.GET_CONSIGNMENT_FILTERS_SUCCESS:
      return {
        ...state,
        filtersLoading: false,
        filters: action.response,
      };

    case actionTypes.SET_CONSIGNMENTS_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          ...action.payload.activeFilters,
        },
      };

    case actionTypes.SET_CONSIGNMENTS_TABLE_STATE:
      return {
        ...state,
        tableState: {
          ...state.tableState,
          ...action.payload.tableState,
        },
      };

    case actionTypes.GET_CONSIGNMENTS_FAIL:
    case actionTypes.GET_CONSIGNMENT_FILTERS_FAIL:
      return {
        ...state,
        error: action.error,
        dataLoading: false,
        filtersLoading: false,
      };

    default:
      return state;
  }
}
